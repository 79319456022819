import React, { useState, useEffect } from 'react';
import textesReconstituerJSON from '~app/components/Entrainement/Redaction/textesReconstituer.json'
import MenuChoisirReconstituer from './MenuChoisirReconstituer';
import MenuChoisirTexte from '../MenuChoisirTexte';
import fleche_haut from '~assets/fleche_haut.svg'
import fleche_bas from '~assets/fleche_bas.svg'

interface TexteReconstituer {
    auteur: string;
    ouvrage: string;
    texte: string[];
}

const TextesReconstituer: React.FC = () => {
    const [textes, setTextes] = useState<TexteReconstituer[]>(textesReconstituerJSON);
    const [texteUtilisateur, setTexteUtilisateur] = useState<string[]>([]);
    const [ordreCorrect, setOrdreCorrect] = useState<number[]>([]);
    const [etat, setEtat] = useState<"info" | "ok" | "erreur">("info")

    const [selectedTextIndex, setSelectedTextIndex] = useState<number | null>(null);
    const [backgroundColors, setBackgroundColors] = useState<string[]>([]);

    const [reponsesDonnees, setReponsesDonnees] = useState<boolean>(false);
    const [messageCentral, setMessageCentral] = useState<string>("");
    //  const textesReconstituer = textesReconstituerJSON as TexteReconstituer[];



    const choisirTexte = (auteur: string, ouvrage: string) => {
        const texteChoisi = textes.find(t => t.auteur === auteur && t.ouvrage === ouvrage);
        if (!texteChoisi) return;

        const ordreAleatoire = [...Array(texteChoisi.texte.length).keys()].sort(() => Math.random() - 0.5);

        setOrdreCorrect(ordreAleatoire);
        setTexteUtilisateur(ordreAleatoire.map(i => texteChoisi.texte[i]));
    };

    const deplacerElement = (index: number, direction: number) => {
        const nouveauTexte = [...texteUtilisateur];
        const temp = nouveauTexte[index];
        nouveauTexte[index] = nouveauTexte[index + direction];
        nouveauTexte[index + direction] = temp;

        setTexteUtilisateur(nouveauTexte);
    };

    return (
        <div>
            <MenuChoisirReconstituer
                etat={etat}
                setEtat={setEtat}
                texteReconstituer={textes}
                selectedTextIndex={selectedTextIndex}
                setSelectedTextIndex={setSelectedTextIndex}
                texteUtilisateur={texteUtilisateur}
                setTexteUtilisateur={setTexteUtilisateur}
                backgroundColors={backgroundColors}
                setBackgroundColors={setBackgroundColors}
                reponsesDonnees={reponsesDonnees}
                setReponsesDonnees={setReponsesDonnees}
            />
            {reponsesDonnees && <div>{/* Afficher les réponses correctes ici */}</div>}
            {messageCentral && <div>{messageCentral}</div>}
            {texteUtilisateur.map((texte, index) => (
                <div className="component menuReconstituer" style={{
                    display: 'flex',
                    backgroundColor: backgroundColors[index] || "white", // Utilisez la couleur de fond stockée ou "white" par défaut
                }} key={index}>
                    <div className="menuGaucheReconstituer">{texte}</div>
                    <div className="menuDroiteReconstituer">
                    {!reponsesDonnees && <>

                        <div className="boutonReconstituer">
                            {index != 0 &&
                                <img src={fleche_haut} style={{ marginBottom: '20px' }} className='boutonReconstituer' onClick={() => deplacerElement(index, -1)} />
                            }
                        </div>
                        <div className="boutonReconstituer">
                            {index < texteUtilisateur.length - 1 &&
                                <img src={fleche_bas} style={{ marginTop: '20px' }} className='boutonReconstituer' onClick={() => deplacerElement(index, 1)} />
                            }
                        </div>
                        </>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TextesReconstituer;
