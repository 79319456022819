import debounce from 'lodash.debounce';
import sha256 from 'crypto-js/sha256';

// Explicitly define the type for 'message' parameter as 'string'
function digestMessage(message: string): Promise<string> {
  if (window.crypto && crypto.subtle && crypto.subtle.digest) {
    return crypto.subtle.digest('SHA-256', new TextEncoder().encode(message))
      .then(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      });
  } else {
    // Fallback to using the polyfill
    return Promise.resolve(sha256(message).toString());
  }
}

// Explicitly define 'input' as 'any' (or use more specific type if possible)
function canonicalize(input: string): string {
  // Explicitly type 'obj' as 'any' (could be further refined based on your use case)
  const parseAndSort = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(parseAndSort);
    }
    return Object.keys(obj)
      .sort()
      .reduce((acc: any, key: string) => {
        acc[key] = parseAndSort(obj[key]);
        return acc;
      }, {});
  };

  return JSON.stringify(parseAndSort(JSON.parse(input)));
}

// Explicitly define 'hash' as a 'string'
function encodeHash(hash: string): string {
  const encodingTable = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const decodingTable = '9876543210zyxwvutsrqponmlkjihgfedcba';
  let encodedHash = '';

  for (let i = 0; i < hash.length; i++) {
    const char = hash[i];
    const position = encodingTable.indexOf(char.toLowerCase());
    if (position !== -1) {
      let newPosition = (position + (i + 1)) % decodingTable.length;
      encodedHash += decodingTable[newPosition];
    } else {
      encodedHash += char; // If character is not in the table, keep it as is
    }
  }

  return encodedHash;
}

// Define the types for the parameters and return type
const askChatGPT = async (
  query: string, 
  Updater: (value: string) => void, 
  setThinking: (value: boolean) => void, 
  setGenerating: (value: boolean) => void,
  keepBusy: boolean = false
): Promise<string> => {
  let receivedText = '';

  function stopAll() {
    if (!keepBusy) {
      setThinking(false);
      setGenerating(false);
    }
  }

  setThinking(true);
  setGenerating(true);
  Updater(" "); // Initialiser avec un espace pour montrer le démarrage

  const debouncedUpdater = debounce((text: string) => {
    Updater(text + "▮"); // Indicateur de génération en cours
  }, 100);

  try {
    const input = [{ role: "system", content: query }];
    const inputString = JSON.stringify(input);

    const canonicalInputString = canonicalize(inputString);

    const hashHex = await digestMessage(canonicalInputString);

    const encodedHash = encodeHash(hashHex);

    const bodytosend=JSON.stringify({
      input: canonicalInputString,
      key: encodedHash,
    })
    console.log (bodytosend);

    const url = window.location.hostname === 'localhost'
      ? 'http://localhost:80/proxy.php'
      : 'https://methodophilo.vmirebeau.fr/serveur/proxy.php';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: bodytosend
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      const processTextStream = async () => {
        let done = false;

        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;

          const chunk = decoder.decode(value, { stream: !done });

          const jsonChunks = chunk
            .split('\n')
            .filter(line => line.startsWith('data:'))
            .map(line => line.replace('data: ', '').trim());

          for (const jsonChunk of jsonChunks) {
            if (jsonChunk === '[DONE]') {
              console.log('Generation completed.');
              debouncedUpdater.cancel();
              Updater(receivedText); // Mise à jour finale avec le texte complet
              stopAll();
              return receivedText;
            }

            if (!keepBusy) setThinking(false);
            setGenerating(true);

            try {
              const parsedChunk = JSON.parse(jsonChunk);
              const choices = parsedChunk.choices;

              if (choices && choices.length > 0) {
                const content = choices[0].delta.content;
                if (content) {
                  receivedText += content;
                  debouncedUpdater(receivedText); // Mise à jour avec le texte reçu
                }
              }
            } catch (error) {
              console.error('Error parsing chunk:', error);
              continue;
            }
          }
        }
      };

      await processTextStream();
    }
  } catch (error) {
    console.error('Error:', error);
    stopAll();
    return ''; // Retourne un message vide en cas d'erreur
  } finally {
    stopAll();
  }

  return receivedText;
};

export default askChatGPT;
