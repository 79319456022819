import React from 'react'
import JSONicon from '~assets/export.svg'
import Tooltip from '../Tooltip'
import { toValidFilename } from '../Funcs/Funcs'

interface MyComponentProps {
  content: string
  sujet:string
  big?:boolean
  tooltipdirection?:"down"|"up"|undefined
  //afficherTexteComposant:(v:string,color?:string) => void
  //cacherTexteComposant:() => void
}

const ExportJSON: React.FC<MyComponentProps> = ({ content, sujet, big = false, tooltipdirection="down" }) => {
  const handleDownload = () => {
    const blob = new Blob([content], { type: 'text/rtf' })
    const url = URL.createObjectURL(blob)

    let a = document.createElement('a')
    a.href = url
    a.download = toValidFilename(sujet) + ".json"
    a.click()
    URL.revokeObjectURL(url)
    //afficherTexteComposant('Fichier JSON exporté !', 'green')
  }

  return (
    <Tooltip text="Enregistrer dans un fichier" direction={tooltipdirection}>
      <img
      src={JSONicon}
      className={big ? "iconExport bigIcon" : "iconExport"}
      //onMouseOver={() => afficherTexteComposant("Exporter au format JSON")}
      //onMouseOut={() => cacherTexteComposant()}
      onClick={handleDownload}
    />
    </Tooltip>
  )
}

export default ExportJSON
