import { FC, useEffect, useState, useContext, useRef } from 'react'
import InputText from '~app/components/Inputs/InputText';
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyDissertation } from '../DissertationVide';
import imgDe from '~assets/de.svg'
import Tooltip from '~app/components/Tooltip';
import { seriesGenerales, seriesTechno } from '~app/consts';
import { SearchPreferencesContext } from '~app/components/Context';


interface Sujet {
    sujet: string;
    notions: string[];
}


interface Props {
    setNewDissertation: (n: Type_Dissertation) => void
    sujets: Sujet[]
    concepts: string[]
}


interface SujetListProps {
    sujets: string[]
    setSujet: (v: string) => void
    selectedSujet: string
    setSelectedSujet: (v: string) => void
    sujetsRef: React.RefObject<HTMLSelectElement>
}

const SujetList: React.FC<SujetListProps> = ({ sujets, setSujet, selectedSujet, setSelectedSujet, sujetsRef }) => {

    const handleSujetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSujet(event.target.value);
        setSelectedSujet(event.target.value)
    }

    const handleRandomSelection = () => {
        const randomIndex = Math.floor(Math.random() * sujets.length);
        const randomSujet = sujets[randomIndex];
        setSujet(randomSujet);
        setSelectedSujet(randomSujet);

        if (sujetsRef.current) {
          const selectElement = sujetsRef.current;
          const optionElement = selectElement.options[randomIndex];

          if (optionElement) {
            const optionTop = optionElement.offsetTop;
            const optionHeight = optionElement.offsetHeight;
            selectElement.scrollTop = optionTop - (selectElement.clientHeight / 2) + (optionHeight / 2);
          }
        }
    }

    return (
        <>
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <select value={selectedSujet} ref={sujetsRef} name="sujets_select" size={5} style={{ width: '100%', height: '100%', padding: '10px', zIndex: 1 }} onChange={handleSujetChange}>
                    <option value="" style={{ display: 'none' }}></option>
                    {sujets.map((sujet, index) => (
                        <option key={index} value={sujet}>{sujet}</option>
                    ))}
                </select>
                <div className="imgBox">
                <img
                    src={imgDe}
                    className="img-rotate"
                    onClick={handleRandomSelection}
                />
                </div>
            </div>
        </>
    );
};

const ConceptsList: React.FC<{ value: string, concepts: string[], nb: number, onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void, selectedValue: string }> = ({ value, concepts, onChange, selectedValue }) => {
    return (
        <select value={value} className="input-text smallInputs inputsConcepts" onChange={onChange}>

            {concepts.map((concept, index) => (
                <option key={index} value={concept}>{concept}</option>
            ))}
        </select>
    );
};

const BaseDonneesSujet: React.FC<Props> = ({ setNewDissertation, sujets, concepts }) => {
    const context = useContext(SearchPreferencesContext)
    if (!context) throw new Error('SearchPreferencesContext is undefined')
    const [concept1,setConcept1,concept2,setConcept2,typeSujet,setTypeSujet,,,,] = context
    

    const [sujet, setSujet] = useState<string>("")

    const [allSujets, setAllSujets] = useState<string[]>([])

    const sujetsRef = useRef<HTMLSelectElement>(null);



    const [query, setQuery] = useState("")
    const [conceptsAffiches, setConceptsAffiches] = useState<string[]>(concepts)


    
    const [selectedSujet, setSelectedSujet] = useState("")

    const [selectedValueConcept1, setSelectedValueConcept1] = useState<string>('');
    const [selectedValueConcept2, setSelectedValueConcept2] = useState<string>('');
    const [selectedValueType, setSelectedValueType] = useState<string>('Tous les sujets');


    const scrollToTop = () => {
        if (sujetsRef.current) {
            sujetsRef.current.scrollTop = 0;
        }

    }

    useEffect(() => {
        const tDiss = getEmptyDissertation() as Type_Dissertation
        tDiss.sujet = sujet
        //console.log ("nouvelle dissertation :",tDiss)
        setNewDissertation(tDiss)
    }, [sujet])

    useEffect(() => {

        if (!conceptsAffiches.includes(concept1)) {
            setConcept1(conceptsAffiches[0]);
            setSelectedValueConcept1(conceptsAffiches[0])
            scrollToTop()
        }
        if (!conceptsAffiches.includes(concept2)) {
            setConcept2(conceptsAffiches[0]);
            setSelectedValueConcept2(conceptsAffiches[0])
            scrollToTop()
        }

        /*if (!allSujets.includes(sujet)) {
          const newSujet = allSujets[0] ? "" : allSujets[0]
          setSujet(allSujets[0])
          setSelectedSujet(allSujets[0]);
        }*/

        //if (!sujet) setSujet("")

        //console.log ("sujet, selectedSujet", sujet, selectedSujet)

        let tSujets = sujets


        //console.log ("Concepts :", concept1,concept2)
        if (concept1.trim() != "") tSujets = tSujets.filter(sujet => sujet.notions.includes(concept1))
        if (concept2.trim() != "") tSujets = tSujets.filter(sujet => sujet.notions.includes(concept2))

        if (typeSujet == "Série générale") {
            tSujets = tSujets.filter(sujet =>
                sujet.notions.every(notion => seriesGenerales.includes(notion)))
        } else if (typeSujet == "Séries techno") {
            tSujets = tSujets.filter(sujet =>
                sujet.notions.every(notion => seriesTechno.includes(notion)))
        }
        if (query.trim() != "") tSujets = tSujets.filter(tSujet => tSujet.sujet.toLowerCase().includes(query.toLowerCase()));

        const allSujetsT = tSujets.flatMap(s => s.sujet)
        setAllSujets(allSujetsT)

        if (!allSujetsT.includes(selectedSujet)) {
            setSujet("");
            setSelectedSujet("");
            scrollToTop()
        }
    }, [concept1, concept2, typeSujet, conceptsAffiches, query])


    useEffect(() => {
        let newConcepts = []
        if (typeSujet == "Série générale") {
            newConcepts = seriesGenerales.slice(); // create a copy of the array
        } else if (typeSujet == "Séries techno") {
            newConcepts = seriesTechno.slice(); // create a copy of the array
        } else {
            newConcepts = concepts.slice(); // create a copy of the array
        }
    
        // Remove any existing empty strings
        newConcepts = newConcepts.filter(concept => concept.trim() !== "");
    
        // Add the single empty string at the beginning
        newConcepts.unshift("");
    
        setConceptsAffiches(newConcepts);
    }, [typeSujet])
    

    const handleConcept1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setConcept1(event.target.value);
        setSelectedValueConcept1(event.target.value)
    }

    const handleConcept2Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setConcept2(event.target.value);
        setSelectedValueConcept2(event.target.value)

    }

    const handleTypeSujetsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTypeSujet(event.target.value);
        setSelectedValueType(event.target.value)

    }
    return <>
        <div style={{ display: 'flex', width: '100%', marginBottom: '15px' }} >
            <div style={{ width: '105px', textAlign: 'left' }}>
                Rechercher :
            </div>
            <div style={{ flex: 1 }}><InputText value={query} onChange={setQuery} placeholder="" small={true} />
            </div>
            <div style={{ width: '200px', marginLeft: '20px' }}>
                <ConceptsList value={typeSujet} selectedValue={selectedValueType} concepts={["Tous les sujets", "Série générale", "Séries techno"]} nb={1} onChange={handleTypeSujetsChange} />
            </div>
        </div>
        <div style={{ display: 'flex', width: '100%', marginBottom: '15px' }} >
            <div style={{ textAlign: 'left', width: '80px' }}>
                Thème 1 :
            </div>
            <div style={{ width: '200px', marginRight: '19px' }}>
                <ConceptsList value={concept1} concepts={conceptsAffiches} selectedValue={selectedValueConcept1} nb={1} onChange={handleConcept1Change} />
            </div>
            <div style={{ textAlign: 'left', width: '100px', paddingLeft: '19px' }}>
                Thème 2 :
            </div>
            <div style={{ width: '200px' }}>
                <ConceptsList value={concept2}  concepts={conceptsAffiches} selectedValue={selectedValueConcept2} nb={2} onChange={handleConcept2Change} />
            </div>
        </div>
        <SujetList sujets={allSujets} setSujet={setSujet} selectedSujet={selectedSujet} setSelectedSujet={setSelectedSujet} sujetsRef={sujetsRef} />

    </>
}

export default BaseDonneesSujet