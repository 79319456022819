import { FC, useEffect, useState, useContext } from 'react'
import Dialog from '../../Info/Dialog/Dialog'
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyDissertation } from '~app/components/Dissertation/DissertationVide'
import NouveauSujet from '~app/components/Dissertation/MenuNouvelleDissertation/NouveauSujet'
import { DissertationAvecId } from '~./app/components/Dissertation/StructureDissertation'
import Error from '../../Info/Dialog/DialogError'
import BaseDonneesSujet from '~app/components/Dissertation/MenuNouvelleDissertation/BaseDonnéesSujet'
import sujetsJson from './sujets.json'
import ImporterModele from './ImporterModele'
import ImporterFichier from './ImporterFichier'
import MenuHorizontal from '~app/components/Menu/MenuHorizontal'

interface Sujet {
    sujet: string;
    notions: string[];
}

interface Props {
    isOpen: boolean
    onClose: () => void
    toShow?: boolean[]
    validate: (dissertation: Type_Dissertation) => void
    onlyCompleteDissertations?: boolean
}

const NouvelleDissertation: FC<Props> = ({ isOpen, onClose, toShow, validate, onlyCompleteDissertations }) => {
    const [idMenu, setIdMenu] = useState(onlyCompleteDissertations ? 2 : 0)
    const [newDissertation, setNewDissertation] = useState<Type_Dissertation>(getEmptyDissertation())

    const [errorOpen, setErrorOpen] = useState(false)
    const [errorTitle, setErrorTitle] = useState("")

    const [concepts, setConcepts] = useState<string[]>([])

    const [sujets, setSujets] = useState<Sujet[]>([]);



    function afficheErreur(v: string) {
        setErrorTitle(v)
        setErrorOpen(true)
        //alert("Erreur : " + v)
    }

    useEffect(() => {
        if (sujetsJson && Array.isArray(sujetsJson)) {
            // Assurez-vous que chaque élément du tableau a la structure attendue
            const validData = sujetsJson.every(item =>
                typeof item.sujet === 'string' &&
                Array.isArray(item.notions) &&
                item.notions.every(notion => typeof notion === 'string')
            );
            if (validData) {
                setSujets(sujetsJson);
                let allNotions = sujetsJson.flatMap(s => s.notions);
                allNotions = [...new Set(allNotions)] // on supprime les doublons
                allNotions.unshift("")  // on ajoute l'élément vide en tête de tableau
                setConcepts(allNotions);
            } else {
                console.error("Les données ne correspondent pas à la structure attendue.");
            }
        }

    }, []);

    const handleClick = () => {
        if (newDissertation) {
            //(newDissertation, newDissertation.sujet.trim())
            if (newDissertation.sujet.trim() == "") {
                //alert("Il est nécessaire d'ajouter un sujet.")
                afficheErreur("Il est nécessaire d'ajouter un sujet.")
            } else { // tout va bien, on enregistre
                onClose()
                validate(newDissertation)
            }
        } else {
            afficheErreur("Aucune dissertation n'a été définie !")
        }
    }


    return (
        <div className={isOpen ? '' : 'hidden'}>
            <Dialog
                title={"Ajouter une dissertation"}
                open={isOpen}
                onClose={onClose}
            >
                <div className="containerNewDissertation">
                    {!onlyCompleteDissertations &&
                        <MenuHorizontal idMenu={idMenu} setIdMenu={setIdMenu} menu={["Nouveau sujet", "Sujet de bac", "Modèle", "Importer"]} toShow={toShow} />
                    }
                    <div className="bottomDiv">
                        <div className="containerInnerNouvelleDissertation">
                            <div className="topDivInnerNouvelleDissertation">
                                {idMenu == 0 ?
                                    <NouveauSujet setNewDissertation={setNewDissertation} />
                                    : idMenu == 1 ?
                                        <BaseDonneesSujet setNewDissertation={setNewDissertation} sujets={sujets} concepts={concepts} />
                                        : idMenu == 2 ?
                                            <ImporterModele setNewDissertation={setNewDissertation} onlyCompleteDissertations={onlyCompleteDissertations} />
                                            : idMenu == 3 ?
                                                <ImporterFichier setNewDissertation={setNewDissertation} />
                                                : <></>}
                            </div>
                            <div className="bottomDivInnerNouvelleDissertation">
                                <button onClick={handleClick} className="bouton_dialog" >Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
            <Error
                isOpen={errorOpen}
                text={<>{errorTitle}</>}
                title="Erreur !"
                onClose={() => setErrorOpen(false)}
            />
        </div>
    )
}
export default NouvelleDissertation
