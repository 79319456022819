import React, { useState, FC, useContext, useCallback } from 'react';
import Tooltip from '../../Tooltip';
import { Etape } from '../StructureDissertation';
import { arraysAreEqual } from '~app/components/Funcs/Funcs';
import { CurrentErrorComposantContext } from '~app/components/Context';
import { ValuesAndNotes } from './SetupParties';
import { CurrentOpenedContext } from '~app/components/Context';

type CircleProps = {
  currentPage: number[];
  setCurrentPage: (v: number[]) => void;
  couleur: number;
  goTo: number[];
  etapes: Etape[];
};

const Circle: React.FC<CircleProps> = ({ setCurrentPage, currentPage, couleur, goTo, etapes }) => {
  const context1 = useContext(CurrentOpenedContext);
  if (!context1) throw new Error('CurrentOpenedContext is undefined');
  const [, setCurrentOpened] = context1;

  const handleClick = () => {
    if (couleur !== -1) {
      setCurrentPage(goTo);
      setCurrentOpened(0);
    }
  };

  const getCercle = useCallback(() => {
    if (arraysAreEqual(currentPage, goTo)) {
      return (
        <div
          className={
            couleur === 1
              ? 'circle_deactivated red'
              : couleur === 2
              ? 'circle_deactivated orange'
              : couleur === 3
              ? 'circle_deactivated green'
              : 'circle_deactivated grey'
          }
        ></div>
      );
    } else {
      return (
        <div
          className={
            couleur === 1
              ? 'circle clickable_circle red'
              : couleur === 2
              ? 'circle clickable_circle orange'
              : couleur === 3
              ? 'circle clickable_circle green'
              : couleur === 4
              ? 'circle clickable_circle white'
              : couleur === -1
              ? 'circle inactiveCircle'
              : 'circle clickable_circle grey'
          }
          onClick={handleClick}
        ></div>
      );
    }
  }, [couleur, currentPage, etapes]);

  return <>{getCercle()}</>;
};

const Etapes: FC<{
  etapes: Etape[];
  etapesVides?: ValuesAndNotes[];
  currentPage: number[];
  setCurrentPage: (v: number[]) => void;
}> = ({ etapes, currentPage, setCurrentPage, etapesVides }) => {
  const context = useContext(CurrentErrorComposantContext);
  if (!context) throw new Error('CurrentErrorComposantContext is undefined');
  const [forbiddenEtape, , , setComposantToGo] = context;

  return (
    <div className="App">
      <div className="etapes-container">
        <div className="circles-container">
          {etapes.map((etape, index) => (
            <Tooltip key={index} text={etape.nom} direction="down" time={0}>
              <div className="containerCercle">
                <Circle
                  key={index}
                  goTo={etape.goTo}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  couleur={etape.couleur}
                  etapes={etapes}
                />
              </div>
            </Tooltip>
          ))}
          {etapesVides &&
            etapesVides.map((etapeVide, index) => (
              <div key={index} className="containerCercle">
                <Circle
                  key={index}
                  goTo={etapeVide.goTo_etape}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  couleur={-1}
                  etapes={etapes}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Etapes;
