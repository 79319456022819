import React, {useState, useCallback, useEffect} from "react"
import FileDropZone from "./FileDropZone"
import { Type_Dissertation } from "../StructureDissertation"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"
import { getEmptyDissertation } from "../DissertationVide"
import StatsDissertInner from "~app/components/Info/Dialog/StatsDissert_Inner"

interface ImporterFichierProps {
    setNewDissertation:(v:Type_Dissertation) => void
}

const ImporterFichier : React.FC<ImporterFichierProps> = ({setNewDissertation}) => {


    const [fichier, setFichier] = useState('')
    const [resultat, setResultat] = useState(<></>)


    const getContent = useCallback((fichier: string) => {
        console.log(fichier)
        if (!fichier) return <></>
        try {
            const parsedJson = JSON.parse(fichier);
    
            if (!isTypeDissertation(parsedJson, getEmptyDissertation())) {
                return <>La structure des données de ce fichier n'est pas valide !</>;
            }
    
            setNewDissertation(parsedJson)
            console.log("fichier valide");
            return <StatsDissertInner
            dissert={parsedJson}
            miniMode={true}
            />
    
        } catch (error) {
            return <>Le format de ce fichier n'est pas valide !<br />Assurez-vous qu'il constitue un fichier JSON correct.</>;
        }
    }, []);

    useEffect(() => {
        setResultat(getContent(fichier));
    }, [fichier, getContent]);

    return <>
        <FileDropZone setDissertation={setFichier} />
        <div className="zoneImport">
        {resultat}
        </div>
    </>
}

export default ImporterFichier