import React from 'react';
import Copyicon from '~assets/copy.svg'
import Tooltip from '~app/components/Tooltip'



interface MyComponentProps {
  text: string;
  afficherTexteComposant:(v:string,color?:string) => void
  cacherTexteComposant:() => void
  big?:boolean
  tooltipdirection?:"down"|"up"|undefined

}

const ExportClipBoard: React.FC<MyComponentProps> = ({ text, afficherTexteComposant, cacherTexteComposant, big=false, tooltipdirection="down" }) => {
    const copyToClipboard = async (text:string) => {
        const unescapedText = text.replace(/\\n/g, '\n').replace(/\\t/g, '\t').replace(/\\r/g, '\r');

        if (navigator.clipboard) {
            
          await navigator.clipboard.writeText(unescapedText);
        } else {
          // Fallback pour les navigateurs qui ne supportent pas l'API Clipboard
          let textarea = document.createElement("textarea");
          textarea.value = unescapedText;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
        }
        afficherTexteComposant('Texte copié dans le presse-papier !', 'green')

      }
      

  return (
    <Tooltip text="Copier"  direction={tooltipdirection}>

    <img
      src={Copyicon}
      className={big ? "iconExport bigIcon" : "iconExport"}
      onMouseOver={() => afficherTexteComposant("Copier dans le presse-papier")}
      onMouseOut={() => cacherTexteComposant()}
      onClick={() => copyToClipboard(text)}
    />
              </Tooltip>

  );
};

export default ExportClipBoard;