import { Dialog as HeadlessDialog } from '@headlessui/react'
import { FC, PropsWithChildren, ReactNode, useContext, useEffect } from 'react'
import closeIcon from '~assets/close.svg'
import closeWhite from '~assets/icons/close_white.svg'
import { DialogContext } from '~app/components/Context'

import './resetStyles.css';

interface Props {
  title: string
  open: boolean
  onClose: () => void
  className?: string,
  backgroundcolor?: string,
  menu?: ReactNode
  smallDialog?: boolean
}

const Dialog: FC<PropsWithChildren<Props>> = (props) => {
  const context = useContext(DialogContext)
  if (!context) throw new Error('dialogContext is undefined')
  const [, setDialogVisible] = context


  const handleDialogClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setDialogVisible(props.open)
  }, [props.open])

  const handleBackgroundClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClose();
  };

  const getClass = () => {
    let class_t = "dialog_4"
    class_t += props.backgroundcolor == "red" ? " red_bg smallDialog" : ''
    class_t += props.smallDialog ? " smallDialog" : ""
    return class_t
  }


  // props.className normalement passé à HeadlessDialog.Panel
  return (
    <HeadlessDialog open={props.open} onClose={() => { }} className="dialog_1">
      <div className="dialog_2" aria-hidden="true" />
      <div className="dialog_3" onClick={handleBackgroundClick}>
        <HeadlessDialog.Panel
          className={getClass()}
          onClick={handleDialogClick}>
          <HeadlessDialog.Title className="dialog_5">
            <span className="dialog_6" />
            <div className="afterTitle">
              <div className="titreComponent" style={props.menu ? { marginRight: '20px' } : undefined}>{props.title}</div>
              {props.menu ? props.menu : <></>}
            </div>
            <img src={props.backgroundcolor == "red" ? closeWhite : closeIcon} className="dialog_8" onClick={props.onClose} />
          </HeadlessDialog.Title>
          {props.children}
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  )
}

export default Dialog
