import React, { FC, useState, useEffect } from "react";
import textetrousJSON from '~app/components/Entrainement/Redaction/textestrous.json'
import texteconnecteursJSON from '~app/components/Entrainement/Redaction/textesconnecteurs.json'

import MenuChoisirTexte from "../MenuChoisirTexte";
interface Option {
  text: string;
  correct: boolean;
}

interface Question {
  text: string;
  options: Option[];
}

interface TextEntry {
  auteur: string;  // Ajout des nouveaux champs
  ouvrage: string; // Ajout des nouveaux champs
  questions: Question[];
}

interface Props {
  mode:"trous" | "connecteurs"
}

const TextesTrous: FC<Props> = ({mode}) => {
  const [selectedTextIndex, setSelectedTextIndex] = useState<number | null>(null);
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
  const [reponsesDonnees, setReponsesDonnees] = useState(false);


  const [etat, setEtat] = useState<"info" | "ok" | "erreur">("info")
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  
  // 1. Utilisation des données importées du fichier JSON
  let textetrous = [] as TextEntry[];
  if (mode == "trous") textetrous = textetrousJSON as TextEntry[]; else textetrous = texteconnecteursJSON as TextEntry[]

  console.log(textetrousJSON)
  
  const handleOptionChange = (questionIndex: number, optionIndex: number) => {
    if (!reponsesDonnees) {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[questionIndex] = optionIndex;
    setSelectedOptions(newSelectedOptions);
}
  };



  return (
    <div style={{ textAlign: 'justify' }}>

        <MenuChoisirTexte
            etat={etat}
            setEtat={setEtat}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            textetrous={textetrous}
            selectedTextIndex={selectedTextIndex}
            setSelectedTextIndex={setSelectedTextIndex}
            backgroundColors={backgroundColors}
            setBackgroundColors={setBackgroundColors}
            reponsesDonnees={reponsesDonnees}
            setReponsesDonnees={setReponsesDonnees}
        />


      {selectedTextIndex !== null && (
        <div className="component" style={{ marginTop: '50px', textAlign: 'justify', padding: '10px', lineHeight: 2 }}>

        <div>
          <div>
            {textetrous[selectedTextIndex].questions.map((question, qIndex) => (
              <span key={qIndex}>
                {question.text.split('<br />').map((text, i) => (
                  <React.Fragment key={i}>
                    {i > 0 && <br />}
                    <span>{text}</span>
                  </React.Fragment>
                ))}
{question.options && (
  <select
    value={selectedOptions[qIndex]}
    onChange={(e) => handleOptionChange(qIndex, parseInt(e.target.value))}
    className="selectExercice"
    style={{
      backgroundColor: backgroundColors[qIndex] || "white", // Utilisez la couleur de fond stockée ou "white" par défaut
    }}
  >
    <option value={-1}></option>
    {question.options.map((option, oIndex) => (
      <option key={oIndex} value={oIndex}>
        {option.text}
      </option>
    ))}
  </select>
)}
              </span>
            ))}
          </div>
          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <p>
              <strong>{textetrous[selectedTextIndex].auteur}</strong>,
              <em> {textetrous[selectedTextIndex].ouvrage}</em>
            </p>
          </div>
        </div>
        </div>)
      }
    </div>
  );
  
};

export default TextesTrous;
