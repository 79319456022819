import { FC, useEffect, useState } from 'react'
import Dialog from '../../Info/Dialog/Dialog'
import { getEmptyContenu } from '~app/components/Dissertation/DissertationVide'
import Error from '../../Info/Dialog/DialogError'
import conceptsJson from '~app/components/Entrainement/ElementDeDissertation/concepts.json'
import MenuHorizontal from '~app/components/Menu/MenuHorizontal'
import { Concept } from '~./app/components/Dissertation/StructureDissertation'
import { Concept_liste } from '~./app/components/Dissertation/StructureDissertation'
import EntrerNouveauConcept from './EntrerNouveauConcept'
import BaseDonneesConcept from './BaseDonnéesConcept'

interface Props {
    isOpen: boolean
    onClose: () => void
    toShow?:boolean[]
    validate: (concept: Concept) => void
}

const NouveauConcept: FC<Props> = ({ isOpen, onClose, toShow, validate }) => {
    const [idMenu, setIdMenu] = useState(0)
    const [newConcept, setNewConcept] = useState<Concept>({nomConcept:"",contenu:getEmptyContenu()})

    const [errorOpen, setErrorOpen] = useState(false)
    const [errorTitle, setErrorTitle] = useState("")

    const [notions, setNotions] = useState<string[]>([])

    const [concepts_liste, setConcepts_liste] = useState<Concept_liste[]>([]);



    function afficheErreur(v:string) {
        setErrorTitle(v)
        setErrorOpen(true)
        //alert("Erreur : " + v)
    }

    useEffect(() => {
        if (conceptsJson && Array.isArray(conceptsJson)) {
          // Assurez-vous que chaque élément du tableau a la structure attendue
          const validData = conceptsJson.every(item => 
            typeof item.concept === 'string' && 
            Array.isArray(item.notions) &&
            item.notions.every(notion => typeof notion === 'string')
          );
          if (validData) {
            setConcepts_liste(conceptsJson);
            let allNotions = conceptsJson.flatMap(s => s.concept);
            allNotions = [...new Set(allNotions)] // on supprime les doublons
            allNotions.unshift("")  // on ajoute l'élément vide en tête de tableau
            setNotions(allNotions);
          } else {
            console.error("Les données ne correspondent pas à la structure attendue.");
          }
        }

      }, []);

    const handleClick = () => {
        if (newConcept) {
            //(newDissertation, newDissertation.sujet.trim())
            if (newConcept.nomConcept.trim() == "") {
                //alert("Il est nécessaire d'ajouter un sujet.")
                afficheErreur("Il est nécessaire d'ajouter un concept.")
            } else { // tout va bien, on enregistre
                onClose()
                validate(newConcept)
            }
        } else 
        {
            afficheErreur("Aucun concept n'a été défini !")
        }
    }


    return (
        <div className={isOpen ? '' : 'hidden'}>
        <Dialog
            title={"Ajouter un concept"}
            open={isOpen}
            onClose={onClose}
        >
            <div className="containerNewDissertation">
                <MenuHorizontal idMenu={idMenu} setIdMenu={setIdMenu} menu={["Nouveau concept", "Banque de concepts"]} toShow={toShow} />
                <div className="bottomDiv">
                    <div className="containerInnerNouvelleDissertation">
                        <div className="topDivInnerNouvelleDissertation">
                        <span className={idMenu == 0 ? '' : 'hidden'}>
                                <EntrerNouveauConcept setNewConcept={setNewConcept} />
                         </span>
                         <span className={idMenu == 1 ? '' : 'hidden'}>

                                <BaseDonneesConcept setNewConcept={setNewConcept} concepts={concepts_liste} notions={notions}/>
                            
                            </span>
                        </div>
                        <div className="bottomDivInnerNouvelleDissertation">
                            <button onClick={handleClick} className="bouton_dialog" >Ok</button>
                        </div>
                    </div>
                </div>
            </div>

        </Dialog>
        <Error
            isOpen={errorOpen}
            text={<>{errorTitle}</>}
            title="Erreur !"
            onClose={()=>setErrorOpen(false)}
            />
        </div>
    )
}
export default NouveauConcept
