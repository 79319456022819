import { Lien } from '~types'
import Dialog from './Dialog'
import GetLinkMenu from '../GetLinkMenu'



interface Props {
    isOpen: boolean
    text:string,
    title:string,
    onClose: () => void,
    liens?:Lien[]
  }

  //prop classname à ajouter ?

const InfoDialog = (props: Props) => {


    return (
        <Dialog
          title={props.title}
          open={props.isOpen}
          onClose={props.onClose}
        >
            <div className="texteInfo" dangerouslySetInnerHTML={{ __html: props.text }} />
            {props.liens && 
            <GetLinkMenu liens={props.liens} />
            }
            </Dialog>
    )
    }
export default InfoDialog
