import React, { FC, useState, useContext, useEffect, ReactNode, ReactElement, isValidElement, PropsWithChildren } from 'react'
import { Type_Dissertation } from '../StructureDissertation'
import delete_img from '~assets/delete.svg'
import QuestionDialog from '~app/components/Info/Dialog/QuestionDialog'
import ViewText from '~app/components/Info/ViewText/ViewText'
import oeil_img from '~assets/oeil2.svg'
import img_moins from '~assets/moins.svg'
import img_plus from '~assets/plus.svg'

import menu_info from '~assets/menu_info.svg'


import signe_attention_n from '~assets/signe_attention_n.svg'
import signe_attention_b from '~assets/signe_attention_b.svg'
import OkCheck from '~assets/ok_check.svg'

import grand_fleche_d from '~assets/grande_fleche_d.svg'
import { isPartieBeyondForbidden } from '../../Funcs/Funcs'

import { CurrentErrorComposantContext } from '~app/components/Context'


import Tooltip from '~app/components/Tooltip'
import _ from 'lodash';
import { getEmptyIntroduction, getEmptyPartie, getEmptyDeveloppement, getEmptyConclusion } from '../DissertationVide'
import { Etape } from '../StructureDissertation'
import { ValuesAndNotes } from './SetupParties'
import { UpdateStructureContext } from '~app/components/Context'
import { deletePartie3, addPartie3 } from '~app/components/Funcs/Funcs';

import { getStrValue, getNumValue, setValue } from '~app/components/Funcs/Funcs'
import StatsDissert from '~app/components/Info/Dialog/StatsDissert'
import { RemoveDissertationContext } from '~app/components/Context';
import { useNavigate } from 'react-router-dom'

interface Props {
  dissertation: Type_Dissertation
  setDissertation: (value: any) => any
  currentPage: number[]
  setCurrentPage: (value: number[]) => void
  etapes: Etape[]
  valuesAndNotes: ValuesAndNotes[]
}

const MenuDissertation: FC<Props> = ({ dissertation, setDissertation, currentPage, setCurrentPage, etapes, valuesAndNotes }) => {

  const context4 = useContext(RemoveDissertationContext)
  if (!context4) throw new Error('RemoveDissertationContext is undefined')
  const [removeDissertation] = context4

  const navigate = useNavigate()

  const element = ''

  const getStrV = (el: string): string => getStrValue(dissertation, element + el);
  const getNumV = (el: string): number => getNumValue(dissertation, element + el);
  const setV = (el: string) => setValue(setDissertation, element + el);


  const [isOpenView, setOpenView] = useState(false)

  const [isOpenEmpty, setOpenEmpty] = useState(false)
  const [isOpenEmptyP3, setOpenEmptyP3] = useState(false)

  const [prompt, setPrompt] = useState('')

  const [partie, setPartie] = useState<number[]>([])
  const [delFunc, setDelFunc] = useState<() => void>(() => { })

  const context2 = useContext(UpdateStructureContext)
  if (!context2) throw new Error('UpdateStructureContext is undefined')
  const [, setUpdateStructure] = context2

  const context = useContext(CurrentErrorComposantContext)
  if (!context) throw new Error('CurrentErrorComposantContext is undefined')
  const [forbiddenEtape, , ,] = context

  const context3 = useContext(CurrentErrorComposantContext)
  if (!context3) throw new Error('CurrentErrorComposantContext is undefined')
  const [, , , setComposantToGo] = context3

  const [imageSrc, setImageSrc] = useState(signe_attention_b)

  const [showStatsDissert, setShowStatsDissert] = useState<boolean>(false)



  const GetLink: FC<PropsWithChildren<{ grande_etape: number; goTo: number[]; partie?: number; style?: React.CSSProperties }>> = ({
    goTo,
    grande_etape,
    children,
    partie,
    style
  }) => {
    let type_classe = ''
    grande_etape == 1 ? (type_classe = 'Partie') : (type_classe = 'sub-box')

    let className = type_classe

    let current = false
    if (currentPage[0] == grande_etape) {
      if (grande_etape == 1) {
        if (partie == currentPage[1]) current = true
      } else current = true
    }
    current ? (className += ' clickable clickedMenu') : (className += ' clickable solide')

    return (
      <div className={className} onClick={current ? undefined : () => setCurrentPage(goTo)} style={style}>
        {children}
      </div>
    )
  }

  const setPromptDirect = (tempprompt: string) => {
    setPrompt('Êtes-vous sûr de vouloir supprimer ' + tempprompt + ' ? Cette opération est irréversible.')
  }

  const emptyIntro = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("toute l'introduction")
    setDelFunc(() => () => {
      setV('intro')(getEmptyIntroduction())
      setUpdateStructure(true)
    })
    setOpenEmpty(true)
  }

  const delDissertation = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("toute la dissertation")
    setDelFunc(() => () => {
      removeDissertation()
      navigate("/dissertation/")
    })
    setOpenEmpty(true)
  }


  const emptyPartie1 = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("toute la première partie")
    setDelFunc(() => () => {
      const newPartie = getEmptyPartie()
      newPartie.annonce.contenu = getStrV('developpement.0.annonce.contenu')
      setV('developpement.0')(newPartie)

      setUpdateStructure(true)
    })
    setOpenEmpty(true)
  }

  const emptyPartie2 = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("toute la seconde partie")
    setDelFunc(() => () => {
      const newPartie = getEmptyPartie()
      newPartie.annonce.contenu = getStrV('developpement.1.annonce.contenu')
      setV('developpement.1')(newPartie)

      setUpdateStructure(true)
    })
    setOpenEmpty(true)
  }

  const emptyPartie3 = () => (event: React.MouseEvent) => {
    // fonctionnement un peu différent des autres : on supprime toute la partie
    event.stopPropagation()
    setOpenEmptyP3(true)
  }

  const emptyDvlpt = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("tout le développement")
    setDelFunc(() => () => {
      setV('developpement')(getEmptyDeveloppement())
      setUpdateStructure(true)
    })
    setOpenEmpty(true)
  }

  const emptyConclu = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPromptDirect("tout le développement")
    setDelFunc(() => () => {
      setV('conclusion')(getEmptyConclusion())
      setUpdateStructure(true)
    })
    setOpenEmpty(true)
  }

  const viewClick = (partie: number[]) => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPartie(partie)
    setOpenView(true)
  }

  const addPartie = () => (event: React.MouseEvent) => {
    event.stopPropagation()
    addPartie3(dissertation, setDissertation, setUpdateStructure)
    setUpdateStructure(true)
    setComposantToGo(2)
    setCurrentPage([0, 4])
  }

  const handleMouseClick = () => {
    if (forbiddenEtape) {
      setComposantToGo(forbiddenEtape.composant)
      setCurrentPage(forbiddenEtape.etape)
    }
  }

  return (
    <div className="menuHaut">
      <div className="menuVertical">
        <div className="sub-box clickable_box" style={{ position: 'relative' }}>
          <div className="signeAttention" style={{ left: '0px', top: '12px' }}>
            {forbiddenEtape?.nom ? (
              <Tooltip
                text={forbiddenEtape.currentProblem}
                direction="down"
                color="rgba(255, 0, 0, 0.95)"
                textColor="black"
                time={0}
                fontweight={700}
              >
                <img
                  className="panneauAttention boutonsTitre"
                  src={imageSrc}
                  onClick={handleMouseClick}
                  onMouseOver={() => setImageSrc(signe_attention_n)}
                  onMouseOut={() => setImageSrc(signe_attention_b)}
                />
              </Tooltip>
            ) :
              <img
                className="panneauOK boutonsTitre"
                src={OkCheck}
                style={{ top: '-2px', cursor: 'default' }}
              />
            }

          </div>

          <div className="signeAttention" style={{ right: '0px', top: '14px' }}>
            <Tooltip text="Infos sur la dissertation" direction="down">
              <img src={menu_info} className="panneauOK boutonsTitre hoverMenu" onClick={() => setShowStatsDissert(true)} />
              <StatsDissert isOpen={showStatsDissert} dissert={dissertation} onClose={() => setShowStatsDissert(false)} delFunc={removeDissertation} />
            </Tooltip>
          </div>
          <div className="Partie_off" style={{ backgroundColor: '#f0f4fd', borderRadius: '10px 10px 0 0' }}>
            <div className="boutonMenu">

              <div className="nomDissertation nomElement">Dissertation complète</div>
              <div className="containerImagesMenu containerImagesSousParties bigMarginButtons smallHeight">

                <Tooltip text="Visualiser la dissertation" direction="down">
                  <img src={oeil_img} className="imagesMenu" onClick={viewClick([3])} />
                </Tooltip>
                <Tooltip text="Supprimer la dissertation" direction="down">
                  <img src={delete_img} className="imagesMenu" onClick={delDissertation()} />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="menuHorizontal">
          <div className="box clickable_box" id="intro">
            <GetLink grande_etape={0} goTo={[0, 1]} style={{ borderRadius: '0 0 0 10px' }}>
              <div className="boutonMenu nomElement">
                Introduction
                <div className="containerImagesMenu bigMarginButtons">
                  <Tooltip text="Visualiser l'introduction" direction="down">
                    <img src={oeil_img} className="imagesMenu" onClick={viewClick([0])} />
                  </Tooltip>
                  <Tooltip text="Supprimer l'introduction" direction="down">
                    <img src={delete_img} className="imagesMenu" onClick={emptyIntro()} />
                  </Tooltip>
                </div>
              </div>
            </GetLink>
            <img src={grand_fleche_d} className="arrow arrow1"></img>
          </div>
          <div className="box solide bigBox" id="develop">
            <div className="sub-box clickable_box">
              {!isPartieBeyondForbidden([1, 0], forbiddenEtape.etape) ?
                <div className="Partie_off">
                  <div className="boutonMenu">
                    <div className="nomElement">Développement</div>
                    <div className="containerImagesMenu containerImagesSousParties bigMarginButtons">
                      <Tooltip text="Visualiser le développement" direction="down">
                        <img src={oeil_img} className="imagesMenu" onClick={viewClick([1])} />
                      </Tooltip>
                      <Tooltip text="Supprimer le développement" direction="down">
                        <img src={delete_img} className="imagesMenu" onClick={emptyDvlpt()} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                : <div className="Partie_off boutonMenuDeactivated">
                  <div className="boutonMenu">
                    <div>Développement</div>
                  </div>
                </div>
              }
            </div>
            <div className="sub-boxes">
              {!isPartieBeyondForbidden([1, 0], forbiddenEtape.etape) ?
                <div className="clickable_box  allHeight">

                  <GetLink grande_etape={1} partie={0} goTo={[1, 0, 0]}>
                    <div className="boutonMenu">
                      <div className="nomElement">Partie I</div>
                      <div className="containerImagesMenu containerImagesSousParties">
                        <Tooltip text="Visualiser la partie I" direction="down">
                          <img src={oeil_img} className="imagesMenu" onClick={viewClick([1, 0])} />
                        </Tooltip>
                        <Tooltip text="Supprimer le développement" direction="down">
                          <img src={delete_img} className="imagesMenu" onClick={emptyPartie1()} />
                        </Tooltip>
                      </div>
                    </div>
                  </GetLink>
                </div>
                : <div className="allHeight">
                  <div className="Partie boutonMenu boutonMenuDeactivated">
                    <div>Partie I</div>
                    <div className="containerImagesMenu containerImagesSousParties">
                    </div>
                  </div>
                </div>
              }
              {!isPartieBeyondForbidden([1, 1], forbiddenEtape.etape) ?
                <div className="clickable_box allHeight">

                  <GetLink grande_etape={1} partie={1} goTo={[1, 1, 0]}>
                    <div className="boutonMenu">
                      <div className="nomElement">Partie II</div>
                      <div className="containerImagesMenu containerImagesSousParties">
                        <Tooltip text="Visualiser la partie II" direction="down">
                          <img src={oeil_img} className="imagesMenu" onClick={viewClick([1, 1])} />
                        </Tooltip>
                        <Tooltip text="Supprimer la partie II" direction="down">
                          <img src={delete_img} className="imagesMenu" onClick={emptyPartie2()} />
                        </Tooltip>
                      </div>
                    </div>
                  </GetLink>
                </div>
                : <div className="allHeight">
                  <div className="Partie boutonMenu boutonMenuDeactivated">
                    <div>Partie II</div>
                    <div className="containerImagesMenu containerImagesSousParties">
                    </div>
                  </div>
                </div>
              }
              {dissertation.developpement.length == 3 ?
                <>
                  {!isPartieBeyondForbidden([1, 2], forbiddenEtape.etape) ?
                    <div className="clickable_box allHeight">
                      <GetLink grande_etape={1} partie={2} goTo={[1, 2, 0]}>
                        <div className="boutonMenu">
                          <div className="nomElement">Partie III</div>
                          <div className="containerImagesMenu containerImagesSousParties">
                            <Tooltip text="Visualiser la partie III" direction="down">
                              <img src={oeil_img} className="imagesMenu" onClick={viewClick([1, 2])} />
                            </Tooltip>
                            <Tooltip text="Supprimer la partie III" direction="down">
                              <img src={delete_img} className="imagesMenu" onClick={emptyPartie3()} />
                            </Tooltip>
                          </div>
                        </div>
                      </GetLink>
                    </div>
                    :
                    <div className="allHeight">
                      <div className="Partie boutonMenu boutonMenuDeactivated">
                        <div>Partie III</div>
                        <div className="containerImagesMenu containerImagesSousParties">
                        </div>
                      </div>
                    </div>
                  }</>
                :
                <>
                  <div className="allHeight smallColonne">

                    <div className="boutonMenu">
                      <Tooltip text="Ajouter une partie III" direction="down">
                        <img src={img_plus} className="plusImg" onClick={addPartie()} />
                      </Tooltip>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          {!isPartieBeyondForbidden([2], forbiddenEtape.etape) ?

            <div className="box clickable_box" id="conclusion">
              <img src={grand_fleche_d} className="arrow arrow2" />

              <GetLink grande_etape={2} goTo={[2, 0]} style={{ borderRadius: '0 0 10px 0' }}>
                <div className="boutonMenu nomElement">
                  Conclusion
                  <div className="containerImagesMenu bigMarginButtons">
                    <Tooltip text="Visualiser la conclusion" direction="down">
                      <img src={oeil_img} className="imagesMenu" onClick={viewClick([2])} />
                    </Tooltip>
                    <Tooltip text="Supprimer la conclusion" direction="down">
                      <img src={delete_img} className="imagesMenu" onClick={emptyConclu()} />
                    </Tooltip>
                  </div>
                </div>
              </GetLink>
            </div>
            : <>

              <div className="box solide boutonMenuDeactivated" id="conclusion">
                <img src={grand_fleche_d} className="arrow arrow2"></img>

                <div className="boutonMenu">
                  Conclusion
                </div>
              </div>

            </>}
          {isOpenEmpty && (
            <QuestionDialog
              isOpen={isOpenEmpty}
              onClose={() => setOpenEmpty(false)}
              text={prompt}
              title="Tout supprimer ?"
              reponse1="Oui"
              reponse2="Non"
              onReponse1={() => delFunc()}
            />
          )}
          {isOpenEmptyP3 && (
            <QuestionDialog
              isOpen={isOpenEmptyP3}
              onClose={() => setOpenEmptyP3(false)}
              text={"Voulez-vous supprimer toute la partie 3,<br />ou seulement vider son contenu ?"}
              title="Précision"
              reponse1="Supprimer"
              reponse2="Vider"
              onReponse1={() => {
                deletePartie3(setDissertation, setUpdateStructure)
                setUpdateStructure(true)
              }}
              onReponse2={() => {
                const newPartie = getEmptyPartie()
                newPartie.annonce.contenu = getStrV('developpement.2.annonce.contenu')
                setV('developpement.2')(newPartie)
                setUpdateStructure(true)
              }
              } />
          )}



          {isOpenView && (
            <ViewText
              dissertation={dissertation}
              isOpen={isOpenView}
              onClose={() => setOpenView(false)}
              partie={partie}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuDissertation
