import { FC } from 'react'
import TrouverConcepts from '~./app/components/Dissertation/Introduction/TrouverConcepts'
import Accroche from '~./app/components/Dissertation/Introduction/Accroche'
import DeuxDefinitions from '~./app/components/Dissertation/Introduction/DeuxDefinitions'

import Problematique from '~./app/components/Dissertation/Introduction/Problematique'
import Plan from '~./app/components/Dissertation/Introduction/Plan'

import { getStrValue } from '~app/components/Funcs/Funcs'

interface Props {
  objet: any
  setObjet: (value: any) => any
  currentPage: number[]
  setEmptyAll?: (func: () => void) => void;
}

const Introduction: FC<Props> = ({
  objet,
  setObjet,
  currentPage,
  setEmptyAll,
}) => {

  return (
    <>
      {currentPage[1] == 0 && (
        <Accroche
          sujet={objet.sujet}
          objet={objet}
          setObjet={setObjet}
          element={'intro.accroche'}
          setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[1] == 1 && (
        <TrouverConcepts
          sujet={objet.sujet}
          objet={objet}
          setObjet={setObjet}
          element={'intro.concepts'}
          setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[1] == 2 && (
        <DeuxDefinitions
          sujet={objet.sujet}
          objet={objet}
          setObjet={setObjet}
          element={'intro'}
          setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[1] == 3 && (
        <Problematique
        sujet={objet.sujet}
        objet={objet}
        setObjet={setObjet}
        element={'intro.problematique'}
        concept1={getStrValue(objet,'intro.concepts.concept1')}
        concept2={getStrValue(objet,'intro.concepts.concept2')}
        def_concept1={getStrValue(objet,'intro.definitions.def_concept1.contenu')}
        def_concept2={getStrValue(objet,'intro.definitions.def_concept2.contenu')}
        setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[1] == 4 && (
        <Plan
          sujet={objet.sujet}
          objet={objet}
          setObjet={setObjet}
          setEmptyAll={setEmptyAll}
        />
      )}
    </>
  )
}

export default Introduction
