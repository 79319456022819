import { FC } from 'react'
import Dialog from './Dialog'
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'


import StatsDissertInner from './StatsDissert_Inner';

interface Props {
    isOpen: boolean
    dissert: Type_Dissertation,
    onClose: () => void
    delFunc:()=> void
}


const StatsDissert: FC<Props> = ({isOpen, dissert, onClose, delFunc }) => {

    return (
        <Dialog
            title={dissert.sujet}
            open={isOpen}
            onClose={onClose}
        >
            <StatsDissertInner
                isOpen={isOpen}
                dissert={dissert}
                onClose={onClose}
                delFunc={delFunc}
                />

        </Dialog>
    )
}
export default StatsDissert
