import {FC} from 'react'
import Tooltip from '~app/components/Tooltip'
import RGBicon from '~assets/RGB.svg'

interface Props {
    seeColors:boolean,
    setSeeColors:(v:boolean) => void,
    afficherTexteComposant:(v:string)=>void,
    cacherTexteComposant:() => void
}

const GetColors: FC<Props> = ({seeColors, setSeeColors, afficherTexteComposant, cacherTexteComposant}) => {
    return (
        <Tooltip text={!seeColors ? "Afficher les couleurs" : "Masquer les couleurs"} direction={'down'}>
        <img
        src={RGBicon}
        className={seeColors ? "iconExport afficheCouleurs" : "iconExport cacherCouleurs"}
        onMouseOver={() => afficherTexteComposant(seeColors ? "Afficher les couleurs" : "Masquer les couleurs")}
        onMouseOut={() => cacherTexteComposant()}
        onClick={() => setSeeColors(!seeColors)}
      />
      </Tooltip>
    )
}

export default GetColors