import React, { useRef, useState } from 'react';
import importIcon from '~assets/import.svg';

type FileDropZoneProps = {
    setDissertation: (content: string) => void;
};

const FileDropZone: React.FC<FileDropZoneProps> = ({ setDissertation }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                setDissertation(content);
                setFileLoaded(true);
            };
            reader.readAsText(file);
        }
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(true);
    };
    
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
            setIsDragOver(false);
    };
    
    const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsHover(false);
        setFileLoaded(false);  // Ajoutez cette ligne
    };
    

    const onMouseEnter= (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsHover(true);
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragOver(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                setDissertation(content);
                setFileLoaded(true);
            };
            reader.readAsText(file);
        }
    };

    return (
        <div 
            className="file-drop-zone"
            onClick={() => fileInputRef.current?.click()}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onDrop={onDrop}
        ><div style={{width:'160px', height:'100%'}}>
            <div className="icon-container">
                <img src={importIcon} alt="Import icon" />
            </div>
            </div>
            <div style={{flex:1, paddingRight: '50px'}}>
    {fileLoaded ? (
        <div className="instruction hoveredImport">
            Fichier chargé !
        </div>
    ) : (
        <>
            <div className={`instruction ${(isHover && !isDragOver) ? 'hoveredImport' : ''}`}>
                Chargez votre fichier
            </div>
            <div className={`instruction ${isDragOver ? 'hoveredImport' : ''}`}>
                ou déposez-le ici
            </div>
        </>
    )}
</div>

            <input
                ref={fileInputRef}
                type="file"
                className="hidden-file-input"
                onChange={handleFileChange}
            />
        </div>
    );
};

export default FileDropZone;
