import { useState, useEffect, FC } from "react"
import Dissertation from "~app/components/Dissertation/Dissertation"
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"
import { getEmptyDissertation } from "~app/components/Dissertation/DissertationVide"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"
import MenuComposant from "./MenuComposant"
import Problematique from "../Dissertation/Introduction/Problematique"
import Plan from "../Dissertation/Introduction/Plan"
import SousPartie from "../Dissertation/Partie/SousPartie"

interface Props {
    typeSousPartie: "developpement_libre" | "reference" | "exemple" | "distinction_conceptuelle"
}
const T_SousPartie: FC<Props> = ({ typeSousPartie }) => {
    const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    const [currentOpened, setCurrentOpened] = useState(0)

    useEffect(() => {
// Vérifier si la dissertation est définie et contient les informations nécessaires
if (dissertation && dissertation.sujet) {
    // Convertir et stocker la dissertation dans localStorage sous une clé dynamique
    localStorage.setItem(typeSousPartie, JSON.stringify(dissertation));
    
    // Optional: Ajouter un log pour vérifier l'enregistrement
    console.log(`Dissertation enregistrée sous la clé '${typeSousPartie}' dans localStorage:`, dissertation);
}

    }, [dissertation, typeSousPartie]); // Ajout de typeSousPartie comme dépendance 

    useEffect(() => {
// Récupérer les données depuis localStorage sous une clé dynamique
const storedData = localStorage.getItem(typeSousPartie);

if (storedData) {
    try {
        // Convertir la chaîne JSON en un objet JavaScript
        const retrievedData = JSON.parse(storedData);

        // Vérifier si l'objet récupéré est du bon type
        if (retrievedData && isTypeDissertation(retrievedData, getEmptyDissertation())) {
            setDissertation(retrievedData as Type_Dissertation);
        } else {
            console.warn(`Les données récupérées pour '${typeSousPartie}' ne sont pas du type attendu.`);
        }
    } catch (error) {
        console.error(`Erreur lors du parsing des données pour '${typeSousPartie}' depuis localStorage:`, error);
    }
} else {
    console.warn(`Aucune donnée trouvée pour la clé '${typeSousPartie}' dans localStorage.`);
}

    }, []);

    useEffect(() => {
        setCurrentOpened(0)
    }, [dissertation.sujet])

    return <>

        {dissertation.sujet ?
            <>
                <MenuComposant
                    sujet={dissertation.sujet}
                    setObjet={setDissertation}
                    toShow={[true, true, false, false]}
                />

                <div className="composant" style={{ marginTop: '50px' }}>
                    <SousPartie

                        sujet={dissertation.sujet}
                        objet={dissertation}
                        setObjet={setDissertation}
                        element={'developpement.0.sousparties.0'}
                        modeComposant={true}
                        currentOpened={currentOpened}
                        setCurrentOpened={setCurrentOpened}
                        typeSousPartie={typeSousPartie}

                    />
                </div>
            </>
            :
            <>
                {showNewDissertation && (
                    <NouvelleDissertation
                        isOpen={showNewDissertation}
                        onClose={() => setShowNewDissertation(false)}
                        toShow={[true, true, false, false]}
                        validate={addNewSujet(setDissertation)}
                    />
                )}
                <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}}  onClick={() => setShowNewDissertation(true)}>
                    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
                    <div className="texteAjouterDissert">Ajouter<br />un sujet</div>
                </div>
            </>}
    </>
}

export default T_SousPartie