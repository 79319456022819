import _ from 'lodash';
import React, { FC, useState, useEffect, useCallback } from 'react';
import tuteurImage from './../../../assets/tuteur.png';
import { BeatLoader } from 'react-spinners';
import { Prompt } from '~./types';
import { Type_Dissertation } from '../Dissertation/StructureDissertation';
import askChatGPT from '~app/askChatGPT';
import { notation_prompt1, notation_prompt2 } from '~app/components/Prompts';

interface Props {
  prompt: string | Prompt[];
  input: string;
  prompt2?: string | Prompt[];
  input2?: string;
  setEval_input: (value: string) => void;
  setNote: (value: number) => void;
  required_inputs: string[];
  objet?: Type_Dissertation;
  setObjet?: (v: Type_Dissertation) => void;
}

const TuteurInfo: FC<Props> = ({
  prompt,
  input,
  prompt2,
  input2,
  setEval_input,
  setNote,
  required_inputs,
  objet,
  setObjet
}) => {
  const [thinking, setThinking] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [prevRequiredInputs, setPrevRequiredInputs] = useState<string[]>(required_inputs);

  // Utilisation de useCallback pour stabiliser la référence de setEval_input
  const stableSetEvalInput = useCallback((value: string) => setEval_input(value), [setEval_input]);

  useEffect(() => {
    // Utilise lodash pour comparer les tableaux required_inputs
    if (!_.isEqual(prevRequiredInputs, required_inputs)) {
      const allInputsEmpty = required_inputs.every(input => input.trim() === '');

      // Met à jour seulement si tous les inputs sont vides et que le champ n'est pas déjà vide
      if (allInputsEmpty && input !== '') {
        stableSetEvalInput('');
      }

      // Met à jour le précédent required_inputs
      setPrevRequiredInputs(required_inputs);
    }
  }, [required_inputs, input, stableSetEvalInput, prevRequiredInputs]);

  const handleSubmit = async () => {
    try {
      setThinking(true);
      setGenerating(true);
      setEval_input(" ");
      setNote(0);

      let initialPrompt: string;

      if (typeof prompt === 'string') {
        initialPrompt = prompt;
      } else if (Array.isArray(prompt) && prompt.length > 0) {
        const lastPrompt = prompt[prompt.length - 1];
        initialPrompt = lastPrompt.beforePrevious + lastPrompt.afterPrevious;
        initialPrompt = await askChatGPT(initialPrompt, () => {}, () => {}, () => {}, true);
        initialPrompt = prompt[0].beforePrevious + initialPrompt + prompt[0].afterPrevious;
      } else {
        initialPrompt = ""; // Cas par défaut si aucun prompt valide
      }

      let result = await askChatGPT(initialPrompt, setEval_input, setThinking, setGenerating);

      let evalPrompt = notation_prompt1 + result + notation_prompt2;
      let note = await askChatGPT(evalPrompt, () => {}, () => {}, () => {});

      if (Number.isInteger(Number(note))) {
        setNote(Number(note));
      } else {
        setNote(0);
      }

    } catch (error) {
      console.error('Error querying the API:', error);
    }
  };

  const clickStop = () => {
    // Logique pour arrêter la génération
  };

  return (
    <div className="tuteurContainer">
      <div className="image-container">
        {required_inputs.every(input => input.trim() !== '') && !generating ? (
          <img src={tuteurImage} alt="Tuteur" className="tutor-image" onClick={handleSubmit} />
        ) : (
          <img src={tuteurImage} alt="Tuteur" className="tutor-image tutor-image-off" />
        )}
        {!true && generating && <button className="button-stop" onClick={clickStop}>Stop</button>}
      </div>
      <div className="textarea-container">
        <textarea
          value={input}
          className="text-field textarea-input"
          readOnly
          placeholder="Cliquez sur le tuteur pour avoir son avis"
        />
        <div className="loader-container">
          {thinking && <BeatLoader size={10} className="leading-tight" />}
        </div>
      </div>
    </div>
  );
};

export default TuteurInfo;
