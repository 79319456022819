import {
  Introduction,
  Conclusion,
  Contenu,
  Souspartie,
  Partie,
  Problematique,
  Concepts,
  Definitions,
  Type_Dissertation,
  Dvlpt_Souspartie,
} from '~./app/components/Dissertation/StructureDissertation'
import _ from 'lodash'


export const getEmptyContenu = (): Contenu => _.cloneDeep({ contenu: '', note: 0, evaluation: '' })

export const getEmptySouspartie = (): Souspartie => ({
  intro: getEmptyContenu(),
  dvlpt_souspartie:getEmptyDvlptSousPartie(),
  conclusion: getEmptyContenu(),
})

export const getEmptyDvlptSousPartie = (): Dvlpt_Souspartie => ({
  type_dvlpt:0,
  exposition_dvlpt:'',
  analyse_dvlpt:'',
  note_dvlpt:0,
  evaluation_dvlpt:''
})

export const getEmptyPartie = (): Partie => ({
  annonce:getEmptyContenu(), 
  intro: getEmptyContenu(),
  sousparties: [getEmptySouspartie(), getEmptySouspartie()],
  conclusion: getEmptyContenu(),
})

export const getEmptyDefinitions = (): Definitions => ({
  def_concept1: getEmptyContenu(),
  def_concept2: getEmptyContenu(),
})

export const getEmptyIntroduction = (): Introduction => ({
  accroche: getEmptyContenu(),
  concepts: {
    concept1: '',
    concept2: '',
    note: 0,
    eval_concepts: '',
  },
  definitions: getEmptyDefinitions(),
  problematique: {
    problematique1: getEmptyContenu(),
    problematique2: getEmptyContenu(),
    synthese: getEmptyContenu(),
  }
})

export const getEmptyDeveloppement = (): Partie[] => [getEmptyPartie(), getEmptyPartie()]

export const getEmptyConclusion = (): Conclusion => ({
  synthese: getEmptyContenu(),
  ouverture: getEmptyContenu(),
})

export const getEmptyDissertation = (): Type_Dissertation => ({
  sujet: '',
  intro: getEmptyIntroduction(),
  developpement: getEmptyDeveloppement(),
  conclusion: getEmptyConclusion(),
})
