// ... (les autres imports)
import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getEmptyDissertation } from '../DissertationVide';
import { DissertationAvecId, Type_Dissertation } from '../StructureDissertation';
import { InfosBack } from '~types';
import NouvelleDissertation from '~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation';

import oeil_img from '~assets/oeil_grand.svg'
import delete_img from '~assets/delete.svg'
import menu_info from '~assets/menu_info_grand.svg'

import plus_bleu from '~assets/plus_bleu.svg'

import Tooltip from '~app/components/Tooltip'
import StatsDissert from '~app/components/Info/Dialog/StatsDissert'

import ViewText from '~app/components/Info/ViewText/ViewText'
import { RemoveDissertationContext } from '~app/components/Context';
import { getStats } from '~app/components/Funcs/Funcs';

import { useNavigate } from 'react-router-dom'
import QuestionDialog from '~app/components/Info/Dialog/QuestionDialog';


interface Props {
  dissertations: DissertationAvecId[]
  setDissertations: (v: DissertationAvecId[]) => void
}


const DissertationList: React.FC<Props> = ({ dissertations, setDissertations }) => {
  const [showStatsDissert, setShowStatsDissert] = useState<boolean>(false)
  const [isOpenEmpty, setOpenEmpty] = useState(false)
  const [idToRemove_, setIdToRemove_] = useState("")

  const [partie, setPartie] = useState<number[]>([])
  const [isOpenView, setOpenView] = useState(false)
  const [id, setId] = useState(0)
  const [idInfos, setIdInfos] = useState(0)
  const [showNewDissertation, setShowNewDissertation] = useState(false)

  const navigate = useNavigate()

  const [response, setResponse] = useState<string>('');




  const context = useContext(RemoveDissertationContext)
  if (!context) throw new Error('RemoveDissertationContext is undefined')
  const [removeDissertation] = context


  const handleRemoveDissertation = () => {
    //console.log("On supprime l'idToRemove_ :", idToRemove_)
    removeDissertation(idToRemove_)
  };

  const generateId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  
  const generateUniqueId = (existingIds: string[]) => {
    let newId = generateId();
    while (existingIds.includes(newId)) {
      newId = generateId();
    }
    return newId;
  };
  
  const addNewDissertation = (newDissertation: Type_Dissertation) => {
    if (newDissertation) {
      if (newDissertation.sujet.trim() === "") {
        console.log("Il est nécessaire d'ajouter un sujet.");
      } else {
        // Récupérer les dissertations existantes depuis localStorage
        const storedDissertations = localStorage.getItem('Dissertation');
        let updatedDissertations: DissertationAvecId[] = [];
  
        if (storedDissertations) {
          try {
            updatedDissertations = JSON.parse(storedDissertations);
          } catch (error) {
            console.error("Erreur lors de l'analyse des dissertations stockées : ", error);
          }
        }
  
        // Extraire les IDs existants
        const existingIds = updatedDissertations.map((dissert) => dissert.id);
  
        // Générer un ID unique
        const newId = generateUniqueId(existingIds);
  
        const newDissertationFinale: DissertationAvecId = {
          id: newId,
          contenu: newDissertation,
        };
  
        // Ajouter la nouvelle dissertation à la liste
        updatedDissertations = [...updatedDissertations, newDissertationFinale];
  
        // Mettre à jour localStorage avec les nouvelles dissertations
        localStorage.setItem('Dissertation', JSON.stringify(updatedDissertations));
  
        // Mettre à jour l'état local
        setDissertations(updatedDissertations);
  
        // Naviguer vers la nouvelle dissertation
        navigate("/dissertation/" + newId);
      }
    } else {
      console.log("Aucune dissertation n'a été définie !");
    }
  };
  

  const viewClick = (partie: number[], id: number) => (event: React.MouseEvent) => {
    event.stopPropagation()
    setPartie(partie)
    setId(id)
    setOpenView(true)
  }

  const montrerInfos = (index: number) => (event: React.MouseEvent) => {
    event.stopPropagation()

    setIdInfos(index)
    setShowStatsDissert(true)
  }

  const showDelete = (idToRemove: string) => (event: React.MouseEvent) => {
    event.stopPropagation()
    setIdToRemove_(idToRemove)
    //console.log("On fixe l'idToRemove_ :", idToRemove)
    setOpenEmpty(true)
  }

  const loadDissertation = (dissertation: DissertationAvecId) => {
    navigate("/dissertation/" + dissertation.id)
  }

  return (
    <div>
      {isOpenEmpty && (
        <QuestionDialog
          isOpen={isOpenEmpty}
          onClose={() => setOpenEmpty(false)}
          text="La dissertation sera supprimée.<br />Cette action est irréversible."
          title="Tout supprimer ?"
          reponse1="Oui"
          reponse2="Non"
          onReponse1={() => handleRemoveDissertation()}
          classname="centrerTexte"
        />
      )}
      <div className="ajouterDissertation" style={{marginTop: '30px', marginBottom: '20px'}} onClick={() => setShowNewDissertation(true)}>
        <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
        <div className="texteAjouterDissert">Ajouter<br />une dissertation</div>
      </div>

      {[...dissertations].reverse().map((dissertation, index) => (
        <div className="componentDissertations" key={dissertation.id} onClick={() => loadDissertation(dissertation)}>
          <div className="bar-filled behind" style={{ width: `${getStats(dissertation.contenu)[0]}%` }}></div>
          <div className="forHover"></div>

          <div style={{ width: '120px' }}>
            <Tooltip text="Infos sur la dissertation" direction="up">
              <img src={menu_info} className="imagesMenuChargement" onClick={montrerInfos(dissertations.length - index - 1)} />
            </Tooltip>
            <Tooltip text="Visualiser la dissertation" direction="up">
              <img src={oeil_img} className="imagesMenuChargement" style={{ marginLeft: '5px' }} onClick={viewClick([3], dissertations.length - index - 1)} />
            </Tooltip>
          </div>
          <div className="nomItemDissertation">
            <span>{dissertation.contenu.sujet}</span>
          </div>
          <div style={{ width: 50 }}>
            <Tooltip text="Supprimer la dissertation" direction="up">
              <img src={delete_img} className="imagesMenuChargement" onClick={showDelete(dissertation.id)} />
            </Tooltip>
          </div>
        </div>
      ))}
      {dissertations[idInfos] &&

        <StatsDissert isOpen={showStatsDissert} dissert={dissertations[idInfos].contenu} onClose={() => setShowStatsDissert(false)} delFunc={() => removeDissertation(dissertations[idInfos].id)} />
      }
      {showNewDissertation && (
        <NouvelleDissertation
          isOpen={showNewDissertation}
          onClose={() => setShowNewDissertation(false)}
          validate={addNewDissertation}
        />
      )}
      {isOpenView && (
        <ViewText
          dissertation={dissertations[id].contenu}
          isOpen={isOpenView}
          onClose={() => setOpenView(false)}
          partie={partie}
          currentPage={[]}
          setCurrentPage={() => { }}
        />
      )}
    </div>
  );
}

export default DissertationList;
