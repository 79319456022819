import Dialog from './Dialog'

interface Props {
    isOpen: boolean
    text:React.ReactElement,
    title:string,
    onClose: () => void
  }

  //prop classname à ajouter ?

const Error = (props: Props) => {
    return (
        <Dialog
          title={props.title}
          open={props.isOpen}
          onClose={props.onClose}
          backgroundcolor='red'
        >
            {props.text}
            </Dialog>
    )
    }
export default Error
