import { useState, FC, ReactNode } from "react"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet, addNewConcept } from "~app/components/Funcs/Funcs"
import NouveauConcept from "../Dissertation/MenuNouvelleDissertation/NouveauConcept"
import Tooltip from "../Tooltip"

interface Props {
    sujet: string
    setObjet: (v: any) => void
    element?: "dissertation" | "concept"
    toShow?: boolean[]
    onlyCompleteDissertations?:boolean
    toDo?:string
}

const MenuComposant: FC<Props> = ({ sujet, setObjet, element = "dissertation", toShow, onlyCompleteDissertations, toDo }) => {
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    type Info = {
        texte: string;
        nombre: number;
    };

    type InfoMap = {
        [key: string]: Info;
    };

    const infos: InfoMap = {
        "dissertation": { texte: "Changer le sujet", nombre: 32 },
        "concept": { texte: "Changer le concept", nombre: 4 }
    };

    return (
        <div className="component" style={{ width: '600px', padding:'20px',minWidth:0, display: 'flex', marginTop: '30px', marginBottom: '-5px', alignItems: 'center', lineHeight: 1 }}>
            <div style={{ width: '150px' }}>
                <div className="ajouterDissertation" onClick={() => setShowNewDissertation(true)}>
                <Tooltip text={infos[element].texte}  direction="down">
                    <img className="plusAjouter" src={plus_bleu} />
                    </Tooltip>
                </div>

            </div>
            <div style={{ width:'410px' }}>
                <h1 className="titreEntrainement" style={{ display: 'block', marginBottom: '15px' }}>
                    <div>{sujet}</div>
                </h1>
            </div>
           
                {element == "dissertation" ?
                            <NouvelleDissertation
                                isOpen={showNewDissertation}
                                onClose={() => setShowNewDissertation(false)}
                                validate={addNewSujet(setObjet, toDo)}
                                toShow={toShow}
                                onlyCompleteDissertations={onlyCompleteDissertations}
                            />
                            : (element == "concept") ?
                            
                            <NouveauConcept 
                            isOpen={showNewDissertation}
                            onClose={() => setShowNewDissertation(false)}
                            validate={addNewConcept(setObjet)} />
                    : <></>
                }   


        </div >
    )
}

export default MenuComposant