import React from 'react'
import RTFicon from '~assets/rtf.svg'
import Tooltip from '../Tooltip'
import { toValidFilename } from '../Funcs/Funcs'


interface MyComponentProps {
  content: string
  sujet: string
  big?:boolean
  afficherTexteComposant:(v:string,color?:string) => void
  cacherTexteComposant:() => void
  tooltipdirection?:"down"|"up"|undefined

}

const ExportRTF: React.FC<MyComponentProps> = ({ content, sujet, afficherTexteComposant, cacherTexteComposant, big=false, tooltipdirection = "down" }) => {
  const handleDownload = () => {
    const blob = new Blob([content], { type: 'text/rtf' })
    const url = URL.createObjectURL(blob)

    let a = document.createElement('a')
    a.href = url
    a.download = toValidFilename(sujet) + '.rtf'
    a.click()
    URL.revokeObjectURL(url)
    afficherTexteComposant('Fichier RTF exporté !', 'green')
  }

  return (
    <Tooltip text="Exporter en RTF" direction={tooltipdirection}>
      <img
      src={RTFicon}
      className={big ? "iconExport margin-left-20 bigIcon" : "iconExport margin-left-20"}
      onMouseOver={() => afficherTexteComposant("Exporter au format RTF")}
      onMouseOut={() => cacherTexteComposant()}
      onClick={handleDownload}
    />
    </Tooltip>
  )
}

export default ExportRTF
