import { FC, useState, ReactNode, RefObject, useRef, useEffect, useContext, Ref } from 'react'
import InfoDialog from '~app/components/Info/Dialog/InfoDialog'
import QuestionDialog from '~app/components/Info/Dialog/QuestionDialog'
import Hourglass from '~assets/icons/hourglass.png'
import { CurrentErrorComposantContext, CurrentPageContext } from '~app/components/Context'
import { arraysAreEqual } from '~app/components/Funcs/Funcs'
import { Etape_Composant, Lien } from '~types'
import close_img from '~assets/close.svg'
import info_img from '~assets/info.svg'
import trafficlight0 from '~assets/trafficlight0.svg'
import trafficlight1 from '~assets/trafficlight1.svg'
import trafficlight2 from '~assets/trafficlight2.svg'
import trafficlight3 from '~assets/trafficlight3.svg'
import reminderOff_img from '~assets/reminder_off.svg'
import reminderOn_img from '~assets/reminder_on.svg'

import { UpdateStructureContext, ReminderContext } from '~app/components/Context'  // Assurez-vous que ceci est le chemin correct

import Tooltip from './Tooltip'
import { current } from 'immer'

interface Props {
  titre: string
  singleton?: boolean
  children: ReactNode
  info: string
  title_info: string
  onEmpty: () => void
  note: number
  handleTitleClick?: () => void
  opened?: boolean
  toDelete?: () => void
  setCurrentOpened?: (value: number) => void
  etape_composant: Etape_Composant
  showReminder?: boolean
  modeComposant?:boolean
  liens?:Lien[]
}

interface PropsContext {
    etape_composant:Etape_Composant
    setCurrentOpened?: (value: number) => void
    currentRef:React.RefObject<HTMLDivElement>

}

const ContextFuncs:FC<PropsContext> = ({etape_composant, setCurrentOpened, currentRef}) => {
  const context = useContext(CurrentErrorComposantContext)
  if (!context) throw new Error('CurrentErrorComposantContext is undefined')
  const [, , composantToGo, setComposantToGo] = context

  const context2 = useContext(CurrentPageContext)
  if (!context2) throw new Error('CurrentPageContext is undefined')
  const [currentPage,] = context2




  useEffect(() => { 
    if (arraysAreEqual(currentPage, etape_composant.etape) && (composantToGo == etape_composant.composant)) {
      if (setCurrentOpened) {
        setCurrentOpened(composantToGo)
      }
      currentRef?.current?.scrollIntoView({ behavior: 'smooth' })
      setComposantToGo(undefined)

    }
  }, [composantToGo])
  
  return <></>
}

const Titre_container: FC<Props> = ({
  titre,
  singleton,
  children,
  handleTitleClick,
  opened,
  info,
  title_info,
  note,
  onEmpty,
  toDelete,
  setCurrentOpened,
  etape_composant,
  showReminder,
  modeComposant,
  liens
}) => {
  const [isOpenInfo, setOpenInfo] = useState(false) // attention, est pour la boite de dialogue, non pour le composant
  const [isOpenEmpty, setOpenEmpty] = useState(false)
  const currentRef = useRef<HTMLDivElement>(null)

  const context3 = useContext(UpdateStructureContext)
  if (!context3) throw new Error('UpdateStructureContext is undefined')
  const [, setUpdateStructure] = context3


  const context4 = useContext(ReminderContext)
  if (!context4) throw new Error('ReminderContext is undefined')
  const [reminder, setReminder] = context4

  const handleClickInfo = (event: React.MouseEvent) => {
    if (opened || singleton) {
      //si le titre n'est pas réduit
      event.stopPropagation() // On évite que le click ne se propage au handleclick
      setOpenInfo(true)
    }
  }

  const handleClickReminder = (event: React.MouseEvent) => { //à refaire
    if (opened || singleton) {
      //si le titre n'est pas réduit
      event.stopPropagation() // On évite que le click ne se propage au handleclick
      setReminder(!reminder)
    }
  }

  const handleClickEmpty = (event: React.MouseEvent) => {
    if (opened || singleton) {
      //si le titre n'est pas réduit

      event.stopPropagation() // On évite que le click ne se propage au handleclick
      setOpenEmpty(true)
    }
  }
  const handleDeleteClick = (event: React.MouseEvent) => {
    if (opened || singleton) {
      //si le titre n'est pas réduit

      event.stopPropagation() // On évite que le click ne se propage au handleclick
      if (toDelete) toDelete()
    }
  }
  const getTrafficLightClassName = () => {
    var clName =
      note === 1
        ? trafficlight1
        : note === 2
          ? trafficlight2
          : note === 3
            ? trafficlight3
            : note === 4
              ? trafficlight0
              : trafficlight0
    //if (!opened && !singleton) clName += ' handcursor'
    return clName
  }
  return (
      <div className="component" ref={currentRef}>
        {!modeComposant && 
          <ContextFuncs
            etape_composant={etape_composant}
            setCurrentOpened={setCurrentOpened}
            currentRef={currentRef}
            />
        }
        <div
          className={`title-container ${singleton ? '' : 'title-ouvrable'} ${!opened && !singleton ? 'title-ferme' : ''
            } `}
          onClick={handleTitleClick}
        >
          <Tooltip text="Avis du tuteur">

            <button className="titre-button titre-button-opened avisTuteur">
              <img src={getTrafficLightClassName()} className="boutonsContainer" />
              {note == 4 && <div className="wait">...</div>}
            </button>
          </Tooltip>
          <h2 className="title">
            {titre}
            {toDelete && (
              <button className="toDelete" onClick={handleDeleteClick}>
                Supprimer
              </button>
            )}
          </h2>
          {(showReminder && !modeComposant) &&
            <Tooltip text="Afficher le pense-bête">
              <button
                className={`titre-button ${opened || singleton ? 'titre-button-opened scalable' : ''}`}
                onClick={handleClickReminder}
              >
                <img src={reminder ? reminderOn_img : reminderOff_img} className="boutonsContainer" />
              </button>
            </Tooltip>
          }
          <Tooltip text="Informations">
            <button
              className={`titre-button ${opened || singleton ? 'titre-button-opened scalable' : ''}`}
              onClick={handleClickInfo}
            >
              <img src={info_img} className="boutonsContainer" />
            </button>
          </Tooltip>
          <Tooltip text="Tout supprimer">
            <button
              className={`titre-button ${opened || singleton ? 'titre-button-opened scalable' : ''}`}
              onClick={handleClickEmpty}
            >
              <img src={close_img} className="boutonsContainer" />
            </button>
          </Tooltip>

        </div>
        {isOpenInfo && (
          <InfoDialog
            isOpen={isOpenInfo}
            onClose={() => setOpenInfo(false)}
            text={info}
            title={title_info}
            liens={liens}
            />
        )}
        {isOpenEmpty && (
          <QuestionDialog
            isOpen={isOpenEmpty}
            onClose={() => setOpenEmpty(false)}
            text="Tous les champs de ce composant vont être réinitialisés. Cette action est irréversible."
            title="Tout supprimer ?"
            reponse1="Oui"
            reponse2="Non"
            onReponse1={() => { onEmpty(); setUpdateStructure(true) }}
          />
        )}

        <div className={`${!singleton ? `content-container ${opened ? 'open' : ''}` : 'content-container open'}`}>
          <div className="containerLigne">
            <div className="ligne"></div>
          </div>
          <div className="inner-container">{children}</div>
        </div>
      </div>
  )
}

export default Titre_container
