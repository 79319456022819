import { useState, useEffect } from "react"
import { ElementReminder, Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"
import { getEmptyDissertation } from "~app/components/Dissertation/DissertationVide"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"
import MenuComposant from "./MenuComposant"
import Synthese from "../Dissertation/Conclusion/Synthese"
import Ouverture from "../Dissertation/Conclusion/Ouverture"

const T_Ouverture = () => {
    const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [showNewDissertation, setShowNewDissertation] = useState(false)


    
    useEffect(() => {
        if (dissertation && dissertation.sujet) {
        // Convertir l'objet dissertation en chaîne JSON et le stocker dans localStorage
localStorage.setItem('Ouverture', JSON.stringify(dissertation));

// Optional: Ajouter un log pour vérifier l'enregistrement
console.log("Dissertation enregistrée sous la clé 'Ouverture' dans localStorage:", dissertation);

        }
    }, [dissertation])


    useEffect(() => {
// Récupérer les données 'Ouverture' depuis localStorage
const storedData = localStorage.getItem('Ouverture');

if (storedData) {
    try {
        // Convertir la chaîne JSON en un objet JavaScript
        const data = JSON.parse(storedData);

        // Vérifier si l'objet récupéré est du bon type
        if (data && isTypeDissertation(data, getEmptyDissertation())) {
            setDissertation(data as Type_Dissertation);
        } else {
            console.warn("Les données récupérées ne sont pas du type attendu.");
        }
    } catch (error) {
        console.error("Erreur lors du parsing des données depuis localStorage:", error);
    }
} else {
    console.warn("Aucune donnée trouvée pour la clé 'Ouverture' dans localStorage.");
}

    }, [])


    return <>

    

    {dissertation.sujet ? 
<>
<MenuComposant
        sujet={dissertation.sujet}
        setObjet={setDissertation}
        toShow={[false, false, true, false]}
        onlyCompleteDissertations={true}
        toDo={'supprimerOuverture'}
    />

<div className="component" style={{ marginTop: '50px', textAlign: 'justify', padding: '10px' }}>
      <div style={{ marginBottom: '10px' }}>Vous devez rédiger l'ouverture qui suivrait la conclusion suivante :</div>
      <div style={{ fontSize: '13px' }}> {dissertation.conclusion.synthese.contenu}
      </div>
    </div>

    <div className="composant" style={{marginTop:'50px'}}>
    <Ouverture
        sujet={dissertation.sujet}
        objet={dissertation}
        element={"conclusion.ouverture"}
        setObjet={setDissertation}
        infosReminder={{categorie:"Synthèse de la dissertation", texte:dissertation.conclusion.synthese.contenu}}
        modeComposant={true}
      />
    </div>
    </>
    :
    <>
          {showNewDissertation && (
        <NouvelleDissertation
        isOpen={showNewDissertation}
        onClose={() => setShowNewDissertation(false)}
        toShow={[false,false, true, false]}
        validate={addNewSujet(setDissertation, "supprimerOuverture")}
        onlyCompleteDissertations={true}

      />
      )} 
    <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}}  onClick={() => setShowNewDissertation(true)}>
    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
    <div className="texteAjouterDissert">Ajouter<br />un sujet</div>
  </div>
  </>}
    </>
}

export default T_Ouverture