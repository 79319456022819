import { useEffect, FC } from "react";
import { DissertationAvecId } from "../StructureDissertation";
import { Type_Dissertation } from "../StructureDissertation";
import { useNavigate } from 'react-router-dom'

interface Props {
    setDissertations: (v: DissertationAvecId[]) => void
    idDissertation: string | undefined
    setDissertation: (v: Type_Dissertation) => void
    setTitle: (v: string) => void
}

const ChargementDonneesDissert: FC<Props> = ({ setDissertations, idDissertation, setDissertation, setTitle }) => {

    const navigate = useNavigate()

    useEffect(() => {

        // Récupération des données depuis localStorage
        const storedDissertations = localStorage.getItem('Dissertation');
        
        if (storedDissertations) {
            // Parse the stored JSON string into an array of dissertations
            const dissertations_init = JSON.parse(storedDissertations);

            // Vérification que les données sont bien un tableau
            if (Array.isArray(dissertations_init)) {
                setDissertations(dissertations_init);

                if (idDissertation) { // si on vise une dissertation précise
                    const idToFind = idDissertation.trim();
                    const index = dissertations_init.findIndex(dissertation => dissertation.id.trim() === idToFind);

                    if (index === -1) {
                        console.error("Introuvable dans les dissertations !");
                        navigate("/dissertation/");
                    } else {
                        const selectedDissertation = dissertations_init[index].contenu;
                        setDissertation(selectedDissertation);
                        setTitle(selectedDissertation.sujet);
                    }
                }
            } else {
                console.error("Les données stockées ne sont pas un tableau valide !");
            }
        } else {
            console.warn("Aucune dissertation stockée trouvée dans localStorage !");
        }

    }, [idDissertation, navigate, setDissertations, setDissertation, setTitle]);

    return <></>
}

export default ChargementDonneesDissert;
