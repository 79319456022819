import { FC, useState, useEffect } from "react";
import MenuHorizontal from "../Menu/MenuHorizontal";
import { useNavigate } from "react-router-dom";

interface Props {
    menu:number
}

const EntrainementChargement:FC<Props> = ({menu}) => {
    const [idMenu, setIdMenu] = useState(menu)


    const navigate = useNavigate()

    useEffect(() => {
        go(idMenu.toString())()
    }, [idMenu])

    const go = (v:string) => {
        return () => navigate("/entrainement/" + v)
    }

    return (
        <div className="component" style={{ marginTop: '40px' }}>
            <div className="titreComponent titreComponent2">Sélectionnez votre exercice</div>
            <MenuHorizontal idMenu={idMenu} setIdMenu={setIdMenu} menu={["Éléments de dissertation", "Entraînement à la rédaction"]} />
            <div className="component2">
                {idMenu == 0 ? <>
                    <div className="block">
                        <div className="level2">Introduction</div>
                        <div className="level3" onClick={go("intro_complete")}>Introduction complète</div>
                        <div className="level3" onClick={go("accroche")}>Accroche</div>
                        <div className="level3" onClick={go("problematiser")}>Problématiser</div>
                        <div className="level3" onClick={go("plan")}>Construire un plan</div>
                    </div>

                    <div className="block">
                        <div className="level2">Rédiger une sous-partie</div>
                        <div className="level3" onClick={go("developpement_libre")}>Développement libre</div>
                        <div className="level3" onClick={go("reference")}>Référence</div>
                        <div className="level3" onClick={go("exemple")}>Exemple</div>
                        <div className="level3" onClick={go("distinction_conceptuelle")}>Distinction conceptuelle</div>
                    </div>

                    <div className="block">
                        <div className="level2">Conclusion</div>
                        <div className="level3" onClick={go("synthese")}>Synthèse</div>
                        <div className="level3" onClick={go("ouverture")}>Ouverture</div>
                    </div>
                </>
                    : <>

                        <div className="block">
                            <div className="level2">Lire et comprendre</div>
                            <div className="level3" onClick={go("trous")}>Textes à trous</div>
                            <div className="level3" onClick={go("reconstituer")}>Textes à reconstituer</div>
                        </div>

                        <div className="block">
                            <div className="level2">Argumenter</div>
                            <div className="level3" onClick={go("definir")}>Définir un concept</div>
                            <div className="level3" onClick={go("these")}>Formuler une thèse</div>
                            <div className="level3" onClick={go("connecteurs")}>Connecteurs logiques</div>
                        </div>
                    </>}
            </div>
        </div>
    );
}
export default EntrainementChargement