import { RTF_HEADER1, RTF_HEADER2 } from '~app/consts'
import { ReactNode } from 'react'

function replaceAccentedCharacters(str: string) {
    let result = ''
    for (let i = 0; i < str.length; i++) {
      let char = str[i]
      let code = char.charCodeAt(0)
  
      // Si le caractère est hors de la plage 0x20 (espace) à 0x7E (~),
      // ou est un backslash, une accolade ou un guillemet droit, convertissez-le
      if ((code < 0x20 || code > 0x7e || char === '\\' ||char === '{' || char === '}') && (char !== "’" && char !== "-" && char !== "'")) {
        result += "\\'" + code.toString(16)
      } else if (char === "’") {
        result += "'"
      } else {
        result += char // Sinon, ajoutez le caractère tel quel
      }
    }
    return result
  }

export function getExports(texte:string, sujet:string,setRtfFile:(v:string)=>void,setClipBoardText:(v:string)=>void) {
    const content = texte; // Pour récupérer le contenu HTML de l'élément
    //console.log("Mise à jour : ", content)

    function processHTML(html: string) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const paragraphs = doc.querySelectorAll("p, br");
      for (let p of paragraphs) {
        if(p instanceof HTMLElement) { 
          clipBoard_temp += '\t' + p.outerText + '\r\n'
        addParRTF(p.outerText)
        //console.log(p.outerText); // Ici, vous pouvez voir chaque balise <p>
      }
    }
    }


    function initializeRTFFile(sujet: string) {
      rtfFile_temp = RTF_HEADER1 + replaceAccentedCharacters(sujet) + RTF_HEADER2
    }

    function addParRTF(paragraphe: string) { // on ajoute un paragraphe au .rtf
      rtfFile_temp += `\\tab ${replaceAccentedCharacters(paragraphe)}\\par`
    }

    function closeRTF() {
      rtfFile_temp += '}'
    }

    




    let temp_text: ReactNode
    let titre_temp = ''

    let rtfFile_temp = ''
    let clipBoard_temp = `Sujet : ${sujet}\r\n\r\n`
    initializeRTFFile(sujet)
    //processElements(content)
    processHTML(content)
    //addParRTF()
    closeRTF()
    setRtfFile(rtfFile_temp)
    setClipBoardText(clipBoard_temp)

    //setText(temp_text)
  }
