import { useState, useEffect } from "react"
import plus_bleu from '~assets/plus_bleu.svg'
import MenuComposant from "./MenuComposant"
import Definition from "../Taches/Definition"
import { getEmptyContenu } from "~app/components/Dissertation/DissertationVide"
import { Concept } from "../Dissertation/StructureDissertation"
import NouveauConcept from "../Dissertation/MenuNouvelleDissertation/NouveauConcept"
import { addNewConcept } from "../Funcs/Funcs"


const T_Definir = () => {
    //const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [concept, setConcept] = useState<Concept>({nomConcept:"",contenu:getEmptyContenu()})
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    useEffect(() => {
        // Vérifier si le concept est défini et a un nom
if (concept && concept.nomConcept) {
    // Convertir l'objet concept en chaîne JSON et le stocker dans localStorage
    localStorage.setItem('Concept', JSON.stringify(concept));

    // Optional: Ajouter un console log pour vérifier l'enregistrement
    console.log("Concept enregistré dans localStorage:", concept);
}

    }, [concept])

    useEffect(() => {
        // Récupérer les données du concept depuis localStorage
        const storedData = localStorage.getItem('Concept');
    
        if (storedData) {
            try {
                // Convertir la chaîne JSON en un objet JavaScript
                const data = JSON.parse(storedData);
    
                // Mettre à jour l'état avec les données récupérées
                setConcept(data as Concept);
    
                // Optional: Ajouter un log pour confirmer le chargement
                console.log("Concept chargé depuis localStorage:", data);
            } catch (error) {
                console.error("Erreur lors du parsing des données depuis localStorage:", error);
            }
        } else {
            console.warn("Aucune donnée trouvée pour la clé 'Concept' dans localStorage.");
        }
    }, []); // Le tableau de dépendances vide signifie que cela s'exécute une fois au montage
    

    return <>

    {(concept && concept.nomConcept) ? 
<>
{
<MenuComposant
        sujet={concept.nomConcept}
        setObjet={setConcept}
        element="concept"
    />}

    <div className="composant" style={{marginTop:'50px'}}>
    <Definition
            definir={concept.nomConcept}
            sujet={""}
            objet={concept}
            setObjet={setConcept}
            element={'contenu'}
            ordre={0}
            currentOpened={0}
            setCurrentOpened={() => {}}
            modeComposant={true}
        />
    </div>
    </>
    :
    <>
          { showNewDissertation && (
                            <NouveauConcept
                            isOpen={showNewDissertation}
                            onClose={() => setShowNewDissertation(false)}
                            validate={addNewConcept(setConcept)} />
          )}
    <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}}  onClick={() => setShowNewDissertation(true)}>
    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
    <div className="texteAjouterDissert">Ajouter<br />un concept</div>
  </div>
  </>}
    </>
}

export default T_Definir