import React, {FC} from 'react'
interface Props {
    idMenu:number,
    setIdMenu:(v:number)=>void
    toShow?:boolean[]
    menu:string[]
}

const MenuHorizontal:FC<Props> = ({idMenu, setIdMenu, toShow, menu}) => {
    function getStyle(x: number) {
        return (idMenu == x ? "texteFlexDiv selectedFlexDiv" : "texteFlexDiv")
    }

    function getHeight(x: number): string {
        return (idMenu == x ? "6px" : "0px")
    }

    return (
        <div className="topDiv">
                    <div className="containerMenuNouvelleDissert">
                    {menu.map((elem, index) => (
    <React.Fragment key={index}>
        {(!toShow || (toShow && toShow[index])) &&
            <div className="flexDiv" key={index}>
                <div className="hover-overlay" onClick={() => setIdMenu(index)}></div>
                <div className={getStyle(index)}>{elem}</div>
                <div className="color-bar" style={{ height: getHeight(index) }}></div>
            </div>
        }
    </React.Fragment>
))}
                    </div>

                </div>
    )
}

export default MenuHorizontal