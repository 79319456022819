import { tutoriels } from "~app/consts";
import { useNavigate } from "react-router-dom";

const MenuTutoriels = () => {
    const navigate = useNavigate();

    const go = (key: string) => {
        navigate("/tutoriels/" + key);
    };

    return (
        <div style={{ marginTop: '70px' }}>
            {tutoriels.map((tutoriel, index) => (
                <div key={index} className="componentDissertations menuTutoriels" onClick={() => go(tutoriel.lien)}>
                    <div className="nomItemDissertation">
                        <span>{tutoriel.titre}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MenuTutoriels;
