import React, { useState, useEffect, useRef } from 'react';
import plumeOn from '~assets/menu_plume_on.svg';

import entrainementOn from '~assets/menu_entrainement_on.svg';

import tutorielsOn from '~assets/menu_tutoriels_on.svg';

import infoIconOn from '~assets/menu_info_on.svg';
import { InfosBack } from '~types';

import FlecheG from '~assets/fleche_g_menu.svg'
import { useNavigate } from 'react-router-dom'

import Tooltip from '../Tooltip';

interface Props {
    title: string,
    menu:string
    setTitle: (v: string) => void,
    infosBack: InfosBack | undefined
    setInfosBack: (v: InfosBack) => void
}
const Menu: React.FC<Props> = ({ title, menu, setTitle, infosBack }) => {
    //const [grandePartieMenu, setGrandePartieMenu] = useState(0)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate= useNavigate()
    const divRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);
    const [modeMini, setModeMini] = useState(false)

    useEffect(() => {
      if (divRef.current) {
        setWidth(divRef.current.offsetWidth);
      }
    }, [divRef.current?.offsetWidth, title]);



    useEffect(() => {
        const scrollBarWidth=18
        const flecheWidth = infosBack ? 150 : 0
        const widthInterface = 376
        const totalWidth = width + widthInterface + flecheWidth + scrollBarWidth
        if (totalWidth >  windowWidth) { //les 17 pixels semblent correspondre à la scrollbar
            setModeMini(true)
            //console.log("ça dépasse", width, totalWidth, windowWidth)
        } else {
            setModeMini(false)
            //console.log("ça rentre", width, totalWidth, windowWidth)
        }
    }, [width, windowWidth])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Ajouter un écouteur d'événement au montage du composant
        window.addEventListener('resize', handleResize);

        // Supprimer l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getHeight(x: string): string {
        return (menu == x ? "6px" : "0px")
    }

    function getStyle(x: string) {
        return (menu == x ? "menu-image" : "menu-image filtered-image")

    }

    return (
        <div className="container">
            <div className="fixed-div">
                <div className="left-div">
                    <Tooltip text="Dissertation complète" direction="down">
                        <div className="menu-item">
                            <div className="hover-overlay" onClick={() => navigate("/dissertation/")}></div>
                            <div className={getStyle("dissertation")} style={{ backgroundImage: `url(${plumeOn})` }}></div>
                            <div className="color-bar" style={{ height: getHeight("dissertation") }}></div>
                        </div>
                    </Tooltip>
                    <Tooltip text="Entraînement" direction="down">
                        <div className="menu-item">
                            <div className="hover-overlay" onClick={() => navigate("/entrainement/0")}></div>
                            <div className={getStyle("entrainement")} style={{ backgroundImage: `url(${entrainementOn})` }}></div>
                            <div className="color-bar" style={{ height: getHeight("entrainement") }}></div>
                        </div>
                    </Tooltip>
                    <Tooltip text="Tutoriels" direction="down">
                        <div className="menu-item">
                            <div className="hover-overlay" onClick={() => navigate("/tutoriels/")}></div>
                            <div className={getStyle("tutoriels")} style={{ backgroundImage: `url(${tutorielsOn})` }}></div>
                            <div className="color-bar" style={{ height: getHeight("tutoriels") }}></div>
                        </div>
                    </Tooltip>
                </div>
                <div className="center-div" style={{ justifyContent: modeMini ? 'left' : 'center' }}>
                    {infosBack &&
                        <div style={{ width: '45px', height: '30px' }}>
                            <div className="imgFlecheMenu imgFlecheImageG" onClick={() => navigate(infosBack.URL)} style={{ marginRight: '30px' }}>
                                <Tooltip text={infosBack.message} direction='down'>
                                    <img src={FlecheG} className="imgFlecheImage " />
                                </Tooltip>
                            </div>
                        </div>
                    }
                    <span className={infosBack ? `centered-text ${modeMini ? 'avecFlecheModeMini' : 'avecFleche'}` : 'centered-text'}>{title}</span>
                    <div className="centered-text hiddenText"  ref={divRef}>{title}</div>
                </div>
                <div className="right-div" style={{ marginLeft: modeMini ? '0px' : '112px' }}>
                    <div className="menu-item right-item">
                        <div className="hover-overlay" onClick={() => navigate("/apropos/")}></div>
                        <div className={getStyle("apropos")} style={{ backgroundImage: `url(${infoIconOn})` }}></div>
                        <div className="color-bar" style={{ height: getHeight("apropos") }}></div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Menu;
