import logo from '~assets/icons/icone_m.svg'

const Apropos = () => {
    return (
        <>
            <div className="component apropos" style={{ width: '750px', marginTop: '70px', alignItems: 'center', padding: '20px', textAlign: 'justify' }}>
                <table>
                    <tr style={{ verticalAlign: 'top' }}>
                        <td style={{ width: '300px', verticalAlign: 'top' }}>
                            <img src={logo} style={{ width: '200px', height: '200px', margin: 'auto', borderRadius: '10px' }} />
                        </td>
                        <td ><p style={{ marginTop:'20px'}}><b>MethodoPhilo</b> est une application web destinée à accompagner le travail de la <b>méthodologie de la dissertation</b> en philosophie.</p>
                            <p>L'évaluation des productions s'appuie sur <b>chatGPT</b> d'OpenAI.</p>
                        </td>
                    </tr>
                </table>
                <p>Retrouvez sur cette vidéo des <b>explications complètes</b> sur toutes les fonctionnalités de l'application :</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ogZD4hA1Mk0?si=tJ7N7Ls2DV0j5qOQ" title="YouTube video player"  style={{border: 'none', margin:'auto', marginBottom:'30px'}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p style={{marginBottom:'10px'}}><b><u>Attention :</u></b></p>
                <p style={{marginLeft:'30px'}}><p>- Le recours aux capacités génératives de chatGPT implique une <b>grande variance</b> des commentaires et des notes produits. Il ne faut en aucun cas prendre trop au sérieux ces éléments ; parfois, réitérer la requête suffit pour produire une évaluation bien plus pertinente. En dernière instance, <b>rien ne saurait remplacer l'évaluation fournie par un professeur compétent de philosophie</b>.</p>
                <p>- Il est évident que chaque professeur de philosophie a des attentes méthodologiques qui lui sont propres, et qui s'inscrivent dans un projet pédagogique donné. Il faut prendre les principes de méthode sur lesquels cette extension se construit comme des <b>suggestions utiles</b>, et non comme des <b>exigences absolues</b>, qui seraient partagées de façon consensuelle par l'ensemble des correcteurs de philosophie.</p></p>
                <p className="aproposInfo">
                    <a href="https://vmirebeau.fr" target="_blank" rel="noopener noreferrer">
                        <b>Cliquez ici</b> pour découvrir d'autres applications
                    </a>
                </p>
                <p>Pour toute question, commentaire ou contribution, n'hésitez pas à m'écrire à l'adresse <a href="mailto:vmirebeau.dev@gmail.com" className="link">vmirebeau.dev@gmail.com</a></p>
                

            </div>
        </>
    )
}

export default Apropos