import React, { ReactElement } from 'react';
import genre_diffPic from '~assets/tuto/genre_difference.png'
import syllogismePic from '~assets/tuto/syllogisme.png'
import GetLinkMenu from './components/Info/GetLinkMenu';



export const BLUE = '#2A4798'

export const RTF_HEADER1 = `{\\rtf1\\ansi\\ansicpg1252\\deff0\\nouicompat\\deflang1036{\\fonttbl{\\f0\\fnil\\fcharset0 Calibri;}}
{\\*\\generator Riched20 10.0.19041}\\viewkind4\\uc1 
\\pard\\sa200\\sl276\\slmult1\\qc\\b\\f0\\fs28\\lang12 `

export const RTF_HEADER2 = `\\par

\\pard\\sa200\\sl276\\slmult1\\qj\\b0\\fs22\\par`

export const CHATGPT_HOME_URL = 'https://chat.openai.com/chat'

export const CHATGPT_API_MODELS = ['gpt-3.5-turbo', 'gpt-4', 'gpt-4-32k']

//Pour la dissertation :
//A importer avec import { DVLPT_LIBRE, REFERENCE, EXEMPLE, DISTINCTION_CONCEPTUELLE, RAISONNEMENT } from '~./app/consts';
/*export const DVLPT_LIBRE:number =               0;
export const REFERENCE:number =                 1;
export const EXEMPLE:number =                   2;
export const DISTINCTION_CONCEPTUELLE:number =  3;
export const RAISONNEMENT:number =              4;
*/
export interface Types_Sousparties {
  nom: string
  texte1: string
  texte2?: string | undefined
}

export const types_sousparties: Types_Sousparties[] =
  [
    {
      nom: "Développement libre",
      texte1: "Rédigez librement votre développement :"
    },
    {
      nom: "Référence",
      texte1: "Exposez votre référence :",
      texte2: "Analysez votre référence :"
    },
    {
      nom: "Exemple",
      texte1: "Exposez votre exemple :",
      texte2: "Analysez votre exemple :"
    },
    {
      nom: "Distinction conceptuelle",
      texte1: "Etablissez votre distinction conceptuelle :"
    }
  ]

export const seriesGenerales = ["l’art", "le bonheur", "la conscience", "le devoir", "l’État", "l’inconscient", "la justice", "le langage", "la liberté", "la nature", "la raison", "la religion", "la science", "la technique", "le temps", "le travail", "la vérité"]
export const seriesTechno = ["l’art", "la justice", "la liberté", "la nature", "la religion", "la technique", "la vérité"]

export const tutoriels = [
  {
    titre: "Définir un concept",
    lien: "definir"
  },
  {
    titre: "L'introduction de dissertation",
    lien: "introduction"
  },
  {
    titre: "Construire un plan",
    lien: "plan"
  },
  {
    titre: "Rédiger le développement",
    lien: "developpement"
  },
  {
    titre: "Vocabulaire de l'argumentation",
    lien: "vocabulaire"
  }
]

interface tutoType {
  titre: string
  contenu: ReactElement
}

export const tutos: { [key: string]: tutoType[] } = {
  'definir': [
    {
      titre: "Vocabulaire",
      contenu: <><p>En philosophie, un <b>concept</b> est une idée générale dont on a fixé le sens par une <b>définition</b>.</p>
        <p><b>- La notion d’<i>essence</i> :</b>
          <h2>- S’interroger sur l’<i>essence</i> d’une chose, c’est s’interroger sur ce qui la <i>définit</i>, sur sa nature.
            Quand on se demande  « <b>qu’est-ce que X ?</b> », on s’interroge donc sur l’<b>essence</b> de X.

            <p><h3><u>Par exemple,</u> quand on se demande ce qu’est un cercle, on s’interroge sur l’essence du cercle : on pourrait par exemple dire que l’essence du cercle, c’est d’être une figure géométrique dont tous les points sont à égale distance du centre. En philosophie on va s’interroger sur l’essence de la justice, du bonheur, de la conscience…</h3></p></h2></p>

        <p><b>- Différence essence/accident :</b>
          <h2>- Comme on l’a dit, l’essence d’une chose, c’est ce qui la définit dans sa nature, ce sont les caractères qui la constituent. Mais toutes les caractéristiques d’une chose concrète ne la définissent pas - parce qu’alors nos définitions n’auraient pas de fin ! Les caractéristiques d’une chose qui lui appartiennent sans la définir, nous les appellerons des <b>accidents</b> (on dira que ce sont des caractéristiques <b>accidentelles</b>).

            <p><h3><u>Par exemple,</u> le fait de pouvoir tenir dans la main et de pouvoir communiquer à distance sont des <b>caractères essentiels</b> d’un téléphone portable. En effet, un objet qui ne peut pas tenir dans la main ne peut pas être un téléphone portable. Mais maintenant, je remarque que mon téléphone portable peut être rouge, vert, bleu, blanc ; il peut être de marque Apple, Samsung, Nokia ; dans tous les cas, ça reste un téléphone portable. On dira donc que la couleur et la marque sont des <b>caractères accidentels</b> d’un téléphone portable, ils peuvent varier à l’infini sans que la nature de l’objet ne change.</h3></p></h2></p>
        <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/definir"
              }
            ]
          }
        />
      </>
    },
    {
      titre: "Les critères d’une bonne définition philosophique",
      contenu: <><p>Définir un concept en philosophie suppose de respecter un petit nombre de règles de bon sens :</p>
        <h2><u>Règle 1 :</u> Le mot à définir ne doit pas intervenir dans la définition (ni un synonyme !).

          <p><h3><u>Par exemple :</u> On ne peut pas définir l’amour ainsi : « <b>aimer</b>, c’est avoir des sentiments <b>amoureux</b> ». Ici on ne peut comprendre la définition qu’à condition de savoir déjà ce que signifie « amoureux »… Et donc on n’explique rien ! Il faut définir sans utiliser le mot « amour » lui-même.</h3>
          <h3>Idem pour les <b>synonymes</b> : on ne peut pas dire « le bonheur, c'est le fait d'être heureux », parce que ces deux expressions signifient exactement la même chose.</h3>
          
          </p></h2>

        <h2><u>Règle 2 :</u> Seuls les caractères <b>essentiels</b> doivent intervenir dans la définition, et jamais les caractères <b>accidentels</b>.

          <p><h3><u>Par exemple :</u> Il ne servirait à rien de définir le cercle ainsi : « un cercle est une figure géométrique dont tous les points sont à égale distance du centre, et qui peut être petite ou grande, rouge, bleue ou de toute autre couleur ». On voit que la partie en italique n’apporte en fait aucune information.</h3></p></h2>

        <h2><u>Règle 3 :</u> <b>Tous</b> les caractères essentiels doivent intervenir.

          <p><h3><u>Par exemple :</u> Si on définit un téléphone portable comme « un dispositif permettant de communiquer à distance », on voit qu’on pourrait aussi bien le confondre avec un téléphone fixe, voire avec un télégraphe, un ordinateur, un talkie-walkie... Voire même un papier et un crayon (qui peuvent servir à écrire des lettres) ! Une définition doit être complète et correspondre uniquement à la notion définie.</h3></p></h2>
          <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/definir"
              }
            ]
          }
        />
          </>
    },
    {
      titre: "La définition par genre et par différence",
      contenu: <>La manière classique de construire des définitions rigoureuses, c’est de définir « par genre et par différence ». Prenons le graphique suivant pour être clair : imaginons qu’on veuille représenter grossièrement la façon dont nous « classons » mentalement les choses.
        <img src={genre_diffPic} />
        <h2>Le <b>genre</b>, c’est la catégorie immédiatement supérieure qui contient le mot à définir.

          <p><h3><u>Par exemple :</u> Ici, on voit qu’une table est un meuble, un objet fabriqué, et une chose. Mais le genre, ici, est « meuble », dans la mesure où c’est sa catégorie immédiatement supérieure. De même, le genre de « humain » est ici « être vivant ».</h3></p></h2>

        <h2>La <b>différence</b>, c’est ce qui distingue le mot à définir des autres notions comprises dans la même catégorie.

          <p><h3><u>Par exemple :</u> Si on cherche à définir le mot « table », chercher la différence va nous demander de réfléchir à ce qui distingue une table d’une chaise, d’un tabouret, d’un lit.
            <h3>Chaise : la table doit servir de support à des objets, pas à des êtres humains</h3>
            <h3>Armoire : ce qui sert de support aux objets, c’est une seule surface surélevée</h3></h3></p></h2>

        <h2>Définir par genre et par différence, c’est d’abord exposer le <b>mot</b> à définir, puis préciser son <b>genre</b>, et enfin compléter avec sa <b>différence</b>.

          <p><h3><u>Par exemple :</u> Ici, la définition de « table » va être la suivante : « une table est un meuble constitué par une surface surélevée permettant de disposer des objets ».</h3></p></h2>
          <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/definir"
              }
            ]
          }
        />
      </>
    }
  ],
  'introduction': [
    {
      titre: "Remarques générales",
      contenu: <><p>N'oubliez pas que l'introduction est la partie la plus importante de votre devoir : parce qu’elle cadre et problématise bien, une bonne introduction met tout votre devoir sur la bonne voie. Il est donc très conseillé de rédiger d’abord toute l’introduction au brouillon, et de la reporter seulement ensuite sur la copie (le même conseil s'appliquera pour la conclusion, mais pas pour le développement).</p>
        <p>Une introduction se compose de <b>deux paragraphes</b>, le second étant réservé au plan. Nous allons ici réfléchir avec le sujet suivant : « Sommes-nous responsables de nos désirs ? »</p></>
    },
    {
      titre: "Le premier paragraphe",
      contenu: <><p><b>0. Accroche (facultatif)</b></p>
        <h2><p>Une accroche est un élément qui est censé éveiller l'intérêt du lecteur pour le problème que vous allez poser, et qui ainsi vous permet d'initier votre réflexion. C'est un élément non nécessaire. Ne rédigez une accroche que si vous avez une référence précise, intéressante et adéquate à proposer.</p></h2>

        <p><b>1. Analyse du sujet</b></p>
        <h2><p>Cette analyse doit comporter au moins une analyse des termes importants du sujet (deux ou trois maximum), qu’il faudra impérativement <b>définir</b>. Attention, si ces termes ont plusieurs sens, demandez-vous lesquels sont pertinents pour le sujet, et ne parlez que de ceux-ci. Vous pouvez stimuler votre réflexion en analysant la différence avec des <b>termes proches</b> (ex : vous expliquez ce qu’est le bonheur par différence avec le plaisir), ou avec des <b>termes opposés</b>.</p>
          <p>Dans notre exemple, les termes importants sont ceux de <b>responsabilité</b> et de <b>désir</b>. Vous pouvez remarquer qu’on ne définit pas n’importe quel sens de la responsabilité ici (il y a en de nombreux !). On ne définit que le sens qui est impliqué par l’expression « être responsable de quelque chose ».</p>
          <h3><p><b>Responsabilité :</b> Être responsable de quelque chose c’est avoir à « en répondre », c’est-à-dire devoir en assumer le sens et les conséquences</p>

            <p><b>Désir :</b> Un désir est une tendance spontanée qui me pousse vers quelque chose qui me plaît.</p></h3>

          <p>Si c’est utile, vous pouvez également repérer les <b>présupposés</b> du sujet, c’est-à-dire les affirmations que le sujet pose implicitement comme vraies. Votre développement pourra revenir sur ces présupposés pour les critiquer. Par exemple, sur le sujet « Pourquoi les hommes cherchent-ils à être libres ? », on présuppose que tous les hommes veulent être libres, ce qui est loin d’être évident.</p></h2>
          <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/definir"
              }
            ]
          }
        />
        <p><b>2. Exposition du sujet</b></p>
        <h2><p>L’idée est simple : le sujet choisi doit toujours apparaître quelque part dans votre introduction. Vous pouvez tout à fait vous contenter de le réécrire mot à mot : faites simple.</p></h2>
        <p><b>3. Problématique</b></p>
        <h2><p>Le but de la problématique est de <b>montrer pourquoi il est difficile de répondre au sujet</b>, ce qui va souvent consister à montrer <b>pourquoi aucune réponse immédiate n’est possible</b>. Il ne faut donc pas confondre la problématique et le sujet : le sujet est la question qui vous est posée, alors que la problématique est la <b>description précise d’une difficulté</b> : elle sera formulée en plusieurs phrases liées ensemble de façon rigoureuse. En philosophie, une problématique est un bloc de texte assez long !</p>
          <p>Pour construire la problématique, essayer d’identifier les deux réponses opposées auxquelles le sujet peut donner lieu (qu’on appellera R1 et R2). Attention, ne vous contentez pas d’un simple oui/non ! Il faut que ces réponses soient précises, détaillées et convaincantes. Chaque réponse doit pouvoir véritablement être soutenue de façon argumentée et intelligente.</p></h2>
          <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/problematiser"
              }
            ]
          }
        />
      </>
    },
    {
      titre: "Le second paragraphe",
      contenu: <><p>Dans le second paragraphe, vous aller <b>annoncer le plan</b>. Pour ce faire, énoncez clairement l’idée que vous allez défendre - et éventuellement critiquer ! Utilisez pour ce faire <b>une phrase par partie</b>, avec des formulations du type : "Dans une première partie, nous verrons que…"</p></>
    }
  ],
  'plan': [
    {
      titre: "Principe général",
      contenu: <><p>Un plan de dissertation se compose de grandes parties, qui se divisent elles-mêmes en sous-parties.
        Généralement, une dissertation se structure en six sous-parties, qu'on peut répartir de deux façons différentes :</p>
        <p><h2>- soit <b>trois grandes parties</b> divisées chacune en <b>deux sous-parties</b></h2>
          <h2>- soit <b>deux grandes parties</b> divisées chacune en <b>trois sous-parties</b></h2></p>
        <p>Le plan en trois grandes parties est conseillé parce qu'il vous empêche de construire votre dissertation autour d'une opposition binaire (mais ce n’est pas une obligation).</p>
        <p>Pour des raisons de simplicité, dans ce programme ce sont les parties avec deux sous-parties qui ont été systématisées. Rien ne vous empêche d'en faire trois, si vous équilibrez correctement votre développement.</p>
        <p>Nous allons travailler avec l'exemple suivant : « La vérité est-elle relative ? »</p></>
    },
    {
      titre: "La construction des parties",
      contenu: <><p><b>A. Toujours être dans le sujet</b></p>

        <h2><p>Un sujet de philosophie vous demandera toujours d’articuler plusieurs concepts ensemble. Par exemple, le sujet « La vérité est-elle relative ? » vous demande d’étudier les rapports entre <b>vérité</b> et <b>relativité</b>; le sujet « faut-il être ignorant pour être heureux ? » vous interroge sur les rapports entre <b>bonheur</b> et <b>ignorance</b>.</p>
          <p>Chacune de vos thèses de parties (et chacune de vos thèses de sous-parties) doit faire référence à <b>tous</b> ces concepts. Si on vous interroge sur les rapports entre bonheur et ignorance, une partie ou une sous-partie qui ne parlerait <b>que</b> du bonheur ou <b>que</b> de l’ignorance serait hors-sujet.</p></h2>

        <p><b>B. Construire des thèses complètes et intéressantes</b></p>

        <h2><p>Pour trouver les thèses des deux premières parties, vous pouvez vous appuyer sur votre problématique. Vos deux premières parties vont donc consister à développer, analyser et éventuellement critiquer les deux réponses opposées au sujet.</p>
          <p><b>Attention :</b> Il faut éviter l’opposition simpliste oui/non ! Certes, chacune de vos thèses penchera, en gros, du côté du non ou du côté du oui. Mais ce qui compte, c’est la <b>raison principale</b> pour laquelle vous pencheriez du côté du oui ou du non : c’est cette raison qu’il faut mettre en avant lorsque vous construisez et annoncez votre plan.</p>

          <h3><p>Par exemple, le plan suivant n’est pas intéressant :</p>
            <p><h3>I. La vérité est relative<br />
              II. La vérité n’est pas relative</h3></p>
            <p>Il faut enrichir ce plan en se demander pourquoi on pourrait affirmer chacune de ces thèses. Par exemple :</p>
            <p><h3>I. Chaque homme <b>se représente</b> différemment le monde autour de lui<br />
              II. Cependant, la <b>méthode scientifique</b> nous permet de nous assurer que notre <b>représentation</b> correspond bien à la réalité</h3></p>
            <p>Ici, j’enrichis mon plan en introduisant des concepts qui n’étaient pas directement inclus dans le sujet (représentation, méthode scientifique, certitude…). Je pourrai analyser ces concepts dans mon développement.</p></h3></h2>

        <p><b>C. Aller du plus simple au plus complexe</b></p>

        <h2><p>Dans quel ordre mettre les deux premières thèses ? Dans l’idéal, la thèse de votre première partie doit correspondre à l'<b>identifier l'opinion commune</b>, c'est-à-dire la réponse au sujet qui semble la plus évidente et vous viendrait naturellement. Le but de votre première partie sera alors de montrer que cette opinion commune ne peut pas tenir à une analyse critique un peu aboutie.</p>

          <p>Puisque vous avez montré les limites de l’opinion commune, votre seconde partie va proposer une nouvelle réponse au sujet : une thèse moins naïve et plus rigoureuse. Vous pourrez alors commencer à <b>aller dans l'abstraction philosophique</b>, de façon maîtrisée et construite. C'est ici que vous allez pouvoir construire des analyses et des distinctions conceptuelles, des raisonnements plus abstraits.</p>

          <p>Pour construire une troisième partie, vous êtes particulièrement libre de diriger votre réflexion dans le sens qui vous convient. Plusieurs possibilités s’offrent à vous :</p>
          <h2><p>- Vous pouvez considérer que la thèse II est elle-même insuffisante. Il va donc falloir construire une thèse III qui réponde aux problèmes posés par les deux précédentes thèses (c’est ce qu’on appelle un <b>plan dialectique</b>). Votre thèse III est donc la solution la plus convaincante que vous pouvez donner à la question qu’on vous pose.</p>
            <p>- Vous pouvez considérer que la thèse II est juste mais incomplète : il faut pousser plus loin. Votre thèse III pourra <b>tirer les conséquences concrètes, pratiques</b>, de ce que vous avez développé en II. L'idée est de partir de l’abstraction, et de revenir vers le concret, vers le monde « réel ».</p></h2></h2>

        <p><b>D. Les parties doivent se suivre de façon logique</b></p>

        <h2>Le test ultime pour savoir si votre plan est bon consiste à vous demander s'il aurait eu du sens à mettre la partie 2 avant la partie 1, ou la partie 3 avant la partie 2. S'il n'y a pas d'ordre logique ni de progression, votre plan n'est pas bon : chaque partie doit être à sa place et ouvrir naturellement sur la suivante.</h2>
        <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/plan"
              }
            ]
          }
        />
        </>
    },
    {
      titre: "Construction des sous-parties",
      contenu: <><p>Une fois que vous avez trouvé vos deux ou trois grandes parties, vous allez <b>chercher les sous-parties à développer</b>. Vous avez plusieurs façons de trouver vos sous-parties en fonction de votre thèse de partie ; en voici quelques-unes :</p>
        <h2><p>- <b>une progression critique</b> : vous commencez par justifiez votre thèse de partie : vous argumentez, vous définissez, vous raisonnez, vous faites des distinctions conceptuelles… Et dans une deuxième sous-partie, vous étudiez les limites de cette thèse : pourquoi ne répond-elle pas complètement au sujet ? Quels sont les problèmes qu’elle pose ?</p>
          <p><h3>C'est cette façon de procéder qui est systématisée dans cette application. C'est en effet la plus simple à comprendre, et c'est celle qui donne souvent les travaux les plus intéressants. Mais rien ne vous oblige à vous y limiter !</h3></p>
          <p>- une <b>progression par accumulation</b> : chaque sous-partie présente un argument pour votre thèse de partie. Vous mettez le plus faible d’abord, le plus fort ensuite. Attention, n’abusez pas de ces accumulations !</p>
          <p>- une <b>progression par raisonnement</b> : chaque sous-partie représente une étape de votre raisonnement</p>
          <p> - une <b>progression par redéfinition</b> : la première sous-partie est consacrée à une mise au point conceptuelle sur un des termes du sujet, dont vous changez le sens de façon très claire et explicite. La deuxième sous-partie montre les conséquences de cette redéfinition.</p>
          - etc.</h2>

        Pensez également à faire progresser vos sous-parties de façon satisfaisante : utilisez pour ce faire le test précédemment décrit (2-D), demandez-vous si vous auriez pu les disposer dans un ordre différent.</>
    },
    {
      titre: "Terminer le plan détaillé",
      contenu: <><p>Ensuite, toujours au brouillon, cherchez :
        <h2>- vos développements : allez-vous utilisez un raisonnement, une référence, une distinction conceptuelle ? Notez-le avec précision<br />
          - vos <b>transitions </b>: vous vous assurez que votre progression logique a du sens. Attention aux transitions artificielles, qui se contentent de répéter la thèse qui va suivre sous forme interrogative (du type « mais ne pourrait-on pas dire que X ? », juste avant de poser la thèse X). Votre transition doit exprimer un acte intellectuel de réflexion : on doit comprendre le raisonnement logique qui vous force à passer d’une idée à l’autre.</h2></p>

        Si vous avez du temps, vous pouvez également préparer vos conclusions.</>
    }
  ],
  'developpement': [
    {
      titre: "Principe général",
      contenu: <><p><b>Rappel</b> : un développement est constitué de deux ou trois parties, chacune divisée en deux ou trois sous-parties.</p>

        <p>
          Une partie se compose des éléments suivants :
          <h2>
            - Une <b>courte introduction</b> de partie, dans laquelle vous posez votre thèse de partie (+ vous expliquez et éventuellement définissez ou redéfinissez un terme important). Soyez clair et concis, votre introduction de partie ne doit pas dépasser deux ou trois phrases.<br />
            - Ensuite, vous allez rédiger vos <b>sous-parties</b>, sous la forme de <b>paragraphes</b>.<br />
            - Enfin, une <b>courte conclusion</b> de partie, qui répond à votre introduction et tire les enseignements de votre développement. Qu'est-ce que votre analyse vous a appris concernant votre problème ? Cette conclusion peut s’accompagner d’une transition, qui fait le lien entre les deux parties. Il s'agit de montrer que les conclusions auxquelles vous êtes arrivé ne permettent pas de répondre définitivement au problème, mais que votre réponse soulève elle-même de nouvelles questions. Idéalement, une transition prendra la forme d’une question.
          </h2>
        </p></>
    },
    {
      titre: "Conseils de rédaction",
      contenu: <><p>- Les références (auteurs, exemples...) ne doivent figurer que dans les développements des sous-parties</p>
       <p> - Vous pouvez développement votre idée de plusieurs façons :
        <h2><p>
        <p>- avec un <b>développement libre</b> : développez comme vous le désirez votre argument. Faites bien attention à la rigueur de vos arguments et à la clarté de votre expression !</p>
        <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/developpement_libre"
              }
            ]
          }
        />
     <p>- avec une <b>référence</b> : présentez une référence tirée de votre culture générale (philosophique, littéraire, artistique, historique, etc.).<h3>- Il faut d'abord l'<b>exposer</b>, c'est-à-dire la situer très clairement (auteur et nom d'ouvrage par exemple, si c'est une référence philosophique), et retranscrire son contenu de façon aussi fidèle et claire que possible.<br />- Il faut ensuite l'<b>analyser</b> et l'<b>interpréter</b> : prenez le temps de bien <b>réfléchir par vous-même</b> à la façon dont votre référence vous permet d'approfondir votre réponse au sujet.</h3>
     <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/reference"
              }
            ]
          }
        />
        </p>
    <p>- avec un <b>exemple</b>. Attention, évitez à tout prix les <b>exemples triviaux</b> ou fantastiques. Prenez soit quelque chose de <b>simple</b> et de quotidien, soit quelque chose de précis tiré de votre culture générale, politique ou historique !<h3>- Dans un premier temps, <b>exposez</b> la situation sur laquelle vous voulez vous appuyer pour développer votre argument.<br />- Ensuite, <b>analysez</b> votre exemple avec une grande finesse, en montrant à votre correcteur que vous avez fait un véritable effort pour <b>réfléchir par vous-même</b>.</h3>
    <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/exemple"
              }
            ]
          }
        /></p>
    <p>- avec une <b>distinction conceptuelle</b>. Il s'agit d'expliquer de façon <b>aussi claire que possible</b> la différence entre deux concepts qui pourraient paraître proches. On peut par exemple distinguer le droit et la force, le savoir et l'opinion... Cette distinction conceptuelle vous permettra de <b>préciser le sens de votre argument</b>.<h3>Une façon courante de procéder à une telle distinction consiste à exposer la différence de façon théorique dans un premier temps, puis de clarifier encore en l'appliquant à un exemple concret.</h3>
    <GetLinkMenu
          liens={
            [
              {
                texte: "S'entraîner",
                URL: "/entrainement/distinction_conceptuelle"
              }
            ]
          }
        /></p>
</p>
        
        <p>Evidemment, dans les faits il est possible de mélanger plusieurs types de développements (un exemple avec une distinction conceptuelle...). Gardez à l'esprit que les indications données dans cette méthodologie ne sont que des suggestions, ne gardez que ce qui vous est utile pour construire des devoirs intéressants.</p></h2></p>
       <p> - Pas de plan apparent ! Ça veut dire qu'il ne faut pas indiquer les titres des parties. Tout doit être rédigé.</p></>
    },
    {
      titre: "Structure d'une dissertation",
      contenu: <><p>Si l'on résume, une dissertation en deux parties constituée chacune de deux sous-parties prendra la forme suivante (chaque ligne indique ici un retour à la ligne !) :</p>

        <div style={{ width: '450px', margin: '30px auto', border: '1px solid black', padding: '20px' }}>
          <h2>
            <p><b>Introduction générale</b> de la dissertation
              <h4>passez deux/trois lignes</h4>
            </p>


            <p>
              <b>Courte introduction</b> de la première partie
              <h2>
                - Sous-partie 1 (paragraphe)<br />
                - Sous-partie 2 (paragraphe)
              </h2>
              <b>Courte conclusion</b> de la première partie et transition

              <h4>
                passez une ligne

              </h4> </p>        <p>
              <b>Courte introduction</b> de la deuxième partie
              <h2>
                - Sous-partie 1 (paragraphe)<br />
                - Sous-partie 2 (paragraphe)
              </h2>
              <b>Courte conclusion</b> de la deuxième partie


              <h4>
                passez deux/trois lignes

              </h4></p>
            <b>Conclusion générale</b>
          </h2>
        </div>
      </>
    }
  ],
  'vocabulaire': [
    {
      titre: "",
      contenu: <><p>Un <b>argument</b> est une justification pour une proposition donnée.
        <h2>L’argument se distingue de la <b>démonstration</b>, dans la mesure où une bonne démonstration vous <b>force</b> absolument à croire sa conclusion. L’argument, lui, peut être plus ou moins bon, mais il n’est jamais absolument contraignant.</h2></p>

        <p>
          Une <b>thèse</b> est une proposition <b>générale</b> qui peut être vraie ou fausse ;  elle est en tout cas défendue par quelqu’un, qui dispose d’un certain nombre d’arguments qu’il juge satisfaisants.
          <h2>Exemples de thèses philosophiques :
            <h3>
              • « le bonheur est une illusion »<br />
              • « l’État n’est rien d’autre que l’expression institutionnelle des intérêts particuliers de la bourgeoisie »<br />
              • « il est impossible de prouver de façon absolument définitive une vérité scientifique »
            </h3>
            <u>Astuce :</u> pour vous assurer que l’expression X est bien une thèse, essayez de l’intégrer dans la structure suivante : « il est vrai que X ». Si votre phrase est alors grammaticalement correcte, il s’agit effectivement d’une thèse.</h2>
        </p>


        <p>Une <b>hypothèse</b> est une proposition que quelqu’un pose comme vraie, mais pour laquelle il n’a pas d’<b>arguments</b>, ou des arguments qu’il sait <b>insuffisants</b>.</p>


        <p>
          Un <b>raisonnement</b> est un enchaînement logique de propositions, tel que si les propositions de départ (les prémisses) sont vraies, la proposition finale (la conclusion) est nécessairement vraie.
          <h2>Par exemple :
            <h3>
              <div style={{ position: 'relative' }}>
                <img src={syllogismePic} />

              </div>


            </h3>

            <u>Attention :</u>
            <h2>
              • Les prémisses d’un raisonnement peuvent être seulement <b>probables</b>, auquel cas la conclusion est elle-même seulement <b>probable</b>.<br />
              • Quand les prémisses sont <b>certaines</b>, la conclusion est certaine, le raisonnement est alors une <b>démonstration</b>.
            </h2>
          </h2>
        </p></>
    }
  ],
};