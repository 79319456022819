import {useEffect} from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import Layout from './components/Layout';
import { useLocation } from 'react-router-dom';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function AppRouter() {
  return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout menu="dissertation" />} />
          <Route path="/dissertation/" element={<Layout menu="dissertation" />} />
          <Route path="/dissertation/:id" element={<Layout  menu="dissertation" />} />

          <Route path="/entrainement/" element={<Layout  menu="entrainement" />} />
          <Route path="/entrainement/:id" element={<Layout  menu="entrainement" />} />


          <Route path="/tutoriels/" element={<Layout  menu="tutoriels"/>} />
          <Route path="/tutoriels/:id" element={<Layout  menu="tutoriels" />} />

          <Route path="/apropos/" element={<Layout  menu="apropos"/>} />


        </Routes>
      </BrowserRouter>
      
  );
}

export default AppRouter;
