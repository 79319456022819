import {
  FC,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'

import Dialog from '../Dialog/Dialog'
import ExportRTF from '../../Exportation/ExportRTF'

import ExportClipBoard from '../../Exportation/ExportClipBoard'

import { Type_Dissertation } from '../../Dissertation/StructureDissertation'
import { Etape_Composant } from '~types'
import { CurrentErrorComposantContext, SeeColorsContext } from '~app/components/Context'
import GetText from './GetText'
import { getExports } from '~app/components/Funcs/Export'
import GetColors from './GetColors'

interface Props {
  dissertation: Type_Dissertation
  isOpen: boolean
  onClose: () => void
  partie: number[]
  currentPage: number[]
  setCurrentPage?: (value: number[]) => void
}

//prop classname à ajouter ?

const ViewText: FC<Props> = ({ dissertation, isOpen, onClose, partie, currentPage, setCurrentPage }) => {
//  const [, setText] = useState<ReactNode>(<></>)
  const [title, setTitle] = useState('Titre temporaire')

  const context2 = useContext(SeeColorsContext)
  if (!context2) throw new Error('SeeColorsContext is undefined')
  const [seeColors, setSeeColors] = context2

  const [rtfFile, setRtfFile] = useState('')
  const [clipBoardText, setClipBoardText] = useState('')

  const context = useContext(CurrentErrorComposantContext)
  if (!context) throw new Error('CurrentErrorComposantContext is undefined')
  const [, , , setComposantToGo] = context

  const refTexte = useRef<HTMLDivElement>(null);
  const refMessageCentral = useRef<HTMLDivElement>(null);

  /*useEffect(() => {
    if (refMessageCentral.current) refMessageCentral.current.textContent = "&nbsp;";
  }, [])*/


  const afficherTexteComposant = useCallback((texte: string, couleur: string='#000000') => {
    if (refMessageCentral.current) {
      if (texte) refMessageCentral.current.textContent = texte;
      if (couleur) refMessageCentral.current.style.color = couleur;
      refMessageCentral.current.style.opacity = '1.0';
    }
    //setMessageCentralComposant(texte)
    //setColorComposant(couleur ? couleur : '#000000')
    //setFadeComposant(true)
  }, [])


  const styleObject = {
    transition: 'opacity 0.5s',
    minHeight: '25px'
  };


  const cacherTexteComposant = () => {
    if (refMessageCentral.current) refMessageCentral.current.style.opacity = '0.0'
  }

  const goThere = useCallback((partie: Etape_Composant) => {
    //console.log("on passe par là !", partie)
    console.log("GOTHERE", partie)
    onClose()
    setComposantToGo(partie.composant)
    if (setCurrentPage) setCurrentPage(partie.etape)
  }, [])

  


  useEffect(() => {

    if (refTexte.current) {
      getExports(
        refTexte.current.innerHTML,
        dissertation.sujet,
        setRtfFile,
        setClipBoardText)
    }

    //console.log("On commence ici : ", refTexte.current)

    
  }, [partie, refTexte.current])
  //
  return (
    <Dialog
      title={title}
      open={isOpen}
      onClose={onClose}
      menu={
        <>
          <ExportRTF
            content={rtfFile}
            sujet={dissertation.sujet}
            afficherTexteComposant={afficherTexteComposant}
            cacherTexteComposant={cacherTexteComposant}
          />
          <ExportClipBoard
            text={clipBoardText}
            afficherTexteComposant={afficherTexteComposant}
            cacherTexteComposant={cacherTexteComposant}
          />
          <GetColors
            seeColors={seeColors}
            setSeeColors={setSeeColors}
            afficherTexteComposant={afficherTexteComposant}
            cacherTexteComposant={cacherTexteComposant}
          />
        </>
      }
    >
      <div className={seeColors ? "ViewText couleurs" : "ViewText"} ref={refTexte}>
        <GetText partie={partie}  dissertation={dissertation} setTitle={setTitle} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere}  />
      </div>
      <div ref={refMessageCentral}  style={styleObject}></div>
    </Dialog>
  )
}
export default ViewText
