import { FC, useEffect, useState } from 'react'
import InputText from '~app/components/Inputs/InputText';
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyDissertation } from '../DissertationVide';


interface Props {
    setNewDissertation: (n: Type_Dissertation) => void
}

const NouveauSujet: React.FC<Props> = ({ setNewDissertation }) => {
    const [sujet, setSujet] = useState<string>("")

    useEffect(() => {
        const tDiss = getEmptyDissertation()
        tDiss.sujet = sujet.charAt(0).toUpperCase() + sujet.slice(1);
        setNewDissertation(tDiss)
    }, [sujet])

    return <>
        <div>Entrez ici le nouveau sujet de dissertation :</div>
        <div className="inputSujet">
            <InputText value={sujet} onChange={setSujet} placeholder="" />
        </div>
    </>
}

export default NouveauSujet