import { FC, useContext } from 'react';
import { ReminderContext } from '~app/components/Context';
import { ElementReminder } from '../StructureDissertation';

interface Props {
    elements: ElementReminder[];
}

const Reminder: FC<Props> = ({ elements }) => {
    const context4 = useContext(ReminderContext);
    if (!context4) throw new Error('ReminderContext is undefined');
    const [reminder,] = context4;

    return (
        <>
            <div className={`reminder content-container ${reminder ? 'openReminder' : ''}`}>
                <div className="inner-container">
                    {elements.map((element, index) => (
                        <div key={index}>
                            {element.texte &&
                                <div><b>{element.categorie} :</b> {element.texte}</div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Reminder;
