import { FC, useState, createRef, useEffect } from 'react'

import { Type_Dissertation } from '../StructureDissertation'
import Definition from '~./app/components/Taches/Definition'
import { getEmptyDefinitions } from '../DissertationVide'

import { getStrValue, getNumValue, setValue } from '~app/components/Funcs/Funcs'


interface Props {
    sujet:string,
    objet:Type_Dissertation,
    setObjet: (value:any) => any,
    element:string,
    setEmptyAll?: (func: () => void) => void;
}

const DeuxDefinitions: FC<Props> = ({ sujet, objet, setObjet, element, setEmptyAll }) => {
    const refs = Array(2).fill(0).map(_ => createRef<HTMLDivElement>());
    
    const getStrV = (el: string):string => getStrValue(objet, element + el);
    const getNumV = (el: string):number => getNumValue(objet, element + el);
    const setV = (el: string) => setValue(setObjet, element + el);



  
    useEffect(() => {
        if (setEmptyAll) setEmptyAll(() => () => {
            setV('.definitions')(getEmptyDefinitions())
            //setDissertation({...dissertation, intro:{...dissertation.intro, definitions:emptyDefinitions}})
        })
      }, [])



  const [currentOpened, setCurrentOpened] = useState(0);  
        return (
            <>
              
            <Definition
            definir={getStrV('.concepts.concept1')}
            sujet={sujet}
            objet={objet}
            setObjet={setObjet}
            element={'intro.definitions.def_concept1'}
            ordre={0}
            currentOpened={currentOpened}
            setCurrentOpened={setCurrentOpened}
        />
        <Definition
            definir={getStrV('.concepts.concept2')}
            sujet={sujet}
            objet={objet}
            setObjet={setObjet}
            element={'intro.definitions.def_concept2'}
            ordre={1}
            currentOpened={currentOpened}
            setCurrentOpened={setCurrentOpened}
        />
        </>
        )
}

export default DeuxDefinitions