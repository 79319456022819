import {createContext} from 'react'
import { ForbiddenEtape } from '../../../types'




type MessageCentralContext = [
    messageCentral:string,
    setMessageCentral : (v:string) => void,
    fade:boolean,
    setFade: (v:boolean) => void,
    color:string,
    setColor:(v:string) => void,
    afficherTexte : (texte:string, couleur?:string) => void,
    cacherTexte: () => void
]

type CurrentErrorComposantContext = [
    forbiddenEtape:ForbiddenEtape,
    setForbiddenEtape:(v:ForbiddenEtape) => void,
    composantToGo:number | undefined,
    setComposantToGo:(v:number | undefined) => void
]

type CurrentPageContext = [
  currentPage:number[],
  setCurrentPage:(value: number[]) => void
]

type UpdateStructureContext = [
    updateStructure:boolean,
    setUpdateStructure:(value: boolean) => void
  ]

  type ReminderContext = [
    reminder:boolean,
    setReminder:(value: boolean) => void
  ]

  type CurrentOpenedContext = [
    currentOpened:number,
    setCurrentOpened:(value: number) => void
  ]

  type DialogContext = [
    dialogVisible:boolean,
    setDialogVisible:(value: boolean) => void
  ]

  type RemoveDissertationContext = [
    removeDissertation:(id?:string) => void
  ]

  type SearchPreferencesContext = [
    concept1:string,
    setConcept1:(v:string) => void,
    concept2:string,
    setConcept2:(v:string) => void,

    typeSujet:string,
    setTypeSujet:(v:string) => void,

    checkedConcepts:{ [key: string]: boolean },
    setCheckedConcepts:React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,

    seulementNotions:boolean,
    setSeulementNotions:(v:boolean)=>void
  ]

  type SeeColorsContext = [
    seeColors:boolean,
    setSeeColors:(v:boolean)=>void
  ]



export const MessageCentralContext = createContext<MessageCentralContext | null>(null);

export const CurrentErrorComposantContext = createContext<CurrentErrorComposantContext | null>(null);


export const CurrentPageContext = createContext<CurrentPageContext | null>(null);

export const UpdateStructureContext = createContext<UpdateStructureContext | null>(null);

export const ReminderContext = createContext<ReminderContext | null>(null);

export const CurrentOpenedContext = createContext<CurrentOpenedContext | null>(null);

export const DialogContext = createContext<DialogContext | null>(null);

export const RemoveDissertationContext = createContext<RemoveDissertationContext | null>(null);

export const SearchPreferencesContext = createContext<SearchPreferencesContext | null>(null);

export const SeeColorsContext = createContext<SeeColorsContext | null>(null);
