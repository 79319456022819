import { useState, useContext, FC } from "react"
import Tooltip from "~app/components/Tooltip"
import signe_attention_n from '~assets/signe_attention_n.svg'
import signe_attention_b from '~assets/signe_attention_b.svg'
import OkCheck from '~assets/ok_check.svg'
import { CurrentErrorComposantContext } from "~app/components/Context"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"

interface Props {
    setCurrentPage:(v:number[])=>void
    sujet:string
    setDissertation:(v:Type_Dissertation) => void
}

const IntroSeuleMenu:FC<Props> = ({setCurrentPage, sujet, setDissertation}) => {
    const [imageSrc, setImageSrc] = useState(signe_attention_b)
    const [showNewDissertation, setShowNewDissertation] = useState(false)


    const context = useContext(CurrentErrorComposantContext)
    if (!context) throw new Error('CurrentErrorComposantContext is undefined')
    const [forbiddenEtape,,, setComposantToGo] = context

    const handleMouseClick = () => {
        if (forbiddenEtape) {
          setComposantToGo(forbiddenEtape.composant)
          setCurrentPage(forbiddenEtape.etape)
        }
      }

    return (
      <div className="component" style={{ width: '600px', padding:'20px',minWidth:0, display: 'flex', marginTop: '30px', marginBottom: '-5px', alignItems: 'center', lineHeight: 1 }}>
      <div style={{width:'150px'}}>
            <div className="ajouterDissertation" onClick={() => setShowNewDissertation(true)}>
        <Tooltip text={"Changer le sujet"}  direction="down">
        <img className="plusAjouter" src={plus_bleu} />
                    </Tooltip>      </div>

            </div>
            <div style={{ width:'410px' }}>
        <h1 className="titreEntrainement" style={{display:'block', marginBottom: '15px'}}>
            <div>
                  {forbiddenEtape?.nom ? (
                    <Tooltip
                      text={forbiddenEtape.currentProblem}
                      direction="down"
                      color="rgba(255, 0, 0, 0.95)"
                      textColor="black"
                      time={0}
                      fontweight={700}
                    >
                      <img
                        className="panneauAttention boutonsTitre"
                        src={imageSrc}
                        onClick={handleMouseClick}
                        onMouseOver={() => setImageSrc(signe_attention_n)}
                        onMouseOut={() => setImageSrc(signe_attention_b)}
                        style={{top:'0px'}}
                      />
                    </Tooltip>
                  ) :
                    <img
                      className="panneauOK boutonsTitre"
                      src={OkCheck}
                      style={{ top: '-5px' }}
                    />
                  }
                  </div>
                  <div>{sujet}</div>
                </h1>
                </div>
                <div className={showNewDissertation ? '' : 'hidden'}>
        <NouvelleDissertation
          isOpen={showNewDissertation}
          onClose={() => setShowNewDissertation(false)}
          validate={addNewSujet(setDissertation)}
          toShow={[true, true, false, false]}
        />
      </div>

                </div>
    )
}

export default IntroSeuleMenu