import React, {useState} from 'react'
import CloseIcon from '~assets/red_close.svg'
import Tooltip from '../Tooltip'
import { useNavigate } from 'react-router-dom'
import QuestionDialog from '../Info/Dialog/QuestionDialog'

interface MyComponentProps {
  big?:boolean
  tooltipdirection?:"down"|"up"|undefined
  delFunc:() => void
}

const DeleteDissertation: React.FC<MyComponentProps> = ({ big = false, tooltipdirection="down", delFunc }) => {
  const [isOpenEmpty, setOpenEmpty] = useState(false)

  const navigate = useNavigate()
  const handleClick = () => {
    delFunc()
    navigate("/dissertation/")
  }

  return (<>
    <Tooltip text="Supprimer la dissertation" direction={tooltipdirection}>
      <img
      src={CloseIcon}
      className={big ? "iconExport bigIcon" : "iconExport"}
      //onMouseOver={() => afficherTexteComposant("Exporter au format JSON")}
      //onMouseOut={() => cacherTexteComposant()}
      onClick={() => setOpenEmpty(true)}
    />
    </Tooltip>
    {isOpenEmpty && (
          <QuestionDialog
            isOpen={isOpenEmpty}
            onClose={() => setOpenEmpty(false)}
            text="La dissertation sera supprimée.<br />Cette action est irréversible."
            title="Tout supprimer ?"
            reponse1="Oui"
            reponse2="Non"
            onReponse1={() => { handleClick() }}
            classname="centrerTexte"
          />
        )}
    </>
  )
}

export default DeleteDissertation
