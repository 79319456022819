import {FC, useEffect, useState} from 'react'
import { tutos } from '~app/consts'
import { useNavigate } from 'react-router-dom'
import EtapesTutoriels from './EtapesTutoriels'

interface Props {
    tutoriel:string
}

const ShowTutoriel:FC<Props> = ({tutoriel}) => {
    const [currentPage, setCurrentPage] = useState(0)
    const navigate = useNavigate()

useEffect(() => {
    if (!tutos[tutoriel]) 
    {
        console.error("Tutoriel non trouvé")
        navigate("/tutoriels/")
    }
}, [])
 return (<>
 {tutos[tutoriel].length > 1 ?
 <><EtapesTutoriels
    etapes={tutos[tutoriel].map(element => element.titre)} 
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}
  />
 <div className="component textetutoriel" style={{ padding:'30px' }}>
    {tutos[tutoriel][currentPage].contenu}
</div></>
: <>
    <div className="component textetutoriel" style={{ padding:'30px',marginTop: '70px' }}>
    {tutos[tutoriel][0].contenu}
</div>
</>}
 </>)
}

export default ShowTutoriel