import { useState, useEffect } from "react"
import Dissertation from "~app/components/Dissertation/Dissertation"
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"
import { getEmptyDissertation } from "~app/components/Dissertation/DissertationVide"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"

const IntroComplete = () => {
    const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    useEffect(() => {
// Vérifier si la dissertation est définie et contient les informations nécessaires
if (dissertation && dissertation.sujet) {
  try {
      // Convertir et stocker la dissertation dans localStorage sous la clé 'Intro_complete'
      localStorage.setItem('Intro_complete', JSON.stringify(dissertation));
      
      // Log de confirmation
      //console.log("Intro_complete enregistrée dans localStorage :", dissertation);
  } catch (error) {
      console.error("Erreur lors de l'enregistrement dans localStorage :", error);
  }
}

    }, [dissertation])

    useEffect(() => {
// Récupérer les données depuis localStorage sous la clé 'Intro_complete'
const storedData = localStorage.getItem('Intro_complete');

if (storedData) {
    try {
        // Convertir la chaîne JSON en un objet JavaScript
        const retrievedData = JSON.parse(storedData);

        // Vérifier si l'objet récupéré est du bon type
        if (retrievedData && isTypeDissertation(retrievedData, getEmptyDissertation())) {
            setDissertation(retrievedData as Type_Dissertation);
        }
    } catch (error) {
        console.error(`Erreur lors du parsing des données pour 'Intro_complete' depuis localStorage:`, error);
    }
}

    }, [])

    return <>

    {dissertation.sujet ? 

    <Dissertation
    dissertation={dissertation}
    setDissertation={setDissertation}
    introSeule={true}
    />
    :
    <>
          {showNewDissertation && (
        <NouvelleDissertation
        isOpen={showNewDissertation}
        onClose={() => setShowNewDissertation(false)}
        toShow={[true,true, false, false]}
        validate={addNewSujet(setDissertation)}
      />
      )}
    <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}} onClick={() => setShowNewDissertation(true)}>
    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
    <div className="texteAjouterDissert">Ajouter<br />un sujet</div>
  </div>
  </>}
    </>
}

export default IntroComplete