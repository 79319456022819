import { FC, useState, useEffect, useCallback, useContext } from 'react'
import Etapes from './Menu/Etapes'
import TitrePage from './Menu/TitrePage'
import { Etape, Type_Dissertation } from './StructureDissertation'
import { ValuesAndNotes } from './Menu/SetupParties'
import { arraysAreEqual, getGoBack, getGoNext } from '../Funcs/Funcs'
import { CurrentOpenedContext } from '../Context'
import FlecheG from '~assets/fleche_g.svg'
import FlecheD from '~assets/fleche_d.svg'
import Tooltip from '../Tooltip'

interface Props {
    etapes: Etape[]
    dissertation: Type_Dissertation
    currentPage: number[]
    emptyAll?: (() => void) | void
    setCurrentPage: (v: number[]) => void
    valuesAndNotes: ValuesAndNotes[]
}

const MenuEtape: FC<Props> = ({ etapes, currentPage, setCurrentPage, valuesAndNotes, dissertation, emptyAll }) => {

    const [backGo, setBackGo] = useState<number[]>([])
    const [nextGo, setNextGo] = useState<number[]>([])


    
  const context1 = useContext(CurrentOpenedContext)
  if (!context1) throw new Error('CurrentOpenedContext is undefined')
  const [, setCurrentOpened] = context1


    useEffect(() => {
        setBackGo(getGoBack(currentPage, etapes))
        setNextGo(getGoNext(currentPage, etapes))
    }, [currentPage, etapes])


    const getEtapes = useCallback((): Etape[] => {
        let result: Etape[] = []
        //console.log("ON SELECTIONNE LES ETAPES")
        if (currentPage[0] === 1) { // si on regarde le développement, en fait il faut filtrer la bonne partie
            result = etapes.filter(item => item.goTo[0] === 1).filter(item => item.goTo[1] === currentPage[1])
        } else {
            result = etapes.filter(item => item.goTo[0] === currentPage[0])
        }
        //console.log (etapes, result)
        return result
    }, [etapes, currentPage]);

    const getEtapesVides = useCallback((): ValuesAndNotes[] => {
        const nbCurrentEtapes: number = getEtapes().length
        let allCurrentValues: ValuesAndNotes[] = []
        if (currentPage[0] === 1) { // si on regarde le développement, en fait il faut filtrer la bonne partie
            allCurrentValues = valuesAndNotes.filter(item => item.goTo_etape[0] === 1).filter(item => item.goTo_etape[1] === currentPage[1])
        } else {
            allCurrentValues = valuesAndNotes.filter(item => item.goTo_etape[0] === currentPage[0])
        }
        return allCurrentValues.filter((item, index) => index >= nbCurrentEtapes)
        //return valuesAndNotes.filter(item => item.goTo_etape)
        //return etapes.filter((item,index) => index > getEtapes().length)  // on prend toutes les étapes qui suivent les étapes retenues par getEtapes
    }, [etapes, currentPage]);


    const getCurrentEtapeNumber = useCallback((): number => {
        //konsole.log("on lance getCurrentEtape")

        if (currentPage[0] === 1) { // si on regarde le développement, en fait il faut filtrer la bonne partie
            if (currentPage[2] === 1) { // si on inspecte une sous-partie
                return currentPage[3] + 1
            } else if (currentPage[2] === 2) { // si on regarde la conclusion
                return dissertation.developpement[currentPage[1]].sousparties.length + 1
            } // si ce n'est ni une sous-partie, ni une conclusion, c'est l'intro -> 0
            else return 0
        } else {
            return currentPage[1]
        }
    }, [currentPage])

    const getCurrentEtape = useCallback((): Etape => {
        //console.log("on lance getCurrentEtape", dissertation)
        return getEtapes()[getCurrentEtapeNumber()]
    }, [getEtapes, getCurrentEtapeNumber]);


    return (
        <div className="component" style={{ margin: '40px auto 40px auto', padding:'20px',  width: '600px', display: 'flex',minWidth: 0 }}>
      {!arraysAreEqual(backGo as number[], []) && backGo ? (
        <div className="imgFlecheTitre imgFlecheImageG" onClick={() => {setCurrentOpened(0);setCurrentPage(backGo)}}>
          <Tooltip text={"Etape précédente"}>

            <img src={FlecheG} className="imgFlecheImage " />
          </Tooltip>

        </div>
      ) : <div style={{width:'50px', height:'70px'}} />}
            <div style={{width:'460px'}}>
                <Etapes
                    etapes={getEtapes()}
                    etapesVides={getEtapesVides()}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                <TitrePage
                    etape={getCurrentEtape()}
                    message={valuesAndNotes[getCurrentEtapeNumber()] ? valuesAndNotes[getCurrentEtapeNumber()].messageEtape : ''}
                    emptyAll={emptyAll}
                    backGo={backGo}
                    nextGo={nextGo}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            {!arraysAreEqual(nextGo as number[], []) && nextGo ? (
        <div className="imgFlecheTitre imgFlecheImageD" onClick={() => {setCurrentOpened(0);setCurrentPage(nextGo)}}>
          <Tooltip text={"Etape suivante"}>
            <img src={FlecheD} className="imgFlecheImage " />
          </Tooltip>
        </div>
      ) : <div style={{width:'50px', height:'70px'}} />}
        </div>
    )
}

export default MenuEtape