import { FC, useState, useEffect, useContext } from 'react'

import IntroductionPartie from './IntroductionPartie'
import ConclusionPartie from './ConclusionPartie'
import SousPartie from './SousPartie'

//import { Partie } from '~./app/components/Dissertation/StructureDissertation'

import { Etape } from '~./app/components/Dissertation/StructureDissertation'
import { ElementReminder } from '~./app/components/Dissertation/StructureDissertation'

import { getStrValue, getNumValue, setValue } from '~app/components/Funcs/Funcs'
import { CurrentOpenedContext } from '~app/components/Context'

interface Props {
  sujet:string,
  objet:any,
  setObjet: (value:any) => any,
  element?:string

  currentPage: number[]
  setCurrentPage: (value: number[]) => void
  etapes: Etape[]
  partie: number
  setEmptyAll?: (func: () => void) => void
}

const Partie: FC<Props> = ({
  sujet,
  objet,
  setObjet,
  element = "",
  currentPage,
  etapes,
  partie,
  setEmptyAll,
}) => {

  const getStrV = (el: string):string => getStrValue(objet, element + el);
  const getNumV = (el: string):number => getNumValue(objet, element + el);
  const setV = (el: string) => setValue(setObjet, element + el);

  const context = useContext(CurrentOpenedContext)
  if (!context) throw new Error('CurrentOpenedContext is undefined')
  const [currentOpened, setCurrentOpened] = context



  const  [index, setIndex] = useState(0)
  useEffect(() => {
    //console.log (dissertation.developpement)
    currentPage[2] == 1 ? setIndex(currentPage[3] + 1)
    : currentPage[2] == 2 ? setIndex(etapes.length - 1) // si on est sur la conclusion, on prend la dernière étape
    : setIndex(currentPage[2])
  }, [etapes,currentPage])

  const returnReminderIfNotEmpty = (categorieT:string, texteT:string):ElementReminder | undefined  => {
    if (texteT) return {categorie:categorieT,texte:texteT}; else return undefined;
  }

  const getReminderNextPartie = ():ElementReminder | undefined => {
    if (currentPage[1] == 0) // si on est dans la première partie
    {
      return returnReminderIfNotEmpty("Thèse de la partie à suivre",objet.developpement[1].annonce.contenu)
    }
      else if (currentPage[1] == 1) // si on est dans la partie 2
      {
        if (objet.developpement.length == 3)
        return returnReminderIfNotEmpty("Thèse de la partie à suivre",objet.developpement[2].annonce.contenu)
      }
      return undefined
  }
  const getReminderNextSousPartie = ():ElementReminder | undefined  => { // on construit le reminder des conclusions de sous-parties : on vérifie quelle sera la prochaine sous-partie, ou la prochaine partie si on est en SP2
    if (currentPage[3] == 0) {  //si c'est la première SP de la partie, alors on enchaine sur la SP suivante
      if (objet.developpement[partie].sousparties[1].intro)
        return returnReminderIfNotEmpty("Thèse de la sous-partie à suivre",objet.developpement[partie].sousparties[1].intro.contenu)
    }
    else if (currentPage[3] == 1) { // si c'est la deuxième SP de la partie
      return getReminderNextPartie()
    }
    return undefined
  }

  const getReminderPreviousConclu = ():ElementReminder | undefined  => { // on construit le reminder des conclusions de sous-parties : on vérifie quelle sera la prochaine sous-partie, ou la prochaine partie si on est en SP2
    if (currentPage[3] == 1) return {categorie:"Conclusion de la sous-partie 1", texte:objet.developpement[currentPage[1]].sousparties[0].conclusion.contenu}; else return undefined
  
  }

  return (
    <>
    {objet.developpement[currentPage[1]] && <>
      {currentPage[2] == 0 && (
<>

        <IntroductionPartie
          sujet={sujet}
          objet={objet}
          setObjet={setObjet}
          element={"developpement." + [currentPage[1]] + ".intro"}

          setEmptyAll={setEmptyAll}
          partie={currentPage[1]}
          infosReminder={{def_concept1:objet.intro.definitions.def_concept1.contenu,
           def_concept2:objet.intro.definitions.def_concept2.contenu,
      annonce_partie1:objet.developpement[0].annonce.contenu,
      annonce_partie2:objet.developpement[1].annonce.contenu,
      annonce_partie3:objet.developpement[2]?.annonce.contenu,

    }}
        />
        </>
      )}
      {currentPage[2] == 1 && 
      <>
              <SousPartie
            
        sujet={sujet}
        objet={objet}
        setObjet={setObjet}
        element={'developpement.' + currentPage[1] + '.sousparties.' + currentPage[3]}

        these_partie={objet.developpement[currentPage[1]].intro.contenu}
        partie={currentPage[1]}
        souspartie={currentPage[3]}

        setEmptyAll={setEmptyAll}
        nextThese={getReminderNextSousPartie()}
        previousConclu={getReminderPreviousConclu()}

        currentOpened={currentOpened}
        setCurrentOpened={setCurrentOpened}
        />
      </>}
      {currentPage[2] == 2 && (
<>

        <ConclusionPartie
          sujet={sujet}
          objet={objet}
          setObjet={setObjet}
          element={"developpement." + [currentPage[1]] + ".conclusion"}
          
          setEmptyAll={setEmptyAll}
          partie={currentPage[1]}
          nextThese={getReminderNextPartie()}
          infosReminder={{thesePartie:objet.developpement[currentPage[1]].annonce.contenu,
          concluSousPartie1:objet.developpement[currentPage[1]].sousparties[0].conclusion.contenu,
          concluSousPartie2:objet.developpement[currentPage[1]].sousparties[1].conclusion.contenu}}
        />
        </>
      )} </> }
    </>
  )
}

export default Partie
