import { FC, useEffect, useState, useRef } from 'react'
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { PieChart } from 'react-minimal-pie-chart';
import GetText from '../ViewText/GetText';
import ExportRTF from '~app/components/Exportation/ExportRTF';
import ExportClipBoard from '~app/components/Exportation/ExportClipBoard';
import { getExports } from '~app/components/Funcs/Export';
import ExportJSON from '~app/components/Exportation/ExportJSON';
import DeleteDissertation from '~app/components/Exportation/DeleteDissertation';
import { getStats } from '~app/components/Funcs/Funcs';

interface Props {
    isOpen?: boolean
    dissert: Type_Dissertation,
    onClose?: () => void
    delFunc?: () => void
    miniMode?: boolean
}

interface BarGraphProps {
    percentage: number;
}

interface DataType {
    title:string,
    value:number,
    color:string
}

const BarGraph: React.FC<BarGraphProps> = ({ percentage }) => {
    return (
        <div className="bar-container">
            <div className="bar-filled" style={{ width: `${percentage}%` }}></div>
        </div>
    );
}
//prop classname à ajouter ?

function countWords(str: string) {
    // Supprime les espaces de début et de fin pour éviter les mots vides
    str = str.trim();

    // Divise la chaîne en mots en utilisant une expression régulière
    const wordsArray = str.split(/[^\w'-]+/);

    // Filtre les éléments vides (peut se produire si la chaîne commence ou termine par des caractères de séparation)
    const nonEmptyWordsArray = wordsArray.filter(word => word !== "");

    // Compte le nombre d'éléments dans le tableau résultant
    const wordCount = nonEmptyWordsArray.length;

    return wordCount;
}

function removeExpressionsInBrackets(inputString: string) {
    return inputString.replace(/\[[^\]]+\]/g, '');
}


const StatsDissertInner: FC<Props> = ({ isOpen, dissert, onClose, delFunc, miniMode }) => {

    const [pourcentage, setPourcentage] = useState<number>(0)
    const [nb_mots, setNb_mots] = useState(0)

    const [rtfFile, setRtfFile] = useState('')
    const [clipBoardText, setClipBoardText] = useState('')
    const refTexte = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<DataType[]>([])


    //const [forbiddenEtape, setForbiddenEtape] = useState<ForbiddenEtape>()

    useEffect(() => {
        //console.log("avant de lancer tout ça")
        const [pourcentage, notes] = getStats(dissert)
        //console.log("Pourcentage : ", nbOccurences, nbTotal)
        setPourcentage(pourcentage)
        //console.log("NOTES", notes_t)

        setData([
            {
                title: '(Non évalué)',
                value: notes[0],
                color: '#d3d3d3',
            },
            {
                title: 'A refaire',
                value: notes[1],
                color: '#ff2626',
            },
            {
                title: 'A améliorer',
                value: notes[2],
                color: '#ffb327',
            },
            {
                title: 'Très bien',
                value: notes[3],
                color: '#00ce00',
            },
        ]);


        /*if (refTexte.current) {
            getExports(
                refTexte.current.innerHTML,
                dissert.sujet,
                setRtfFile,
                setClipBoardText)
            console.log("CurrentHTML : ", refTexte.current.innerHTML)
        }*/

    }, [dissert])

    useEffect(() => {

        if (refTexte.current) {
            getExports(
                refTexte.current.innerHTML,
                dissert.sujet,
                setRtfFile,
                setClipBoardText)
            setNb_mots(countWords(removeExpressionsInBrackets(refTexte.current?.innerText)))

        }


        //console.log("On commence ici : ", refTexte.current)

    }, [refTexte.current, refTexte.current?.innerHTML, isOpen])

    

    return (
        <div className={miniMode ? 'infoContainerMini' : 'infoContainer'}>
            <div className="leftContainer" style={{ width: `${miniMode ? '200px' : '250px'}` }}>
                <div>
                    <PieChart
                        data={data}
                        style={{ width: `${miniMode ? '150px' : '200px'}`, height: `${miniMode ? '150px' : '200px'}` }}
                        label={({ dataEntry }) => dataEntry.value > 0 ? dataEntry.title : ""}
                        labelStyle={{
                            fontSize: '6px',
                            fontFamily: 'Inter var,"system-ui"',
                        }}
                    />

                </div>
                <div>
                    Evaluations
                </div>
            </div>
            <div className="rightContainer">
                {miniMode &&
                    <div className="messageTop" style={{ fontWeight: 700 }}>
                        {dissert.sujet}
                    </div>}
                <div className="messageTop">
                    {dissert.developpement.length == 2 ? "Deux " : "Trois "} parties, {nb_mots} mots
                </div>
                <div className="messageTop"><div className="elementTop">
                    <BarGraph percentage={pourcentage} /></div><div className="elementBottom" style={{ top: `${miniMode ? '-5px' : '-15px'}` }}>Progression : {pourcentage}%</div>
                    <div className="hidden" ref={refTexte}>
                        <GetText partie={[3]} dissertation={dissert} setTitle={() => { }} afficherTexteComposant={() => { }} cacherTexteComposant={() => { }} goThere={() => { }} />
                    </div>
                </div>
                {!miniMode &&
                    <div className="messageBottom">
                        <ExportClipBoard
                            text={clipBoardText}
                            big={true}
                            afficherTexteComposant={() => { }}
                            cacherTexteComposant={() => { }}
                            tooltipdirection={"up"}
                        />
                        <ExportRTF
                            content={rtfFile}
                            sujet={dissert.sujet}
                            big={true}
                            afficherTexteComposant={() => { }}
                            cacherTexteComposant={() => { }}
                            tooltipdirection={"up"}
                        />

                        <ExportJSON
                            content={JSON.stringify(dissert)}
                            sujet={dissert.sujet}
                            big={true}
                            tooltipdirection={"up"}
                        />
                        {delFunc &&
                            <DeleteDissertation
                                big={true}
                                tooltipdirection={"up"}
                                delFunc={() => { delFunc(); if (onClose) onClose() }}
                            />}
                    </div>}
            </div>
        </div>

    )
}
export default StatsDissertInner
