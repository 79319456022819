import { FC, useCallback, useEffect, useMemo } from "react";
import { Facultatif as Tfacultatif, Necessaire as Tnecessaire } from "./Composants";
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation";
import { Etape_Composant } from "~types";

interface Props {
    setTitle: (v: string) => void;
    dissertation: Type_Dissertation;
    afficherTexteComposant: (texte: string, couleur?: string) => void;
    cacherTexteComposant: () => void;
    goThere: (partie: Etape_Composant) => void;
}

export const ViewText_Intro: FC<Props> = ({ setTitle, dissertation, afficherTexteComposant, cacherTexteComposant, goThere }) => {

    useEffect(() => {
        let titre_temp = 'Introduction complète';
        setTitle(titre_temp);
    }, []);

    const Facultatif: FC<{ texte: string; classe: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, infoComposant, toGo }) => {
        return <Tfacultatif texte={texte} classe={classe} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    const Necessaire: FC<{ texte: string; classe: string; erreur: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, erreur, infoComposant, toGo }) => {
        return <Tnecessaire texte={texte} classe={classe} erreur={erreur} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    const situationComposant = 'Introduction > ';

    return (
        <>
            <p>
                <Facultatif
                    texte={dissertation.intro.accroche.contenu}
                    classe="accroche_intro"
                    infoComposant="Accroche"
                    toGo={{ etape: [0, 0], composant: 0 }}
                />{' '}
                {useMemo(() => <Necessaire
                    classe="definition"
                    texte={dissertation.intro.definitions.def_concept1.contenu}
                    erreur="Définition du premier concept manquante"
                    infoComposant="Définition du premier concept"
                    toGo={{ etape: [0, 2], composant: 0 }}
                />, [])}
                <Necessaire
                    texte={dissertation.sujet}
                    classe="sujet"
                    erreur="Sujet manquant"
                    infoComposant="Sujet" />
                <Necessaire
                    classe="definition"
                    texte={dissertation.intro.definitions.def_concept2.contenu}
                    erreur="Définition du second concept manquante"
                    infoComposant="Définition du second concept"
                    toGo={{ etape: [0, 2], composant: 1 }}
                />
                <Necessaire
                    classe="problematique1"
                    texte={dissertation.intro.problematique.problematique1.contenu}
                    erreur="Première partie de la problématique manquante"
                    infoComposant="Première partie de la problématique"
                    toGo={{ etape: [0, 3], composant: 0 }}
                />
                <Necessaire
                    classe="problematique2"
                    texte={dissertation.intro.problematique.problematique2.contenu}
                    erreur="Seconde partie de la problématique manquante"
                    infoComposant="Deuxième partie de la problématique"
                    toGo={{ etape: [0, 3], composant: 1 }}
                />
                <Facultatif
                    classe="problematique_synthese"
                    texte={dissertation.intro.problematique.synthese.contenu}
                    infoComposant="Synthèse"
                    toGo={{ etape: [0, 3], composant: 2 }}
                />
            </p>
            <p>
                <Necessaire
                    texte={dissertation.developpement[0].annonce.contenu}
                    classe="plan1"
                    erreur="Première partie du plan manquante"
                    infoComposant="Première partie du plan"
                    toGo={{ etape: [0, 4], composant: 0 }}
                />
                <Necessaire
                    texte={dissertation.developpement[1].annonce.contenu}
                    classe="plan2"
                    erreur="Seconde partie du plan manquante"
                    infoComposant="Deuxième partie du plan"
                    toGo={{ etape: [0, 4], composant: 1 }}
                />
                {(dissertation.developpement.length === 3) ? (
                    <Necessaire
                        texte={dissertation.developpement[2].annonce.contenu}
                        classe="plan3"
                        erreur="Troisième partie du plan manquante"
                        infoComposant="Troisième partie du plan"
                        toGo={{ etape: [0, 4], composant: 2 }}
                    />
                ) : (
                    <></>
                )}
            </p>
        </>
    );
};

export default ViewText_Intro;
