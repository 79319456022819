import { useState, FC, ReactNode } from "react"
import info from '~assets/menu_info.svg'
import ok from '~assets/ok_check.svg'
import erreur from '~assets/red_close.svg'

import InfoDialog from "../Info/Dialog/InfoDialog"

interface Option {
    text: string;
    correct: boolean;
}

interface Question {
    text: string;
    options: Option[];
}

interface TextEntry {
    auteur: string;  // Ajout des nouveaux champs
    ouvrage: string; // Ajout des nouveaux champs
    questions: Question[];
}


interface Props {
    etat: "info" | "ok" | "erreur",
    setEtat: (v: "info" | "ok" | "erreur") => void
    textetrous: TextEntry[];
    selectedTextIndex: number | null;
    setSelectedTextIndex: (v: number | null) => void
    selectedOptions: number[]
    setSelectedOptions: (v: number[]) => void
    backgroundColors: string[]
    setBackgroundColors: (v: string[]) => void
    reponsesDonnees: boolean,
    setReponsesDonnees: (v: boolean) => void

}

const MenuChoisirTexte: FC<Props> = ({ etat, setEtat, textetrous, selectedTextIndex, setSelectedTextIndex, selectedOptions, setSelectedOptions, backgroundColors, setBackgroundColors, reponsesDonnees, setReponsesDonnees }) => {

    const [isOpenInfo, setOpenInfo] = useState(false) // attention, est pour la boite de dialogue, non pour le composant



    const [imgEtat, setImgEtat] = useState(info)
    const [messageCentral, setMessageCentral] = useState("")

    const handleTextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const value = event.target.value === "null" ? null : parseInt(event.target.value, 10); // Convertissez la chaîne "null" en null
        setSelectedTextIndex(value);
        setMessageCentral("")
        setBackgroundColors([]);
        setReponsesDonnees(false);
        setImgEtat(info)
        if (value !== null) { // si un texte est vraiment chargé
            setSelectedOptions(Array(textetrous[value].questions.length).fill(-1));

        }
    };



    const handleCorrection = (showResults = false) => {
        let correctCount = 0;
    const newBackgroundColors: string[] = [];
    const newSelectedOptions: number[] = [...selectedOptions]; // Faites une copie du tableau d'options sélectionnées existant

    if (showResults) {
        setReponsesDonnees(true);

        textetrous[selectedTextIndex!].questions.forEach((question, qIndex) => {
            if (question.options) {
                const correctOptionIndex = question.options.findIndex((opt) => opt.correct);

                if (
                    selectedOptions[qIndex] !== -1 &&
                    question.options[selectedOptions[qIndex]].correct
                ) {
                    correctCount++;
                    newBackgroundColors.push("#22eb00"); // Vert pour correct
                } else {
                    newBackgroundColors.push("#ff9090"); // Rouge pour incorrect
                }

                // Mettez à jour l'option correcte pour cette question
                newSelectedOptions[qIndex] = correctOptionIndex;
            } else {
                newBackgroundColors.push("white"); // Blanc pour les questions sans options
            }
        });

        setBackgroundColors(newBackgroundColors);
        setSelectedOptions(newSelectedOptions);  // Mettez à jour l'état avec les nouvelles options sélectionnées
    }
    correctCount = 0;  // Réinitialisez le correctCount avant la deuxième vérification
    const validQuestions = textetrous[selectedTextIndex!].questions.filter(
        (q) => q.options && q.options.length > 0
    );

        validQuestions.forEach((question, qIndex) => {
            const actualIndex = textetrous[selectedTextIndex!].questions.indexOf(question);
            if (
                selectedOptions[actualIndex] !== -1 &&
                question.options![selectedOptions[actualIndex]].correct
            ) {
                correctCount++;
            }
        });
        if (correctCount == validQuestions.length) {
            setImgEtat(ok)
            setMessageCentral(`Bravo ! Vous avez ${correctCount} bonnes réponses sur ${validQuestions.length}`)
            setReponsesDonnees(true);

        } else {
            setImgEtat(erreur)
            setMessageCentral(`Vous avez ${correctCount} bonnes réponses sur ${validQuestions.length}`)
        }
    };


    return (
        <div className="component" style={{ width: '450px', display: 'flex', marginTop: '30px', marginBottom: '-5px', alignItems: 'center', lineHeight: 1 }}>
            <div style={{ width: '150px' }}>
            <div className="ajouterTxtMenu">
                    <div className="imgMenuTexte" style={{ cursor: 'pointer',backgroundImage: `url(${imgEtat})` }} onClick={() => setOpenInfo(true)}></div>
                </div>


            </div>
            <div style={{ flex: 1 }}>
                <div style={{ marginBottom: '10px' }}>
                    <select onChange={handleTextChange} style={{ margin: 'auto' }}>
                        <option value="null">Sélectionnez un texte</option>
                        {textetrous.map((texte, index) => (
                            <option key={index} value={index}>
                                {texte.auteur} - {texte.ouvrage}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedTextIndex != null &&
                <>
                <div style={{ textAlign: 'center' }}>{messageCentral}</div>
                <div style={{ display: 'flex' }}>
                    <div className="bouton_dialog"                         style={{
                            margin: 'auto',
                            marginTop: '10px',
                            marginRight: '10px',
                            fontSize:'100%',
                            filter: !reponsesDonnees ? 'none' : 'opacity(0.2)', // Appliquer un filtre si le bouton est désactivé
                            pointerEvents: !reponsesDonnees ? 'auto' : 'none', // Désactiver les événements du pointeur si le bouton est désactivé
                        }} onClick={() => handleCorrection(true)}>Voir réponses</div>
                    <div
                        className="bouton_dialog"
                        style={{
                            margin: 'auto',
                            marginTop: '10px',
                            marginLeft: '10px',
                            fontSize:'100%',
                            filter: !reponsesDonnees ? 'none' : 'opacity(0.2)', // Appliquer un filtre si le bouton est désactivé
                            pointerEvents: !reponsesDonnees ? 'auto' : 'none', // Désactiver les événements du pointeur si le bouton est désactivé
                        }}
                        onClick={() => {
                            if (!reponsesDonnees) {
                                handleCorrection();
                            }
                        }}
                    >
                        Évaluer
                    </div>
                
                </div>
                </>}
            </div>

            {isOpenInfo && (
          <InfoDialog isOpen={isOpenInfo} onClose={() => setOpenInfo(false)} text={"Sélectionnez un texte, et choisissez l'expression qui convient grâce au menu déroulant.<br />Cliquez sur \"<b>Évaluer</b>\" pour connaître le nombre de fautes.<br />Cliquez sur \"<b>Voir réponses</b>\" pour avoir la correction."} title={"Texte à trous"} />
        )}


        </div >
    )
}

export default MenuChoisirTexte