import { FC, ReactNode } from 'react'
import { Etape_Composant } from '~types';

type AfficherTexteComposantFunction = (
  texte: string,
  couleur?: string
) => void;

export const Necessaire: FC<{ texte: string; erreur: string; classe?:string; infoComposant: string; toGo?: Etape_Composant, afficherTexteComposant: AfficherTexteComposantFunction, situationComposant: string, cacherTexteComposant: () => void, goThere: (partie: Etape_Composant) => void }> = ({
  texte,
  erreur,
  infoComposant,
  classe,
  toGo,
  afficherTexteComposant,
  cacherTexteComposant,
  situationComposant,
  goThere
}) => {
  if (texte) {
    return (
      <>
        <span
          className={"textPart " + classe}
          onMouseOver={(event) => {
            event.stopPropagation()
            afficherTexteComposant(infoComposant != 'Sujet' ? situationComposant + infoComposant : '[Sujet]',
              '#000000',
            )
          }}
          onMouseOut={(event) => {event.stopPropagation();cacherTexteComposant()}}
          onClick={toGo ? () => {goThere(toGo);} : () => {}}
        >
          {texte.trim()}
        </span>{' '}
      </>
    )
  } else {
    return (
      <>
        <span
          className={'textPart ViewTextError1'}
          onMouseOver={() => {
            afficherTexteComposant(infoComposant != 'Sujet' ? situationComposant + infoComposant : '',
              '#FF0000',
            )
          }}
          onMouseOut={() => cacherTexteComposant()}
          onClick={toGo ? () => {goThere(toGo);} : () => {}}
        >
          [{erreur}]
        </span>{' '}
      </>
    )
  }
}

export const Facultatif: FC<{ texte: string; infoComposant: string; classe?:string; toGo?: Etape_Composant, afficherTexteComposant: (texte: string, couleur?: string) => void, situationComposant: string, cacherTexteComposant: () => void, goThere: (partie: Etape_Composant) => void }> = ({
  texte,
  infoComposant,
  classe,
  toGo,
  afficherTexteComposant,
  cacherTexteComposant,
  situationComposant,
  goThere
}) => {

  if (texte) {
    return (
      <>
        <span
          className={"textPart " + classe}
          onMouseOver={() => {
            afficherTexteComposant(situationComposant + infoComposant, '#000000',)
          }}
          onMouseOut={() => cacherTexteComposant()}
          onClick={toGo ? () => {goThere(toGo);} : () => {}}

        >
          {texte.trim()}
        </span>{' '}
      </>
    )
  } else {
    return null
  }
}