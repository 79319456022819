import { Outlet } from '@tanstack/react-router'
import { FC, useState, useEffect } from 'react'
import Dissertation from './Dissertation/Dissertation'
import { SearchPreferencesContext, SeeColorsContext } from '~./app/components/Context'
import Error from './Info/Dialog/DialogError'
import EcranChargement from './Dissertation/EcranChargement/EcranChargement'
import { getEmptyDissertation } from './Dissertation/DissertationVide'
import { DissertationAvecId, Type_Dissertation } from './Dissertation/StructureDissertation'
import Menu from './Menu/Menu'
import { InfosBack } from '~types'
import { DialogContext } from '~./app/components/Context'
import { ForbiddenEtape } from '~types';
import { CurrentErrorComposantContext } from '~app/components/Context';
import { RemoveDissertationContext } from '~./app/components/Context'
import { ReminderContext } from '~./app/components/Context'
import ChargementDonneesDissert from './Dissertation/EcranChargement/ChargementDonneesDissert'
import EntrainementChargement from './Entrainement/EntrainementChargement'

import { useParams } from 'react-router-dom'
import IntroComplete from './Entrainement/ElementDeDissertation/IntroComplète'
import T_Accroche from './Entrainement/T_Accroche'
import { UpdateStructureContext } from '~./app/components/Context'
import T_Definir from './Entrainement/T_Definir'
import T_Problematiser from './Entrainement/T_Problematiser'
import T_Plan from './Entrainement/T_Plan'
import T_SousPartie from './Entrainement/T_SousPartie'
import T_Synthese from './Entrainement/T_Synthese'
import T_Ouverture from './Entrainement/T_Ouverture'
import TextesTrous from './Entrainement/Redaction/TextesTrous'
import TextesReconstituer from './Entrainement/Redaction/TextesReconstituer'
import { useNavigate } from 'react-router-dom'
import RedigerThese from './Entrainement/Redaction/RedigerThese'
import MenuTutoriels from './Tutoriels/MenuTutoriels'
import ShowTutoriel from './Tutoriels/ShowTutoriel'
import { tutoriels } from '~app/consts'
import Apropos from './Apropos/Apropos'

interface Props {
  menu: string
}

const Layout: FC<Props> = ({ menu }) => {
  const [thinking, setThinking] = useState(false);
  const [generating, setGenerating] = useState(false);
  const { id } = useParams()
  const [reminder, setReminder] = useState<boolean>(false)


  //const [affichage, setAffichage] = useState(id)
  const [idDissert, setIdDissert] = useState<string>("")

  const [title, setTitle] = useState("MethodoPhilo")

  const [infosBack, setInfosBack] = useState<InfosBack>()

  const [dialogVisible, setDialogVisible] = useState(false);


  const [forbiddenEtape, setForbiddenEtape] = useState<ForbiddenEtape>({
    nom: '',
    currentProblem: '',
    etape: [],
    composant: 0,
  })
  const [composantToGo, setComposantToGo] = useState<number | undefined>(undefined)

  const [seeColors, setSeeColors] = useState(false)


  const [error, setError] = useState(false) // est-ce qu'on affiche l'erreur ou non ?
  const [textError, setTextError] = useState<React.ReactElement>(<></>);
  const [dissertations, setDissertations] = useState<DissertationAvecId[]>([]);
  const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())


  //
  const [concept1, setConcept1] = useState("")
  const [concept2, setConcept2] = useState("")
  const [typeSujet, setTypeSujet] = useState("Tous les sujets")
  const [uncheckedConcepts, setUncheckedConcepts] = useState<{ [key: string]: boolean }>({});
  const [seulementNotions, setSeulementNotions] = useState(false);


  //


  const navigate = useNavigate()

  const removeDissertation = (id?: string) => {
    // Si aucun ID n'est passé en paramètre, utiliser l'ID de dissertation actuel (idDissert)
    if (!id) id = idDissert;

    // Filtrer les dissertations pour exclure celle qui a l'ID spécifié
    const updatedDissertations = dissertations.filter(dissertation => dissertation.id !== id);

    // Mettre à jour localStorage avec les nouvelles dissertations
    localStorage.setItem('Dissertation', JSON.stringify(updatedDissertations));

    // Mettre à jour l'état local avec la liste des dissertations filtrées
    setDissertations(updatedDissertations);

    console.log("Dissertation supprimée !");
}




  useEffect(() => {

    switch (menu) {
      case "dissertation":
        if (id == null) {
          setTitle("Dissertation complète")
          setInfosBack(undefined)
          break;
        } else {
          setIdDissert(id)
          setInfosBack({ URL: "/dissertation/", message: "Revenir au choix de la dissertation" })
          break;
        }
      case "entrainement":
        let num = 0;
        switch (id) {
          case undefined:
          case "0":
          case "1":
            setTitle("Entraînement")
            setInfosBack(undefined)
            break;
          case "intro_complete":
            setTitle("Introduction complète")
            break;
          case "accroche":
            setTitle("Accroche")
            break;

          case "problematiser":
            setTitle("Problématiser")
            break;
          case "plan":
            setTitle("Construire un plan")
            break;
          case "developpement_libre":
            setTitle("Sous-partie avec développement libre")
            break;
          case "reference":
            setTitle("Sous-partie avec référence")
            break;
          case "exemple":
            setTitle("Sous-partie avec exemple")
            break;
          case "distinction_conceptuelle":
            setTitle("Sous-partie avec distinction conceptuelle")
            break;
          case "synthese":
            setTitle("Synthèse")
            break;
          case "ouverture":
            setTitle("Ouverture")
            break;
          case "trous":
            setTitle("Textes à trous")
            num = 1
            break;
          case "definir":
            setTitle("Définir")
            num = 1
            break;
          case "reconstituer":
            setTitle("Textes à reconstituer")
            num = 1
            break;
          case "connecteurs":
            setTitle("Identifier les connecteurs logiques")
            num = 1
            break;
          case "these":
            setTitle("Rédiger une thèse")
            num = 1
            break;
        }
        if (!id || id == "0" || id == "1") {
          setInfosBack(undefined)
        } else setInfosBack({ URL: "/entrainement/" + num, message: "Revenir au choix de l'exercice" })

        break;
      case "tutoriels":
        if (!id) {
          setTitle("Tutoriels")
          setInfosBack(undefined)
        } else {
          const index = tutoriels.findIndex(element => element.lien === id);
          if (index == -1) { // on a essayé de chercher un tutoriel qui n'existe pas
            navigate("/tutoriels/")
          } else {
            setTitle(tutoriels[index].titre)
            setInfosBack({ URL: "/tutoriels/", message: "Revenir au choix du tutoriel" })
          }
        }

        break;
      case "apropos":
        setTitle("À propos")
        setInfosBack(undefined)
        break;
    }
  }, [menu, id])

  useEffect(() => {

    //console.log (idDissert)
    if (idDissert) {
      //console.log(idDissert)
      const index = dissertations.findIndex(dissertation => dissertation.id.trim() === idDissert.trim());

      //console.log("On est dans le useEffect [dissertation]")
      if (index === -1) {
        //console.error(" introuvable dans les dissertations !");
        navigate("/dissertation/")
        return;
      }
      //} else console.log("On a identifié le code", idDissert)

      // Copiez la liste actuelle de dissertations et modifiez l'élément trouvé
      const updatedDissertations = [...dissertations];
      updatedDissertations[index].contenu = dissertation;

      //console.log("Nouvel updatedDissertations : ", updatedDissertations)

      // Mettez à jour le stockage local et l'état local
// Mettre à jour localStorage avec les dissertations mises à jour
localStorage.setItem('Dissertation', JSON.stringify(updatedDissertations));

// Mettre à jour l'état local avec les dissertations mises à jour
setDissertations(updatedDissertations);

// Console log pour confirmer la mise à jour
//console.log(`Dissertation avec ID ${currentDissert.id} mise à jour !`, updatedDissertations);
//console.log("on a appelé setDissertationx");

    }
  }, [dissertation])

  return (
      <DialogContext.Provider value={[dialogVisible, setDialogVisible]}>

        {error && (
          <Error isOpen={error} onClose={() => setError(false)} text={textError} title="Erreur !" />
        )}
        <CurrentErrorComposantContext.Provider value={[forbiddenEtape, setForbiddenEtape, composantToGo, setComposantToGo]}>
          <RemoveDissertationContext.Provider value={[removeDissertation]}>
            <ReminderContext.Provider value={[reminder, setReminder]}>
              <SearchPreferencesContext.Provider value={[concept1,setConcept1,concept2,setConcept2,typeSujet,setTypeSujet,uncheckedConcepts,setUncheckedConcepts,seulementNotions,setSeulementNotions]} >
              <SeeColorsContext.Provider value={[seeColors, setSeeColors]} >



              <Menu
                title={title}
                menu={menu}
                setTitle={setTitle}
                infosBack={infosBack}
                setInfosBack={setInfosBack}
              />

              {menu == "dissertation" ? <>
                <ChargementDonneesDissert
                  setDissertations={setDissertations}
                  idDissertation={id}
                  setDissertation={setDissertation}
                  setTitle={setTitle}
                />
                {id == null ?
                  <EcranChargement
                    dissertations={dissertations}
                    setDissertations={setDissertations}
                  />
                  :
                  <Dissertation
                    dissertation={dissertation}
                    setDissertation={setDissertation}
                  />
                }
              </>
                : menu == "entrainement" ?
                  <>
                    <UpdateStructureContext.Provider value={[false, () => { }]}>

                      {id == null ?

                        <EntrainementChargement menu={0} />
                        : id == "0" ?
                          <EntrainementChargement menu={0} />
                          : id == "1" ?
                            <EntrainementChargement menu={1} />
                            : id == "intro_complete" ?
                              <IntroComplete />
                              : id == "accroche" ?
                                <T_Accroche />
                                : id == "definir" ?
                                  <T_Definir />
                                  : id == "problematiser" ?
                                    <T_Problematiser />
                                    : id == "plan" ?
                                      <T_Plan />
                                      : id == "developpement_libre" ?
                                        <T_SousPartie typeSousPartie='developpement_libre' />
                                        : id == "reference" ?
                                          <T_SousPartie typeSousPartie='reference' />
                                          : id == "exemple" ?
                                            <T_SousPartie typeSousPartie='exemple' />
                                            : id == "distinction_conceptuelle" ?
                                              <T_SousPartie typeSousPartie='distinction_conceptuelle' />
                                              : id == "synthese" ?
                                                <T_Synthese />
                                                : id == "ouverture" ?
                                                  <T_Ouverture />
                                                  : id == "trous" ?
                                                    <TextesTrous mode="trous" />
                                                    : id == "reconstituer" ?
                                                      <TextesReconstituer />
                                                      : id == "connecteurs" ?
                                                        <TextesTrous mode="connecteurs" />
                                                        : id == "these" ?
                                                          <RedigerThese />
                                                          : <></>
                      }
                    </UpdateStructureContext.Provider>
                  </>
                  : menu == "tutoriels" ?
                    <>{id == null ?
                      <MenuTutoriels />
                      : <ShowTutoriel tutoriel={id} />}
                    </>
                    : menu == "apropos" ?
                      <Apropos />
                      : <></>}
                      </SeeColorsContext.Provider>
            </SearchPreferencesContext.Provider>
          </ReminderContext.Provider>
        </RemoveDissertationContext.Provider>
      </CurrentErrorComposantContext.Provider>
    </DialogContext.Provider>

  )
}

export default Layout
