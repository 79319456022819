import { useState, useEffect } from "react"
import { ElementReminder, Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"
import { getEmptyDissertation } from "~app/components/Dissertation/DissertationVide"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"
import MenuComposant from "./MenuComposant"
import Synthese from "../Dissertation/Conclusion/Synthese"

const T_Synthese = () => {
    const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    const [hidden, setHidden] = useState<Record<number, boolean>>({});

    const toggleVisibility = (index: number) => {
      setHidden((prevVisibility) => ({
        ...prevVisibility,
        [index]: !prevVisibility[index],
      }));
    };

    
    useEffect(() => {
// Vérifier si la dissertation est définie et contient les informations nécessaires
if (dissertation && dissertation.sujet) {
  // Convertir et stocker la dissertation dans localStorage sous la clé 'Conclusion'
  localStorage.setItem('Conclusion', JSON.stringify(dissertation));
  
  // Optional: Ajouter un log pour vérifier l'enregistrement
  //console.log("Conclusion enregistrée dans localStorage:", dissertation);
}

    }, [dissertation])


    useEffect(() => {
// Récupérer les données depuis localStorage sous la clé 'Conclusion'
const storedData = localStorage.getItem('Conclusion');

if (storedData) {
    try {
        // Convertir la chaîne JSON en un objet JavaScript
        const retrievedData = JSON.parse(storedData);

        // Vérifier si l'objet récupéré est du bon type
        if (retrievedData && isTypeDissertation(retrievedData, getEmptyDissertation())) {
            setDissertation(retrievedData as Type_Dissertation);
        }
    } catch (error) {
        console.error(`Erreur lors du parsing des données pour 'Conclusion' depuis localStorage:`, error);
    }
}

    }, [])

    const getConclusions = ():ElementReminder[] => {
        const firstConclusions = [
          {categorie:"Conclusion de la partie 1", texte:dissertation.developpement[0].conclusion.contenu},
          {categorie:"Conclusion de la partie 2", texte:dissertation.developpement[1].conclusion.contenu}
        ]
        if (!(dissertation.developpement.length == 3)) return firstConclusions; else return firstConclusions.concat({categorie:"Conclusion de la partie 3", texte:dissertation.developpement[2].conclusion.contenu})
      }

    return <>

    

    {dissertation.sujet ? 
<>
<MenuComposant
        sujet={dissertation.sujet}
        setObjet={setDissertation}
        toShow={[false, false, true, false]}
        onlyCompleteDissertations={true}
        toDo={'supprimerSynthese'}
    />

<div className="component" style={{ marginTop: '50px', textAlign: 'justify', padding: '10px' }}>
      <div style={{ marginBottom: '10px' }}>Vous devez synthétiser les conclusions auxquelles vous avez abouti au cours de votre développement (vous pouvez cliquer sur les noms de parties pour ouvrir ou fermer les informations).</div>
      <div style={{ fontSize: '13px' }}>
        {dissertation.developpement.map((partie, index) => (
          <div key={index}>
            <span
              className="petitLien"
              onClick={() => toggleVisibility(index)}
            >
              Partie {index + 1} :
            </span>
            {!hidden[index] && <span> {partie.conclusion.contenu}</span>}
          </div>
        ))}
      </div>
    </div>

    <div className="composant" style={{marginTop:'50px'}}>
    <Synthese
          sujet={dissertation.sujet}
          objet={dissertation}
          setObjet={setDissertation}
          element={"conclusion.synthese"}
          infosReminder={getConclusions()}
          modeComposant={true}
        />
    </div>
    </>
    :
    <>
          {showNewDissertation && (
        <NouvelleDissertation
        isOpen={showNewDissertation}
        onClose={() => setShowNewDissertation(false)}
        toShow={[false,false, true, false]}
        validate={addNewSujet(setDissertation, "supprimerSynthese")}
        onlyCompleteDissertations={true}

      />
      )}
    <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}}  onClick={() => setShowNewDissertation(true)}>
    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
    <div className="texteAjouterDissert">Ajouter<br />un sujet</div>
  </div>
  </>}
    </>
}

export default T_Synthese