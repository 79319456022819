import { FC, useState, useEffect, useContext, useCallback } from 'react'
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'

import {
  ValuesAndNotes,
} from '~app/components/Dissertation/Menu/SetupParties'

import Introduction from './Introduction/Introduction'
import Conclusion from './Conclusion/Conclusion'

import Partie from './Partie/Partie'

import { Etape } from '~./app/components/Dissertation/StructureDissertation'
import Etapes from '~app/components/Dissertation/Menu/Etapes'
import TitrePage from '~app/components/Dissertation/Menu/TitrePage'
import MenuEtape from './MenuEtape'


interface Props {
  dissertation: Type_Dissertation
  setDissertation: (value: any) => any
  currentPage: number[]
  setCurrentPage: (value: number[]) => void
  etapes: Etape[]
  valuesAndNotes: ValuesAndNotes[]
}

const Load_GrandePartie: FC<Props> = ({
  dissertation,
  setDissertation,
  currentPage,
  setCurrentPage,
  etapes,
  valuesAndNotes
}) => {

  const [emptyAll, setEmptyAll] = useState(() => { })

  return (
    <>
    <MenuEtape
      etapes={etapes}
      dissertation={dissertation}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      valuesAndNotes={valuesAndNotes}
      emptyAll={emptyAll}
    />

      {currentPage[0] == 0 && (
        <Introduction
          objet={dissertation}
          setObjet={setDissertation}
          currentPage={currentPage}
          setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[0] == 1 && (
        <Partie
          sujet={dissertation.sujet}
          objet={dissertation}
          setObjet={setDissertation}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          partie={currentPage[1]}
          etapes={etapes}
          setEmptyAll={setEmptyAll}
        />
      )}
      {currentPage[0] == 2 && (
        <Conclusion
          sujet={dissertation.sujet}
          objet={dissertation}
          setObjet={setDissertation}
          element={".conclusion"}
          currentPage={currentPage}
          etapes={etapes}
          setEmptyAll={setEmptyAll}
        />
      )}
    </>
  )
}

export default Load_GrandePartie
