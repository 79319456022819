import Titre_container from '../../Titre_container'
import { FC, PropsWithChildren } from 'react'
import Titre_container_noContext from '~app/components/Titre_container_noContext'
import { Etape_Composant, Lien } from '~types'



interface Props {
    titre:string,
    info:string,
    title_info:string,
    onEmpty:  () => void,
    note:number
    etape_composant:Etape_Composant //destiné à comparer avec l'étape interdite, pour le scroll d'erreur
    showReminder?:boolean
    modeComposant?:boolean
    liens?:Lien[]
  }

const SingleComponent: FC<PropsWithChildren<Props>> = ({ titre, info, title_info, onEmpty, note, children, etape_composant, showReminder, modeComposant, liens }) => {
    
    return (
<>
    <Titre_container titre={titre} singleton={true} info={info} title_info={title_info} onEmpty={onEmpty} note={note} etape_composant={etape_composant} showReminder={showReminder}  modeComposant={modeComposant} liens={liens}>
        {children}
    </Titre_container>
    
    </>
    )
    }
export default SingleComponent