import React, { useState, useEffect, useRef, ReactNode, useContext } from 'react';
import './Tooltip.css';
import { DialogContext } from '../Context';

interface TooltipProps {
  text: string;
  children: ReactNode;
  direction?: 'up' | 'down';
  color?: string;
  textColor?:string;
  time?:number
  fontweight?:number
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, direction = 'up', color = 'rgba(0, 0, 0, 0.8)', textColor= '#ccc', time = 500, fontweight=500 }) => {

  const context = useContext(DialogContext)
  if (!context) throw new Error('dialogContext is undefined')
  const [dialogVisible, ,,] = context

  const [isOpen, setIsOpen] = useState(false)

  const [dialogWasOpened, setDialogWasOpened] = useState(false)

  const timeoutRef = useRef<number>();

  const handleMouseEnter = () => {
    setDialogWasOpened(dialogVisible)
    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(true)
    }, time);
  };

  useEffect(() => {
    setIsOpen(false)
  }, [dialogVisible])

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setIsOpen(false)
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {isOpen && (dialogWasOpened == dialogVisible) && (
        <div className={`tooltip ${direction}`} style={{ backgroundColor: color, color:textColor, fontWeight:fontweight }}>
          <div 
            className="tooltip-arrow" 
            style={{ borderColor: direction === 'up' ? `${color} transparent transparent transparent` : `transparent transparent ${color} transparent` }}
          />
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
