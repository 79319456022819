import React, { useState, useEffect } from "react";
import textesThesesJSON from '~app/components/Entrainement/Redaction/textesTheses.json'
import info from '~assets/menu_info.svg'
import InfoDialog from "~app/components/Info/Dialog/InfoDialog";
import { Contenu } from '~app/components/Dissertation/StructureDissertation'
import { getEmptyContenu } from '~app/components/Dissertation/DissertationVide'
import RedigerThese_Cpnt from "./RedigerThese_Cpnt";
import { isTypeDissertation } from "~app/components/Funcs/Funcs";

interface TextEntry {
    auteur: string;  // Ajout des nouveaux champs
    ouvrage: string; // Ajout des nouveaux champs
    texte: string;
}

interface TheseType {
    contenu : Contenu
}

interface Params {
    selectedTextIndex:number,
    these:TheseType
}




const RedigerThese = () => {
    const [isOpenInfo, setOpenInfo] = useState(false) // attention, est pour la boite de dialogue, non pour le composant

    const [selectedTextIndex, setSelectedTextIndex] = useState<number | null>(null);

    const initThese = {contenu:getEmptyContenu()} as TheseType
    const [these, setThese] = useState<TheseType>(initThese)


    const [selectedTextSelect, setSelectedTextSelect] = useState("")



    // 1. Utilisation des données importées du fichier JSON
    let textetrous = textesThesesJSON as TextEntry[]


    useEffect(() => {
        if (selectedTextIndex !== null) {
            const toSave = { selectedTextIndex: selectedTextIndex, these: these };
// Vérifier si 'toSave' est défini
if (toSave) {
    try {
        // Convertir et stocker 'toSave' dans localStorage sous la clé 'These'
        localStorage.setItem('These', JSON.stringify(toSave));
        
        // Log de confirmation
        console.log("'These' enregistré dans localStorage :", toSave);
    } catch (error) {
        console.error("Erreur lors de l'enregistrement dans localStorage :", error);
    }
}

        }
    }, [selectedTextIndex, these]);
    
    useEffect(() => {
        // Récupérer les données depuis localStorage sous la clé 'These'
const storedData = localStorage.getItem('These');

if (storedData) {
    try {
        // Parser la chaîne JSON en un objet JavaScript
        const data = JSON.parse(storedData);

        // Vérifier si les données et le champ 'These' sont définis
        if (data && data.These) {
            // Utiliser les données récupérées
            setThese(data.These as TheseType);
            setSelectedTextIndex(data.selectedTextIndex as number);
            setSelectedTextSelect(getAuteurTitre(textetrous[data.selectedTextIndex]));
        } else {
            console.error("Pas de données à charger pour la thèse");
        }
    } catch (error) {
        console.error("Erreur lors du parsing des données pour 'These' depuis localStorage :", error);
    }
} else {
    console.warn("Aucune donnée 'These' trouvée dans localStorage");
}

    }, []);
    
    const handleTextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value === "null" ? null : parseInt(event.target.value, 10);
        setSelectedTextIndex(value);
        if (value !== null) {
            setSelectedTextSelect(getAuteurTitre(textetrous[value]))
        } else {
            setSelectedTextSelect("Sélectionnez un texte")
        }
        setThese(initThese);
    };

    const getAuteurTitre = (texte: TextEntry) => {
        return texte.auteur + " - " + texte.ouvrage
    }

    return (
        <div style={{ textAlign: 'justify' }}>
            {isOpenInfo && (
          <InfoDialog isOpen={isOpenInfo} onClose={() => setOpenInfo(false)} text={"Sélectionnez un texte, et formulez ci-dessous la thèse générale du texte, c'est-à-dire l'idée principale défendue par l'auteur."} title={"Exposer une thèse"} />
        )}
            <div className="component" style={{ width: '450px', display: 'flex', marginTop: '30px', marginBottom: '-5px', alignItems: 'center', lineHeight: 1 }}>
                <div style={{ width: '150px' }}>
                    <div className="ajouterTxtMenu">
                        <div className="imgMenuTexte" style={{ cursor: 'pointer', backgroundImage: `url(${info})` }} onClick={() => setOpenInfo(true)}></div>
                    </div>


                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '10px' }}>
                    <select onChange={handleTextChange} value={selectedTextIndex === null ? "null" : selectedTextIndex}>
    <option value="null">Sélectionnez un texte</option>
    {textetrous.map((texte, index) => (
        <option key={index} value={index}>
            {getAuteurTitre(texte)}
        </option>
    ))}
</select>
                    </div>
                </div>
            </div>



            {selectedTextIndex !== null && (
                <>
                <div className="component" style={{ marginTop: '50px', textAlign: 'justify', padding: '10px', lineHeight: 1.5 }}>

                    <div>
                        <div>
                        {textetrous[selectedTextIndex].texte}
                        </div>
                        <div style={{ textAlign: 'right', marginTop: '20px' }}>
                            <p>
                                <strong>{textetrous[selectedTextIndex].auteur}</strong>,
                                <em> {textetrous[selectedTextIndex].ouvrage}</em>
                            </p>
                        </div>
                    </div>
                </div>
                
                
                <RedigerThese_Cpnt objet={these} setObjet={setThese} element={"contenu"} texte={textetrous[selectedTextIndex].texte} modeComposant={true}/>
                </>
                )

            }
        </div>
    );

};

export default RedigerThese;
