import React, { useState } from 'react';
import info from '~assets/menu_info.svg'
import ok from '~assets/ok_check.svg'
import erreur from '~assets/red_close.svg'
import InfoDialog from '~app/components/Info/Dialog/InfoDialog';

interface TexteReconstituer {
    auteur: string;
    ouvrage: string;
    texte: string[];
}

interface Props {
    etat: "info" | "ok" | "erreur",
    setEtat: (v: "info" | "ok" | "erreur") => void
    texteReconstituer: TexteReconstituer[]; // l'ensemble de tous les textes
    selectedTextIndex: number | null;
    setSelectedTextIndex: (v: number | null) => void
    texteUtilisateur: string[]
    setTexteUtilisateur: (v: string[]) => void
    backgroundColors: string[]
    setBackgroundColors: (v: string[]) => void
    reponsesDonnees: boolean,
    setReponsesDonnees: (v: boolean) => void

}


const MenuChoisirReconstituer: React.FC<Props> = ({ etat, setEtat, texteReconstituer, selectedTextIndex, setSelectedTextIndex, texteUtilisateur, setTexteUtilisateur, backgroundColors, setBackgroundColors, reponsesDonnees, setReponsesDonnees }) => {

    const [isOpenInfo, setOpenInfo] = useState(false) // attention, est pour la boite de dialogue, non pour le composant


    const [imgEtat, setImgEtat] = useState(info)
    const [messageCentral, setMessageCentral] = useState("")

    const handleTextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        const value = event.target.value === "null" ? null : parseInt(event.target.value, 10); // Convertissez la chaîne "null" en null
        setSelectedTextIndex(value);
        setMessageCentral("")
        setBackgroundColors([]);
        setReponsesDonnees(false);
        setImgEtat(info)
        if (value !== null) { // si un texte est vraiment chargé

            const ordreAleatoire = [...Array(texteReconstituer[value].texte.length).keys()].sort(() => Math.random() - 0.5);

            setTexteUtilisateur(ordreAleatoire.map(i => texteReconstituer[value].texte[i]));

        }
    };

    const handleCorrection = (showResults = false) => {
        let correctCount = 0;
        const newBackgroundColors: string[] = [];

        if (showResults) setReponsesDonnees(true);

        texteReconstituer[selectedTextIndex!].texte.forEach((element, qIndex) => {
            if (element == texteUtilisateur[qIndex]) {
                correctCount++;
                if (showResults) newBackgroundColors.push("#22eb00"); // Vert pour correct
            } else {
                if (showResults) newBackgroundColors.push("#ff9090"); // Rouge pour incorrect
            }


            if (showResults)
            {
                setBackgroundColors(newBackgroundColors);
                setTexteUtilisateur(texteReconstituer[selectedTextIndex!].texte);  // Mettez à jour l'état avec les nouvelles options sélectionnées
            }
        })

        if (correctCount == texteReconstituer[selectedTextIndex!].texte.length) {
            setImgEtat(ok)
            setMessageCentral(`Bravo ! Vous avez ${correctCount} bonnes réponses sur ${texteReconstituer[selectedTextIndex!].texte.length}`)
            setReponsesDonnees(true);

        } else {
            setImgEtat(erreur)
            setMessageCentral(`Vous avez ${correctCount} bonnes réponses sur ${texteReconstituer[selectedTextIndex!].texte.length}`)
        }
    }
        ;

    return (
        <div className="component" style={{ width: '450px', display: 'flex', marginTop: '30px', marginBottom: '75px', alignItems: 'center', lineHeight: 1 }}>
            <div style={{ width: '150px' }}>
                <div className="ajouterTxtMenu">
                    <div className="imgMenuTexte" style={{ cursor: 'pointer',backgroundImage: `url(${imgEtat})` }} onClick={() => setOpenInfo(true)}></div>
                </div>

            </div>
            <div style={{ flex: 1 }}>
                <div style={{ marginBottom: '10px' }}>
                    <select onChange={handleTextChange} style={{ margin: 'auto' }}>
                        <option value="null">Sélectionnez un texte</option>
                        {texteReconstituer.map((texte, index) => (
                            <option key={index} value={index}>
                                {texte.auteur} - {texte.ouvrage}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedTextIndex != null &&
                    <>
                        <div style={{ textAlign: 'center' }}>{messageCentral}</div>
                        <div style={{ display: 'flex' }}>
                            <div className="bouton_dialog" style={{
                                margin: 'auto',
                                marginTop: '10px',
                                marginRight: '10px',
                                fontSize: '100%',
                                filter: !reponsesDonnees ? 'none' : 'opacity(0.2)', // Appliquer un filtre si le bouton est désactivé
                                pointerEvents: !reponsesDonnees ? 'auto' : 'none', // Désactiver les événements du pointeur si le bouton est désactivé
                            }} onClick={() => handleCorrection(true)}>Voir réponses</div>
                            <div
                                className="bouton_dialog"
                                style={{
                                    margin: 'auto',
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    fontSize: '100%',
                                    filter: !reponsesDonnees ? 'none' : 'opacity(0.2)', // Appliquer un filtre si le bouton est désactivé
                                    pointerEvents: !reponsesDonnees ? 'auto' : 'none', // Désactiver les événements du pointeur si le bouton est désactivé
                                }}
                                onClick={() => {
                                    if (!reponsesDonnees) {
                                        handleCorrection();
                                    }
                                }}
                            >
                                Évaluer
                            </div>

                        </div>
                    </>}
            </div>


            {isOpenInfo && (
          <InfoDialog isOpen={isOpenInfo} onClose={() => setOpenInfo(false)} text={"Sélectionnez un texte, et déplacez les éléments en haut et en bas grâce aux flèches situées à droite.<br />Cliquez sur \"<b>Évaluer</b>\" pour connaître le nombre de fautes.<br />Cliquez sur \"<b>Voir réponses</b>\" pour avoir la correction."} title={"Texte à reconstituer"} />
        )}

        </div >

    );
};

export default MenuChoisirReconstituer;
