import { useState, useEffect } from "react"
import Dissertation from "~app/components/Dissertation/Dissertation"
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation"
import { getEmptyDissertation } from "~app/components/Dissertation/DissertationVide"
import plus_bleu from '~assets/plus_bleu.svg'
import NouvelleDissertation from "~app/components/Dissertation/MenuNouvelleDissertation/NouvelleDissertation"
import { addNewSujet } from "~app/components/Funcs/Funcs"
import { isTypeDissertation } from "~app/components/Funcs/Funcs"
import MenuComposant from "./MenuComposant"
import Problematique from "../Dissertation/Introduction/Problematique"

const T_Problematiser = () => {
    const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())
    const [showNewDissertation, setShowNewDissertation] = useState(false)

    useEffect(() => {
// Vérifier si la dissertation est définie et contient les informations nécessaires
if (dissertation && dissertation.sujet) {
    // Convertir et stocker la dissertation dans localStorage
    localStorage.setItem('Problematique', JSON.stringify(dissertation));
    
    // Optional: Ajouter un log pour vérifier l'enregistrement
    console.log("Dissertation enregistrée sous la clé 'Problematique' dans localStorage:", dissertation);
}

    }, [dissertation])

    useEffect(() => {
// Récupérer les données 'Problematique' depuis localStorage
const storedData = localStorage.getItem('Problematique');

if (storedData) {
    try {
        // Convertir la chaîne JSON en un objet JavaScript
        const data = JSON.parse(storedData);

        // Vérifier si l'objet récupéré est du bon type
        if (data && isTypeDissertation(data, getEmptyDissertation())) {
            setDissertation(data as Type_Dissertation);
        } else {
            console.warn("Les données récupérées ne sont pas du type attendu.");
        }
    } catch (error) {
        console.error("Erreur lors du parsing des données depuis localStorage:", error);
    }
} else {
    console.warn("Aucune donnée trouvée pour la clé 'Problematique' dans localStorage.");
}

    }, [])

    return <>

    {dissertation.sujet ? 
<>
<MenuComposant
        sujet={dissertation.sujet}
        setObjet={setDissertation}
        toShow={[true, true, false, false]}
    />

    <div className="composant" style={{marginTop:'50px'}}>
    <Problematique
        sujet={dissertation.sujet}
        objet={dissertation}
        setObjet={setDissertation}
        element={'intro.problematique'}
        modeComposant={true}
    />
    </div>
    </>
    :
    <>
          {showNewDissertation && (
        <NouvelleDissertation
        isOpen={showNewDissertation}
        onClose={() => setShowNewDissertation(false)}
        toShow={[true,true, false, false]}
        validate={addNewSujet(setDissertation)}
      />
      )}
    <div className="ajouterDissertation"  style={{margin: '70px auto auto auto'}}  onClick={() => setShowNewDissertation(true)}>
    <div className="plusAjouter" style={{ backgroundImage: `url(${plus_bleu})` }}></div>
    <div className="texteAjouterDissert">Ajouter<br />un sujet</div>
  </div>
  </>}
    </>
}

export default T_Problematiser