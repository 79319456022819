import { Concept, Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyPartie, getEmptyDissertation } from '../Dissertation/DissertationVide';
import set from 'lodash/set';
import _ from 'lodash'
import { ValuesAndNotes } from '~app/components/Dissertation/Menu/SetupParties';
import { getValuesAndNotes } from '~app/components/Dissertation/Menu/SetupParties';
import { Etape } from '~./app/components/Dissertation/StructureDissertation';
import { cloneDeep } from 'lodash';

export function updateDissertation(dissertation: Type_Dissertation, path: (string | number)[], value: any): Type_Dissertation {
  // Crée une copie de la dissertation
  //console.log("updateDissertation, état initial:", dissertation);
  //console.log("Chemin à mettre à jour:", path);
  //console.log("Valeur à mettre:", value);

  const newDissertation = { ...dissertation };

  // Utilise lodash.set pour modifier la valeur à l'emplacement spécifié
  set(newDissertation, path, value);
  //console.log("Dissertation après mise à jour:", newDissertation);

  return newDissertation;
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export function arraysAreEqual(a: number[], b: number[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}





export function updateDissertation2(dissertation: any, path: (string | number)[], value: any): Type_Dissertation {
  // Crée une copie de la dissertation
  const newDissertation = { ...dissertation };

  // Utilise lodash.set pour modifier la valeur à l'emplacement spécifié
  set(newDissertation, path, value);

  return newDissertation;
}





export function createEmptyDissertation(): Type_Dissertation {
  let emptyDissertation: any = {};

  function traverse(node: any) {
    for (let key in node) {
      if (typeof node[key] === "string") {
        node[key] = "";
      } else if (Array.isArray(node[key])) {
        node[key] = node[key].map(traverse);
      } else if (typeof node[key] === "object" && node[key] !== null) {
        node[key] = traverse({});
      }
    }
    return node;
  }

  return traverse(emptyDissertation);
}



function isObject(item: any): boolean {
  return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

export function isTypeDissertation(data: any, model: any): data is Type_Dissertation {
  if (!isObject(data) || !isObject(model)) {
    return false;
  }

  for (const key in model) {
    if (!data.hasOwnProperty(key)) {
      return false;
    }

    if (isObject(model[key])) {
      if (!isTypeDissertation(data[key], model[key])) {
        return false;
      }
    }
  }

  return true;
}

export function isPartieBeyondForbidden(etape:number[], forbiddenEtapeNumber :number[]):boolean {
  if (forbiddenEtapeNumber[0] == 0) { // si la forbiddenEtape est dans l'intro
    if ((etape[0] == 1) || (etape[0] == 2)) { // si on la dépasse du point de vue de la grande partie...
      return true
    } else if (etape[1] > forbiddenEtapeNumber[1]) return true
  } else if (forbiddenEtapeNumber[0] == 2) { // si la forbiddenEtape se trouve dans la conclusion, on se contente de comparer les étapes
    if ((etape[0] == 2) && etape[1] > forbiddenEtapeNumber[1]) return true
  } else if (forbiddenEtapeNumber[0] == 1) {
    if (etape[0] == 2) {
      return true
    } else if (etape[0] == 1) { // si on est dans le développement
      if (etape[1] > forbiddenEtapeNumber[1]) // si on est dans une partie postérieure à l'erreur
      {
        return true
      } else if (etape[1] == forbiddenEtapeNumber[1]) {  //si on est dans la même partie...
        // on va devoir tester les étapes
        if (etape[2] > forbiddenEtapeNumber[2])  return true
        if ((etape[2] == 1) && (forbiddenEtapeNumber[2] == 1)) {
          // si la page interdite est dans un développement de partie, il faut tester les sous-parties
          if (etape[3] > forbiddenEtapeNumber[3])  return true
        }
      }
    }
  }
  return false;
}

export const deletePartie3 = (setDissertation: (value: any) => any, setUpdateStructure:(v:boolean) => void) => {
  setDissertation((prevObject:Type_Dissertation) => ({
    ...prevObject,
    developpement: prevObject.developpement.filter((_, index) => index !== 2)
    
  }));

  

  //console.log ("modification de toDelete" )
  setUpdateStructure(true)

};

export function addPartie3(dissertation:Type_Dissertation, setDissertation: (value: any) => any, setUpdateStructure:(v:boolean) => void) {  // ajouter la troisième partie
  setValue(setDissertation, "developpement.2")(getEmptyPartie())
  setUpdateStructure(true)

}

export function getValue(obj: any, path: string): (string | number) {
  //console.log("on rentre dans getValue")
  /*const pathArray:string[] = path.split('.')
  return pathArray.reduce((o: any, k: string) => o && o[k], obj);*/
  return "hello"
}

export function getStrValue(obj: any, path: string): string {
  //console.log("on rentre dans getStrValue")

  const pathArray: (string | number)[] = path.split('.').map(item => {
    const num = parseInt(item);
    return isNaN(num) ? item : num;
  });  return pathArray.reduce((o: any, k: string | number) => o && o[k], obj);
}

export function getNumValue(obj: any, path: string): number {
  //console.log("on rentre dans getNumValue")

  const pathArray: (string | number)[] = path.split('.').map(item => {
    const num = parseInt(item);
    return isNaN(num) ? item : num;
  });  return parseInt(pathArray.reduce((o: any, k: string | number) => o && o[k], obj));
}

/*export function setValue(
  setState: React.Dispatch<React.SetStateAction<Type_Dissertation>>,
  path: string): any {
    console.log("on rentre dans setValue")

    const pathArray: (string | number)[] = path.split('.').map(item => {
      const num = parseInt(item);
      return isNaN(num) ? item : num;
    });  return changeF(setState, pathArray)
}*/

export function setValue(setDissertation: any, pathStr: string) {
  return function(value: any) {
      const keys = pathStr.split(".");

      const recursivelySet = (obj: any, keys: string[], value: any): any => {
          const key = isNaN(Number(keys[0])) ? keys[0] : Number(keys[0]);
          
          if (keys.length === 1) {
              if (typeof key === "number") {
                  const newArr = [...obj];
                  newArr[key] = value;
                  return newArr;
              } else {
                  return { ...obj, [key]: value };
              }
          }

          const [firstKey, ...remainingKeys] = keys;
          if (typeof key === "number") {
              const newArr = [...obj];
              newArr[key] = recursivelySet(obj[key] || {}, remainingKeys, value);
              return newArr;
          } else {
              return {
                  ...obj,
                  [firstKey]: recursivelySet(obj[firstKey] || {}, remainingKeys, value),
              };
          }
      };

      setDissertation((prevDissertation: Type_Dissertation) => recursivelySet(prevDissertation, keys, value));
    };
}


// Utilisatio

// Utilisation

// Utilisation


export function loadDissertation(setObjet:(value: any) => void, dissert:Type_Dissertation, setUpdateStructure:(v:boolean) => void) {

  if (dissert && Object.keys(dissert).length > 0) {
    if (isTypeDissertation(dissert, getEmptyDissertation())) {
      //console.log("on est censé lancer le setObjet")
      //console.log(dissert)
      setObjet(_.cloneDeep(dissert))
      setUpdateStructure(true)
      return true
    }
  }
  /*console.log(
    "Erreur de format à l'importation de la dissertation. La dissertation a été réinitialisée.",
  )*/
  setObjet(getEmptyDissertation())
  return false
}

export function getStats(dissert:Type_Dissertation):([pourcentage:number,notes_t:number[]]) {
  //console.log("avant getValuesAndNotes")
  const valuesAndNotesTemp: ValuesAndNotes[] = getValuesAndNotes(dissert)
  //console.log("après getValuesAndNotes")


  let nbOccurences = 0
  let nbTotal = 0
  let ended = false
  let notes_t: number[] = [0, 0, 0, 0]
  for (let i = 0; i < valuesAndNotesTemp.length; i++) {
      for (let j = 0; j < valuesAndNotesTemp[i].notes.length; j++) {
          if (!ended) notes_t[valuesAndNotesTemp[i].notes[j]] += 1
      }
      for (let j = 0; j < valuesAndNotesTemp[i].valeursATester.length; j++) {
          nbTotal += 1;
          if (valuesAndNotesTemp[i].valeursATester[j].valeur == "") {
              ended = true
          } else {
              if (!ended) nbOccurences += 1
          }
      }
  }
  //console.log("après le calcul dans le tableau")

  return [Math.round((nbOccurences / nbTotal) * 100) as number, notes_t]
}

export function toValidFilename(str:string) {
  // Étape 1 : Supprimez ou remplacez les caractères non autorisés
  let validStr = str.replace(/[\/\\:*?"<>|]/g, ''); 

  // Étape 2 : Limitez la longueur du nom de fichier (par exemple, à 255 caractères)
  if (validStr.length > 150) {
      validStr = validStr.substring(0, 150);
  }

  // Étape 3 : Assurez-vous que le nom de fichier ne commence ni ne se termine par un espace
  validStr = validStr.trim();

  return validStr;
}

export function getGoBack(currentPage: number[], etapes:Etape[]): number[] {
  const index = etapes.findIndex((etape) => arraysAreEqual(etape.goTo, currentPage))
  //console.log('INDEX', index)
  //console.log('ETAPES', etapes)
  if (index == undefined || index <= 0) {
    return []
  } else {
    return etapes[index - 1].goTo
  }
}

export function getGoNext(currentPage: number[], etapes:Etape[]): number[] {
  const index = etapes.findIndex((etape) => arraysAreEqual(etape.goTo, currentPage))
  //console.log('INDEX', index)
  //console.log('ETAPES', etapes)
  if (index == undefined || index >= etapes.length - 1) {
    return []
  } else {
    return etapes[index + 1].goTo
  }
}


export const addNewSujet = (setDiss:(v:Type_Dissertation) => void, toDo?:string) => {
  return (newDissertation:Type_Dissertation) => {
    if (newDissertation) {
      //(newDissertation, newDissertation.sujet.trim())
      if (newDissertation.sujet.trim() == "") { //déjà pris en charge par NouvelleDissertation.tsx normalement, mais au cas où...
          //alert("Il est nécessaire d'ajouter un sujet.")
          console.log("Il est nécessaire d'ajouter un sujet.")
      } else { // tout va bien, on enregistre
          if (toDo == "supprimerSynthese") {
          let newV = cloneDeep(newDissertation);
          newV.conclusion.synthese.contenu = "";
          console.log ("new : ", newV)
          setDiss(newV)  
          } else if (toDo == "supprimerOuverture") {
            let newV = cloneDeep(newDissertation);
            newV.conclusion.ouverture.contenu = "";
            console.log ("new : ", newV)
            setDiss(newV)  
            } else setDiss(newDissertation)      
      }
  } else 
  {
      console.log("Aucune dissertation n'a été définie !")
  }
  }

}

export const addNewConcept = (setConcept:(v:Concept) => void) => {
  return (newConcept:Concept) => {
    if (newConcept) {
      //(newDissertation, newDissertation.sujet.trim())
      if (newConcept.nomConcept.trim() == "") { //déjà pris en charge par NouvelleDissertation.tsx normalement, mais au cas où...
          //alert("Il est nécessaire d'ajouter un sujet.")
          console.log("Il est nécessaire d'ajouter un concept.")
      } else { // tout va bien, on enregistre
        setConcept(newConcept)      
      }
  } else 
  {
      console.log("Aucun concept n'a été défini !")
  }
  }


}
