import React, { FC, useEffect } from "react";
import ViewText_Intro from "./ViewText_Intro";
import ViewText_Conclu from "./ViewText_Conclu";
import ViewText_Partie from "./ViewText_Partie";
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation";
import { Etape_Composant } from "~types";

export const GetText: FC<{
    partie: number[],
    dissertation: Type_Dissertation,
    setTitle: (k: string) => void,
    afficherTexteComposant: (v: string, color?: string) => void,
    cacherTexteComposant: () => void,
    goThere: (partie: Etape_Composant) => void,
    titre?: string
}> = ({ partie, titre, dissertation, setTitle, afficherTexteComposant, cacherTexteComposant, goThere }) => {

    useEffect(() => {
        if (partie[0] === 1) {
            setTitle("Développement");
        } else if (partie[0] === 3) {
            setTitle("Dissertation complète");
        }
    }, [partie, setTitle]);

    return (
        <>
            {partie[0] === 0 &&
                <ViewText_Intro key="view_intro" setTitle={setTitle} dissertation={dissertation} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} />
            }
            {partie[0] === 1 &&
                <>
                    {partie.length === 2 &&
                        <ViewText_Partie key={`view_partie_${partie[1]}`} setTitle={setTitle} dissertation={dissertation} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} nbPartie={partie[1]} titre={titre} />
                    }
                    {partie.length === 1 &&
                        <>
                            {dissertation.developpement.map((partie, index) => (
                                <React.Fragment key={`get_text_partie_${index}`}>
                                    <GetText key={`get_text_partie_${index}`} partie={[1, index]} titre={"Développement"} dissertation={dissertation} setTitle={setTitle} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} />
                                    {index + 1 < dissertation.developpement.length && <br key={`br_${index}`} />}
                                </React.Fragment>
                            ))}
                        </>
                    }
                </>
            }
            {partie[0] === 2 &&
                <ViewText_Conclu key="view_conclu" setTitle={setTitle} dissertation={dissertation} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} titre={titre} />
            }
            {partie[0] === 3 &&
                <>
                    <GetText key="get_text_partie_0" partie={[0]} titre={"Dissertation complète"} dissertation={dissertation} setTitle={setTitle} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} />
                    <br key="br_1" /><br key="br_2" />
                    <GetText key="get_text_partie_1" partie={[1]} titre={"Dissertation complète"} dissertation={dissertation} setTitle={setTitle} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} />
                    <br key="br_3" /><br key="br_4" />
                    <GetText key="get_text_partie_2" partie={[2]} titre={"Dissertation complète"} dissertation={dissertation} setTitle={setTitle} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} goThere={goThere} />
                </>
            }
        </>
    );
};

export default GetText;
