
import ReactDOM from 'react-dom/client';
import '~./styles/base.scss';
import '~./styles/dialog.scss';
import './i18n';


import AppRouter from './router';  // Assuming AppRouter is the default export from './router'

const container = document.getElementById('app')!;

ReactDOM.createRoot(container).render(<AppRouter />);

// in main.tsx
