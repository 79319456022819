import { FC, useEffect, useState, useCallback, useRef } from 'react'
import InputText from '~app/components/Inputs/InputText';
import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyDissertation } from '../DissertationVide';
import dissert_json from '~app/components/Dissertation/Modèles/Modèles_dissert.json'
import intro_json from '~app/components/Dissertation/Modèles/Modèles_intro.json'


interface Sujet {
    sujet: string;
    notions: string[];
}


interface Props {
    setNewDissertation: (n: Type_Dissertation) => void
    onlyCompleteDissertations?:boolean
}


interface SujetListProps {
    sujets: string[]
    setSujet:(v:string)=>void
    selectedSujet:string
    setSelectedSujet:(v:string)=>void
    sujetsRef:React.RefObject<HTMLSelectElement>
}

const SujetList: React.FC<SujetListProps> = ({ sujets, setSujet, selectedSujet, setSelectedSujet, sujetsRef }) => {



    const handleSujetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSujet(event.target.value);
        setSelectedSujet(event.target.value)
    }

    return (
        <select value={selectedSujet} ref={sujetsRef} name="sujets_select" size={5} style={{ width: '100%', height:'200px' }} onChange={handleSujetChange}>
            <option value="" style={{ display: 'none' }}></option>
            {sujets.map((sujet, index) => (
                <option key={index} value={sujet}>{sujet}</option>
            ))}
        </select>
    );
};

const ImporterModele: React.FC<Props> = ({ setNewDissertation, onlyCompleteDissertations }) => {
    const [sujet, setSujet] = useState<string>("")

    const [allSujets, setAllSujets] = useState<string[]>([])

    const sujetsRef = useRef<HTMLSelectElement>(null);

    const disserts = dissert_json as Type_Dissertation[];
    const intros = intro_json as Type_Dissertation[];



    const [typeSujet, setTypeSujet] = useState("Dissertations complètes")

    const [selectedSujet, setSelectedSujet] = useState("")

    const [selectedValueType, setSelectedValueType] = useState<string>('Dissertations complètes');

    const scrollToTop = () => {
        if (sujetsRef.current) {
            sujetsRef.current.scrollTop = 0;
          }
      
    }

    useEffect(() => {
        let sujets = [] as Type_Dissertation[]
        if (typeSujet == "Dissertations complètes") {
            sujets = disserts
        } else if (typeSujet == "Introductions") {
            sujets = intros
        }

        //console.log (sujet, selectedSujet)
        //console.log("typeSujet, sujets", typeSujet, sujets)
        const selectedDissertation = sujets.find(dissertation => dissertation.sujet === sujet);
  
        if (selectedDissertation) {
            setNewDissertation(selectedDissertation)
        } else {
            console.log("Erreur imprévue dans le chargement du modèle")
        }
        
    }, [sujet])

    useEffect(() => {
        setAllSujets(disserts.flatMap(s => s.sujet))
    }, [])

    useEffect(() => {


        console.log ("sujet, selectedSujet", sujet, selectedSujet)

        /* ici pour obtenir la liste des sujets à partir des disserts
        const allSujetsT = tSujets.flatMap(s => s.sujet)
        setAllSujets(allSujetsT)

        if (!allSujetsT.includes(selectedSujet)) {
            setSujet("");
            setSelectedSujet("");
            scrollToTop()
        }
        */
    }, [typeSujet])


    useEffect(() => {
        if (typeSujet == "Dissertations complètes") {
            setAllSujets(disserts.flatMap(s => s.sujet))
        } else if (typeSujet == "Introductions") {
            setAllSujets(intros.flatMap(s => s.sujet)) //à changer
        }
    }, [typeSujet])

    const handleTypeSujetsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log("Nouvelle valeur :",event.target.value)
        setTypeSujet(event.target.value);
        setSelectedValueType(event.target.value)

    }
    return <>
        {!onlyCompleteDissertations &&
        <div style={{ display: 'flex', width: '100%', marginBottom: '15px' }} >
            <div style={{ width: '400px', textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                Type de document à charger :
            </div>
            <div style={{ width: '100%', marginLeft: '20px' }}>
            <select value={typeSujet} ref={sujetsRef} name="sujets_select" style={{ width: '100%' }} onChange={handleTypeSujetsChange}>
                <option key={1} value={"Dissertations complètes"}>Dissertations complètes</option>
                <option key={2} value={"Introductions"}>Introductions</option>
        </select>            </div>
        </div>
}
        <SujetList sujets={allSujets} setSujet={setSujet} selectedSujet={selectedSujet} setSelectedSujet={setSelectedSujet} sujetsRef={sujetsRef}/>
    </>
}

export default ImporterModele