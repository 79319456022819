import { FC, useState, useContext } from 'react'
import QuestionDialog from '~app/components/Info/Dialog/QuestionDialog'
import { MessageCentralContext } from '~app/components/Context'

import { arraysAreEqual } from '~app/components/Funcs/Funcs'
import close_img from '~assets/close.svg'
import Tooltip from '~app/components/Tooltip'
import { Etape } from '~./app/components/Dissertation/StructureDissertation'
import { UpdateStructureContext } from '~app/components/Context'
import { CurrentOpenedContext } from '~app/components/Context'


interface Props {
  etape: Etape
  message?: string
  emptyAll?: (() => void) | void
  backGo?: number[]
  nextGo?: number[]
  setCurrentPage: (v: number[]) => void
}

const TitrePage: FC<Props> = ({ etape, emptyAll, message, backGo, nextGo, setCurrentPage }) => {
  const context = useContext(MessageCentralContext)
  if (!context) throw new Error('MessageCentral is undefined')
  const [, , , , , , afficherTexte, cacherTexte] = context

  const context2 = useContext(UpdateStructureContext)
  if (!context2) throw new Error('UpdateStructureContext is undefined')
  const [, setUpdateStructure] = context2

  const [isOpenEmpty, setOpenEmpty] = useState(false)

  //Note : effectivement, le "etape &&" du début du return n'a théoriquement pas de sens. Mais comme il faut attendre que le tableau se génère, il faut éviter l'erreur en cas de valeur non encore définie

  return (
    
    <div className="containerTitrePage">

      <div className="titrePage">
        <h1>
          {etape && (
            <>
              {etape.nom}
              {etape.nb_valeurs_necessaires != 0 ? (
                <>
                  {' '}({etape.nb_valeurs_renseignees}/{etape.nb_valeurs_necessaires})
                </>
              ) : (
                <></>
              )}
              
              {emptyAll && (
                <div style={{marginLeft: '3px', marginTop: '-1px'}}>
                  <Tooltip text="Tout supprimer">
                    <button
                      className="titre-button titre-button-opened margin-left-20 margin-top-3 scalable"
                      onClick={() => {
                        setOpenEmpty(true)
                      }}
                      onMouseOver={() => {
                        if (message) afficherTexte(message, 'red')
                      }}
                      onMouseOut={() => cacherTexte()}
                    >
                      <img src={close_img} className="boutonsContainer " />
                    </button>
                  </Tooltip>
                </div>
              )}
              {isOpenEmpty && emptyAll && (
                <QuestionDialog
                  isOpen={isOpenEmpty}
                  onClose={() => setOpenEmpty(false)}
                  text="Tous les champs de cette page vont être réinitialisés. Cette action est irréversible."
                  title="Tout supprimer ?"
                  reponse1="Oui"
                  reponse2="Non"
                  onReponse1={() => { emptyAll(); setUpdateStructure(true) }
                  }
                />
              )}
            </>
          )}
        </h1>
      </div>

      
    </div>
  )
}

export default TitrePage
