import React, { useState, FC, useEffect, useContext, useCallback } from 'react'
import Tooltip from '../Tooltip'
import FlecheG from '~assets/fleche_g.svg'
import FlecheD from '~assets/fleche_d.svg'

type CircleProps = {
  numero: number
  currentPage: number
  setCurrentPage: (v: number) => void
  etapes: string[]
}

const Circle: React.FC<CircleProps> = ({ currentPage, setCurrentPage, numero, etapes }) => {


  const handleClick = () => {
    setCurrentPage(numero)
  }
  //console.log(couleur)
  const getCercle = useCallback(() => {
    if (currentPage == numero) { //si il s'agit d
      return (
        <div className={'circle_deactivated grey'}
        />
      )
    } else {
      return ( // si le cercle n'est pas actif
        <div
          className={'circle clickable_circle grey'
          }
          onClick={handleClick}
        ></div>
      )
    }
  }, [currentPage, numero])

  return <>{getCercle()}</>
}

const EtapesTutoriels: FC<{
  etapes: string[]
  currentPage: number
  setCurrentPage: (v: number) => void
}> = ({ etapes, currentPage, setCurrentPage }) => {

  return (
    <>
      <div className="component" style={{ margin: '70px auto 30px auto', height:'140px', padding: '20px', width: '600px', display: 'flex', minWidth: 0 }}>
        {(etapes.length > 1) && currentPage > 0 ? (
          <div className="imgFlecheTitre imgFlecheImageG" onClick={() => { setCurrentPage(currentPage - 1) }}>
            <Tooltip text={"Etape précédente"}>

              <img src={FlecheG} className="imgFlecheImage " />
            </Tooltip>

          </div>
        ) : <div style={{ width: '50px', height: '70px' }} />}


        <div style={{ width: '460px' }}>
          <div className="App" >
            <div className="etapes-container">
              <div className="circles-container">
                {etapes.map((etape, index) => (
                  <Tooltip text={etape} direction="down" time={0} key={index}>
                    <div className="containerCercle">
                      <Circle
                        numero={index}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        etapes={etapes}
                      />
                    </div>
                  </Tooltip>
                ))}

              </div>
            </div>
            <div className="containerTitrePage" style={{marginTop:'20px'}}>

              <div className="titrePage" style={{alignItems: 'center'}}>
                <h1>{currentPage + 1}. {etapes[currentPage]}
                </h1>
              </div>
            </div>

          </div>

        </div>

        {(etapes.length > 1 && currentPage < etapes.length - 1) ? (
          <div className="imgFlecheTitre imgFlecheImageD" onClick={() => { setCurrentPage(currentPage + 1) }}>
            <Tooltip text={"Etape suivante"}>
              <img src={FlecheD} className="imgFlecheImage " />
            </Tooltip>
          </div>
        ) : <div style={{ width: '50px', height: '70px' }} />}
      </div>

    </>
  )
}

export default EtapesTutoriels
