import React, { FC, useEffect, useCallback } from "react";
import { Facultatif as Tfacultatif, Necessaire as Tnecessaire } from "./Composants";
import { Type_Dissertation, Partie } from "~app/components/Dissertation/StructureDissertation";
import { Etape_Composant } from "~types";

interface Props {
    setTitle: (v: string) => void;
    dissertation: Type_Dissertation;
    afficherTexteComposant: (texte: string, couleur?: string) => void;
    cacherTexteComposant: () => void;
    goThere: (partie: Etape_Composant) => void;
    nbPartie: number;
    titre?: string;
}

export const ViewText_Partie: FC<Props> = ({ setTitle, dissertation, afficherTexteComposant, cacherTexteComposant, goThere, nbPartie, titre }) => {

    const Facultatif: FC<{ texte: string; classe: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, infoComposant, toGo }) => {
        return <Tfacultatif texte={texte} classe={classe} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    const Necessaire: FC<{ texte: string; classe: string; erreur: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, erreur, infoComposant, toGo }) => {
        return <Tnecessaire texte={texte} classe={classe} erreur={erreur} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    // useEffect to set the title after component has rendered
    useEffect(() => {
        let titre_temp = titre ? titre : 'Partie ' + (nbPartie + 1);
        setTitle(titre_temp);
    }, [nbPartie, titre, setTitle]);

    // Define the situationComposant based on the current part
    const situationComposant = 'Développement > Partie ' + (nbPartie + 1) + ' > ';

    // Get the specific part from dissertation based on nbPartie prop
    const partie: Partie = dissertation.developpement[nbPartie];

    return (
        <>
            <p key="partie_intro">
                <Necessaire
                    key="partie_intro_necessaire"
                    classe="partie_intro"
                    texte={partie.intro.contenu}
                    erreur={"Introduction de partie manquante"}
                    infoComposant="Introduction de partie"
                    toGo={{ etape: [1, nbPartie, 0], composant: 0 }}
                />
            </p>
            {partie.sousparties.map((souspartie, index) => (
                <p key={`souspartie_${index}`}>
                    <Necessaire
                        key={`souspartie_${index}_intro_necessaire`}
                        classe="souspartie_intro"
                        texte={souspartie.intro.contenu}
                        erreur={"Introduction de sous-partie manquante"}
                        infoComposant="Introduction de sous-partie"
                        toGo={{ etape: [1, nbPartie, 1, index], composant: 0 }}
                    />
                    <Necessaire
                        key={`souspartie_${index}_dev_necessaire`}
                        classe="souspartie_developpement"
                        texte={souspartie.dvlpt_souspartie.exposition_dvlpt + " " + souspartie.dvlpt_souspartie.analyse_dvlpt}
                        erreur={"Développement de la sous-partie manquant"}
                        infoComposant="Développement de la sous-partie"
                        toGo={{ etape: [1, nbPartie, 1, index], composant: 1 }}
                    />
                    <Necessaire
                        key={`souspartie_${index}_conclu_necessaire`}
                        classe="souspartie_conclu"
                        texte={souspartie.conclusion.contenu}
                        erreur={"Conclusion de sous-partie manquante"}
                        infoComposant="Conclusion de sous-partie"
                        toGo={{ etape: [1, nbPartie, 1, index], composant: 2 }}
                    />
                </p>
            ))}
            <p key="partie_conclu">
                <Necessaire
                    key="partie_conclu_necessaire"
                    classe="partie_conclu"
                    texte={partie.conclusion.contenu}
                    erreur={"Conclusion de partie manquante"}
                    infoComposant="Conclusion de partie"
                    toGo={{ etape: [1, nbPartie, 2], composant: 0 }}
                />
            </p>
        </>
    );
};

export default ViewText_Partie;
