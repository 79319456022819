import Dialog from './Dialog'

interface Props {
    isOpen: boolean
    text:string,
    title:string,
    reponse1:string,
    reponse2:string,
    onReponse1:  () => void,
    onReponse2?:() => void,
    onClose: () => void
    classname?:string;
  }

  //prop classname à ajouter ?

  const QuestionDialog = (props: Props) => {
    return (
        <Dialog
          title={props.title}
          open={props.isOpen}
          onClose={props.onClose}
          smallDialog={true}
        >
            <div className="texteInfo"><div className={props.classname ? props.classname : ''} dangerouslySetInnerHTML={{ __html: props.text }} /></div>
            <div className="boutons-container">
            <button className="bouton_dialog" onClick={() => {props.onReponse1(); props.onClose();}}>{props.reponse1}</button>
            <button className="bouton_dialog" onClick={() => {if (!props.onReponse2) props.onClose(); else {props.onReponse2();props.onClose();}}}>{props.reponse2}</button>
            </div>

            </Dialog>
    )
    }
export default QuestionDialog

