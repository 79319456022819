import { FC } from 'react'

import Synthese from './Synthese'
import Ouverture from './Ouverture'

import { Etape } from '~./app/components/Dissertation/StructureDissertation'

import { ElementReminder } from '~./app/components/Dissertation/StructureDissertation'

interface Props {
  sujet: string
  objet: any
  setObjet: (value: any) => void
  element:string

  currentPage: number[]
  etapes:Etape[]
  emptyAll?: (() => void) | void
  setEmptyAll?: (func: () => void) => void;
}

const Conclusion: FC<Props> = ({
  sujet,
  objet,
  setObjet,
  element,
  currentPage,
  etapes,
  setEmptyAll,
}) => {

  const getConclusions = ():ElementReminder[] => {
    const firstConclusions = [
      {categorie:"Conclusion de la partie 1", texte:objet.developpement[0].conclusion.contenu},
      {categorie:"Conclusion de la partie 2", texte:objet.developpement[1].conclusion.contenu}
    ]
    if (!(objet.developpement.length == 3)) return firstConclusions; else return firstConclusions.concat({categorie:"Conclusion de la partie 3", texte:objet.developpement[2].conclusion.contenu})
  }

  return (
    <>
      {currentPage[1] == 0 && (
        <Synthese
          sujet={sujet}
          objet={objet}
          setObjet={setObjet}
          element={"conclusion.synthese"}
          setEmptyAll={setEmptyAll}
          infosReminder={getConclusions()}
        />
      )}
       {currentPage[1] == 1 && (
        <Ouverture
        sujet={sujet}
        objet={objet}
        element={"conclusion.ouverture"}
        setObjet={setObjet}
        setEmptyAll={setEmptyAll}
        infosReminder={{categorie:"Synthèse de la dissertation", texte:objet.conclusion.synthese.contenu}}
      />
      )}
    </>
  )
}

export default Conclusion
