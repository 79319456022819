import React, { FC, useEffect, useCallback } from "react";
import { Facultatif as Tfacultatif, Necessaire as Tnecessaire } from "./Composants";
import { Type_Dissertation } from "~app/components/Dissertation/StructureDissertation";
import { Etape_Composant } from "~types";

interface Props {
    setTitle: (v: string) => void;
    dissertation: Type_Dissertation;
    afficherTexteComposant: (texte: string, couleur?: string) => void;
    cacherTexteComposant: () => void;
    goThere: (partie: Etape_Composant) => void;
    titre?: string;
}

export const ViewText_Conclu: FC<Props> = ({ setTitle, dissertation, afficherTexteComposant, cacherTexteComposant, goThere, titre }) => {

    const Facultatif: FC<{ texte: string; classe: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, infoComposant, toGo }) => {
        return <Tfacultatif texte={texte} classe={classe} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    const Necessaire: FC<{ texte: string; classe: string; erreur: string; infoComposant: string; toGo?: Etape_Composant }> = useCallback(({ texte, classe, erreur, infoComposant, toGo }) => {
        return <Tnecessaire texte={texte} classe={classe} erreur={erreur} infoComposant={infoComposant} toGo={toGo} afficherTexteComposant={afficherTexteComposant} cacherTexteComposant={cacherTexteComposant} situationComposant={situationComposant} goThere={goThere} />;
    }, []);

    useEffect(() => {
        let titre_temp = titre ? titre : 'Conclusion complète';
        setTitle(titre_temp);
    }, [titre, setTitle]);

    const situationComposant = 'Conclusion > ';

    return (
        <>
            <p>
                <Necessaire
                    classe="conclu_synthese"
                    texte={dissertation.conclusion.synthese.contenu}
                    erreur="Synthèse de la conclusion manquante"
                    infoComposant="Synthèse de la conclusion"
                    toGo={{ etape: [2, 0], composant: 0 }}
                />
            </p>
            <p>
                <Facultatif
                    classe="conclu_ouverture"
                    texte={dissertation.conclusion.ouverture.contenu}
                    infoComposant="Ouverture de la conclusion"
                    toGo={{ etape: [2, 1], composant: 0 }}
                />
            </p>
        </>
    );
};

export default ViewText_Conclu;
