import { useEffect, useState } from 'react'
import InputText from '~app/components/Inputs/InputText';
import { Concept } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyContenu} from '../DissertationVide';
import { Concept_liste } from '~./app/components/Dissertation/StructureDissertation';

interface Props {
    setNewConcept: (n: Concept) => void
}

const EntrerNouveauConcept: React.FC<Props> = ({ setNewConcept }) => {
    const [sujet, setSujet] = useState<string>("")

    useEffect(() => {
        const tDiss = {nomConcept:"",contenu:getEmptyContenu()}
        tDiss.nomConcept = sujet.charAt(0).toUpperCase() + sujet.slice(1);
        setNewConcept(tDiss)
    }, [sujet])

    return <>
        <div>Entrez ici le nouveau concept :</div>
        <div className="inputSujet">
            <InputText value={sujet} onChange={setSujet} placeholder="" />
        </div>
    </>
}

export default EntrerNouveauConcept