import { useState, useEffect, useContext, ReactNode, FC } from 'react'
import MenuDissertation from './Menu/Menu'
import { arraysAreEqual, isPartieBeyondForbidden } from '~app/components/Funcs/Funcs'

//import Introduction from '~./app/components/Dissertation/Introduction/Introduction'
//import Partie from './Partie/Partie'
import Load_GrandePartie from './Load_GrandePartie'

import { Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyDissertation } from '~./app/components/Dissertation/DissertationVide'

//import { DissertContext } from '~./app/components/Context'
import { MessageCentralContext, CurrentPageContext, UpdateStructureContext } from '../Context'

import {
  ValuesAndNotes,
  getValuesAndNotes,
  generateStructureMenu,
} from '~app/components/Dissertation/Menu/SetupParties'

import { Etape } from '~./app/components/Dissertation/StructureDissertation'

import { CurrentOpenedContext } from '../Context'

import { getStrValue, getNumValue, setValue } from '~app/components/Funcs/Funcs'
import { CurrentErrorComposantContext } from '~app/components/Context'
import { useNavigate } from 'react-router-dom'
import { getValuesAndNotesIntro } from '~app/components/Dissertation/Menu/SetupParties'
import IntroSeuleMenu from '../Entrainement/ElementDeDissertation/IntroSeuleMenu'
import { ReminderContext } from '../Context'

interface Props {
  dissertation: Type_Dissertation,
  setDissertation: (v: Type_Dissertation) => void
  introSeule?: boolean
}

const Dissertation: FC<Props> = ({ dissertation, setDissertation, introSeule }) => {
  //const [dissertation, setDissertation] = useState<Type_Dissertation>(getEmptyDissertation())

  const context = useContext(CurrentErrorComposantContext)
  if (!context) throw new Error('CurrentErrorComposantContext is undefined')
  const [forbiddenEtape, setForbiddenEtape,,] = context

  const context4 = useContext(ReminderContext)
  if (!context4) throw new Error('ReminderContext is undefined')
  const [reminder, setReminder] = context4
  

  const [currentPage, setCurrentPage] = useState<number[]>([0, 1])
  const [prevDissertation, setPrevDissertation] = useState<Type_Dissertation>(getEmptyDissertation());

  const navigate = useNavigate()

  const [updateStructure, setUpdateStructure] = useState<boolean>(false)

  const [currentOpened, setCurrentOpened] = useState<number>(0)


  const [messageCentral, setMessageCentral] = useState<string>('')
  const [fade, setFade] = useState<boolean>(false)
  const [color, setColor] = useState('#000000') // ce color est la couleur du texte, non du cercle !

  const [valuesAndNotes, setValuesAndNotes] = useState<ValuesAndNotes[]>([])
  const [etapes, setEtapes] = useState<Etape[]>([])


  useEffect(() => {
  }, [])

  const afficherTexte = (texte: string, couleur?: string) => {
    setMessageCentral(texte)
    setColor(couleur ? couleur : '#000000')
    setFade(true)
  }

  const cacherTexte = () => {
    setFade(false)
  }


  function deepCompare(obj1: any, obj2: any, path: string = ''): boolean {
    for (const key in obj1) {
      const currentPath = path ? `${path}.${key}` : key;

      // Si c'est un objet ou un tableau, on plonge récursivement
      if (typeof obj1[key] === 'object' && obj1[key] !== null && obj2[key]) {
        if (deepCompare(obj1[key], obj2[key], currentPath)) {
          // Si l'appel récursif renvoie true, alors renvoyer true immédiatement
          return true;
        }
      } else {
        const currentIsEmpty = !obj1[key] || obj1[key] === '';
        const prevIsEmpty = !obj2[key] || obj2[key] === '';

        if (currentIsEmpty !== prevIsEmpty) {
          //console.log(`La clé "${currentPath}" est passée de "${prevIsEmpty ? 'vide' : 'non vide'}" à "${currentIsEmpty ? 'vide' : 'non vide'}".`);
          return true;
        }
      }
    }
    return false;
  }


  useEffect(() => {
    //console.log("On change la dissertation")
    if (dissertation) {

      //console.log("modification de la dissertation.", dissertation.intro.definitions.def_concept1.evaluation)
      if (deepCompare(dissertation, prevDissertation)) {
        // console.log("mise à jour de la structure", dissertation.intro.definitions.def_concept1.evaluation)

        //          console.log ("On est censé mettre à jour la structure")
        setUpdateStructure(true)
      }
      setPrevDissertation(dissertation);


      // console.log("Modification de dissertation. concept1 : ", dissertation.intro.concepts.concept1)
    } else navigate("/dissertations/")
  }, [dissertation])

  useEffect(() => {
    setUpdateStructure(true)
  }, [])

  /**** Mise à jour de la structure de la dissertation  ****/

  useEffect(() => {
    if (updateStructure) { // s'il faut mettre à jour la structure
      //console.log("on update la strucure")
      //console.log("on update la structure", dissertation.intro.definitions.def_concept1.evaluation)

      let new_valuesAndNotes: ValuesAndNotes[] = []
      new_valuesAndNotes = introSeule ? getValuesAndNotesIntro(dissertation) : getValuesAndNotes(dissertation)
      setValuesAndNotes(new_valuesAndNotes)
      //console.log("on recréé valuesAndNotes", new_valuesAndNotes)


      if (currentPage) {
        //console.log ("on met à jour les étapes")
        updateEtapes(new_valuesAndNotes)
        if (arraysAreEqual([1, 2], currentPage.slice(0, 2)) && (dissertation.developpement.length == 2)) setCurrentPage([1, 1, 1])
        // on ne le fait pas sur valuesAndNotes à cause de sa dimension asynchrone
      }

      setUpdateStructure(false) // il n'y a plus à mettre à jour la structure, on vient de le faire
    }
  }, [updateStructure])

  useEffect(() => {  // on vérifie qu'on ne reste pas dans la partie 3 quand elle est supprimée
    if (arraysAreEqual([1, 2], currentPage.slice(0, 2)) && (dissertation.developpement.length == 2)) setCurrentPage([0, 1])

  }, [updateStructure])

  useEffect(() => {
    if (isPartieBeyondForbidden(currentPage, forbiddenEtape.etape)) setCurrentPage(forbiddenEtape.etape)
  }, [currentPage, etapes, forbiddenEtape])

  function updateEtapes(valuesAndNotes_temp: ValuesAndNotes[]) {

    //const filteredArray = valuesAndNotes_temp.filter(item => item.goTo_etape[0] === currentPage[0]);
    const new_etapes = generateStructureMenu(valuesAndNotes_temp, setForbiddenEtape)
    //console.log("on recréé les étapes", new_etapes)
    setEtapes(new_etapes)
  }


  const objet = dissertation
  const setObjet = setDissertation
  const element = ''

  const getStrV = (el: string): string => getStrValue(objet, element + el);
  const getNumV = (el: string): number => getNumValue(objet, element + el);
  const setV = (el: string) => setValue(setObjet, element + el);


  if (!dissertation) return null
  return (
    <MessageCentralContext.Provider
      value={[messageCentral, setMessageCentral, fade, setFade, color, setColor, afficherTexte, cacherTexte]}
    >
      <CurrentPageContext.Provider value={[currentPage, setCurrentPage]}>
        <UpdateStructureContext.Provider value={[updateStructure, setUpdateStructure]}>
            <CurrentOpenedContext.Provider value={[currentOpened, setCurrentOpened]}>

              {introSeule ?
                <IntroSeuleMenu setCurrentPage={setCurrentPage} sujet={dissertation.sujet} setDissertation={setDissertation}/>
              :
              <MenuDissertation
                dissertation={dissertation}
                setDissertation={setDissertation}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                valuesAndNotes={valuesAndNotes}
                etapes={etapes}
              />
            }

              <Load_GrandePartie
                dissertation={dissertation}
                setDissertation={setDissertation}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                etapes={etapes}
                valuesAndNotes={valuesAndNotes}
              ></Load_GrandePartie>
            </CurrentOpenedContext.Provider>
        </UpdateStructureContext.Provider>
      </CurrentPageContext.Provider>
    </MessageCentralContext.Provider >
  )
}

export default Dissertation
