import { FC } from "react"
import checkAndUpdate from '~app/components/Funcs/CheckAndUpdate'
import { UpdateStructureContext } from '~app/components/Context'  // Assurez-vous que ceci est le chemin correct

interface Props {
    value:string
    onChange:(str:string)=>void
    placeholder:string | undefined
    small?:boolean
}
const InputText: FC<Props> = ({value, onChange, placeholder, small}) => {

    const handleChange = (event:any, oldInput:string, setFunction:(v:string) => void) => {
        //checkAndUpdate(oldInput, event.target.value, setUpdateStructure); // si on entre une valeur ou si on la supprime, on met à jour la structure
        setFunction(event.target.value);
      }

    return (
        <input
          type="text"
          className={small ? "input-text smallInputs" : "input-text"} 
          value={value}
          onChange={(e)=> handleChange(e,value,onChange)}
          placeholder={placeholder}
        />
    )
}

export default InputText