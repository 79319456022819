import { FC, useEffect, useState, useContext, useRef } from 'react'
import InputText from '~app/components/Inputs/InputText';
import { Concept_liste, Type_Dissertation } from '~./app/components/Dissertation/StructureDissertation'
import { getEmptyContenu, getEmptyDissertation } from '../DissertationVide';
import imgDe from '~assets/de.svg'
import Tooltip from '~app/components/Tooltip';
import { Concept } from '~./app/components/Dissertation/StructureDissertation';
import { seriesGenerales, seriesTechno } from '~app/consts';
import { SearchPreferencesContext } from '~app/components/Context';

interface Sujet {
    sujet: string;
    notions: string[];
}


interface Props {
    setNewConcept: (n: Concept) => void
    concepts: Concept_liste[]
    notions: string[]
}


interface SujetListProps {
    sujets: string[]
    setSujet: (v: string) => void
    selectedSujet: string
    setSelectedSujet: (v: string) => void
    sujetsRef: React.RefObject<HTMLSelectElement>
}

interface Props3 {
    allConcepts: string[];
    uncheckedConcepts: { [key: string]: boolean };
    setUncheckedConcepts: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    seulementNotions:boolean
  }

const CheckBoxComponent: React.FC<Props3> = ({ allConcepts, uncheckedConcepts, setUncheckedConcepts, seulementNotions }) => {


    const handleCheck = (concept: string) => {
        setUncheckedConcepts(prevState => ({
            ...prevState,
            [concept]: !prevState[concept],
        }));
    };
    
    const selectAll = () => {
        const newCheckedConcepts: { [key: string]: boolean } = {};
        allConcepts.forEach(concept => {
            newCheckedConcepts[concept] = false;
        });
        setUncheckedConcepts(newCheckedConcepts);
    };
      
    const deselectAll = () => {
        const newCheckedConcepts: { [key: string]: boolean } = {};
        allConcepts.forEach(concept => {
            newCheckedConcepts[concept] = true;
        });
        setUncheckedConcepts(newCheckedConcepts);
    };
    

      /*useEffect(() => {
        const initialCheckedConcepts: { [key: string]: boolean } = {};
        allConcepts.forEach(concept => {
          initialCheckedConcepts[concept] = true;
        });
        setCheckedConcepts(initialCheckedConcepts);
      }, []);*/

    return (
        <div style={{ height: '200px', backgroundColor: '#f0f2f5', borderRadius: '10px', padding: '5px 7px 7px 7px', position:'relative' }} className={seulementNotions ? "disabled" : ''}>
            <div style={{ textAlign: 'center', marginBottom: '5px', fontWeight: 600 }}>Seulement les thèmes suivants :</div>
            <div style={{ width: '100%', height: '100px', display: 'flex', flexWrap: 'wrap', fontSize: '13px', alignItems: 'center' }}>

                {allConcepts.map((concept, index) => (
                    <div key={index} className='optionsCheckBox'>
          <input
            type="checkbox"
            checked={!uncheckedConcepts[concept]}
            onChange={() => handleCheck(concept)}
            style={{marginRight:'5px'}}
          />                        <div>{concept}</div>
                    </div>
                ))}
            </div>
            <div style={{ display: 'flex', position: 'absolute', bottom: '9px', alignItems: 'center',justifyContent: 'center' }}>
                <button className="bouton_dialog smallBouton" onClick={() => selectAll()}>Tout sélectionner</button>
                <button className="bouton_dialog smallBouton" style={{marginLeft: '11px'}} onClick={() => deselectAll()}>Tout désélectionner</button>

            </div>

        </div>
    );
};

const SujetList: React.FC<SujetListProps> = ({ sujets, setSujet, selectedSujet, setSelectedSujet, sujetsRef }) => {


    const handleSujetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSujet(event.target.value);
        setSelectedSujet(event.target.value)
    }

    const handleRandomSelection = () => {
        const randomIndex = Math.floor(Math.random() * sujets.length);
        const randomSujet = sujets[randomIndex];
        setSujet(randomSujet);
        setSelectedSujet(randomSujet);

        if (sujetsRef.current) {
            const selectElement = sujetsRef.current;
            const optionElement = selectElement.options[randomIndex];

            if (optionElement) {
                const optionTop = optionElement.offsetTop;
                const optionHeight = optionElement.offsetHeight;
                selectElement.scrollTop = optionTop - (selectElement.clientHeight / 2) + (optionHeight / 2);
            }
        }
    }

    return (
        <>
            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                <select value={selectedSujet} ref={sujetsRef} name="sujets_select" size={5} style={{ width: '100%', height: '100%', padding: '10px', zIndex: 1 }} onChange={handleSujetChange}>
                    <option value="" style={{ display: 'none' }}></option>
                    {sujets.map((sujet, index) => (
                        <option key={index} value={sujet}>{sujet}</option>
                    ))}
                </select>
                <div className="imgBox">
                    <img
                        src={imgDe}
                        className="img-rotate"
                        onClick={handleRandomSelection}
                    />
                </div>
            </div>
        </>
    );
};

const ConceptsList: React.FC<{ value:string, concepts: string[], nb: number, onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void, selectedValue: string }> = ({ value, concepts, onChange, selectedValue }) => {
    return (
        <select value={value} className="input-text smallInputs inputsConcepts" onChange={onChange}>

            {concepts.map((concept, index) => (
                <option key={index} value={concept}>{concept}</option>
            ))}
        </select>
    );
};

const BaseDonneesConcept: React.FC<Props> = ({ setNewConcept, concepts, notions }) => {

    const context = useContext(SearchPreferencesContext)
    if (!context) throw new Error('SearchPreferencesContext is undefined')
    const [,,,,typeSujet,setTypeSujet,uncheckedConcepts,setUncheckedConcepts,seulementNotions,setSeulementNotions] = context
    

    const [concept, setConcept] = useState<string>("")

    const [allConcepts, setAllConcepts] = useState<string[]>([])

    const conceptsRef = useRef<HTMLSelectElement>(null);



    const [notionsAffichees, setNotionsAffichees] = useState<string[]>(notions)


    const [selectedConcept, setSelectedConcept] = useState("")

    const [selectedValueType, setSelectedValueType] = useState<string>('Série générale');


    const scrollToTop = () => {
        if (conceptsRef.current) {
            conceptsRef.current.scrollTop = 0;
        }

    }



    useEffect(() => {
        const tDiss = { nomConcept: "", contenu: getEmptyContenu() }
        tDiss.nomConcept = concept
        setNewConcept(tDiss)
    }, [concept])


    useEffect(() => {


        let allConceptsT: string[] = [];
        if (seulementNotions) {
            console.log("on rentre ici", seulementNotions)
            if (typeSujet == "Série générale" || typeSujet == "Tous les sujets") { // le "tous les sujets" n'est pas proposé par l'interface, mais il peut être enregistré dans le contexte en provenance d'un choix de dissertation 
                allConceptsT = seriesGenerales
            } else if (typeSujet == "Séries techno") {
                allConceptsT = seriesTechno
            }
        } else {

            let tConcepts = concepts
            console.log("tous les concepts", concepts)
            if (typeSujet == "Série générale" || typeSujet == "Tous les sujets") {
                tConcepts = tConcepts.filter(sujet => seriesGenerales.includes(sujet.concept) && !uncheckedConcepts[sujet.concept])
            } else if (typeSujet == "Séries techno") {
                tConcepts = tConcepts.filter(sujet => seriesTechno.includes(sujet.concept) && !uncheckedConcepts[sujet.concept])

            }

            allConceptsT = tConcepts.flatMap(s => s.notions)
        }

        setAllConcepts(allConceptsT)


        if (!allConceptsT.includes(selectedConcept)) {
            setConcept("");
            setSelectedConcept("");
            scrollToTop()
        }
    }, [typeSujet, notionsAffichees, seulementNotions, uncheckedConcepts])


    useEffect(() => {
        let newConcepts = seriesGenerales
        if (typeSujet == "Séries techno") newConcepts = seriesTechno
        setNotionsAffichees(newConcepts)

    }, [typeSujet])


    const handleTypeSujetsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTypeSujet(event.target.value);
        setSelectedValueType(event.target.value)

    }
    return <>
        <div style={{ display: 'flex', width: '100%', marginBottom: '15px', height: '100%' }} >
            <div style={{ width: '345px', marginRight: '20px' }}>
                <div style={{ width: '100%', textAlign: 'left' }}>
                    <div style={{ display: 'flex', height: '30px', alignItems: 'center' }}>
                        <div style={{ textAlign: 'left' }}>
                            Rechercher :</div>
                        <ConceptsList value={typeSujet} selectedValue={selectedValueType} concepts={["Série générale", "Séries techno"]} nb={1} onChange={handleTypeSujetsChange} />
                    </div>
                    <div style={{ display: 'flex', marginTop: '5px', marginBottom: '12px' }}>
                        <input
                            type="checkbox"
                            checked={seulementNotions}
                            onChange={(event) => setSeulementNotions(event.target.checked)}
                            style={{ marginRight: '12px' }}
                        />
                        Se limiter aux notions du programme {seulementNotions}</div>
                    <CheckBoxComponent allConcepts={notionsAffichees} uncheckedConcepts={uncheckedConcepts} setUncheckedConcepts={setUncheckedConcepts} seulementNotions={seulementNotions}/>

                </div>
            </div>
            <div style={{ width: '225px', marginBottom: '15px' }} >
                <SujetList sujets={allConcepts} setSujet={setConcept} selectedSujet={selectedConcept} setSelectedSujet={setSelectedConcept} sujetsRef={conceptsRef}/>
            </div>

        </div>

    </>
}

export default BaseDonneesConcept