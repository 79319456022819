import React, { FC } from 'react'
import { Lien } from "~types"
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

interface Props {
    liens: Lien[]
}

const GetLinkMenu: FC<Props> = ({ liens }) => {
    const navigate = useNavigate()
    const location = useLocation();

    return (
        <div style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <div style={{ margin: 'auto', display: 'flex' }}>
                {liens.map((lien, index) => (
                    <React.Fragment key={index}>
                        {location.pathname !== lien.URL &&
                            <button onClick={() => navigate(lien.URL)} className="bouton_dialog boutonLink">{lien.texte}</button>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default GetLinkMenu