import { FC } from "react";

interface Props {
    input:string,
    setInput:(str:string) => void
    placeholder?:string
    className?:string
  }

const Textearea_noEnter: FC<Props> = ({ input, setInput, placeholder, className}) => {
  
  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleChange = (event:any) => {
    const value = event.target.value.replace(/\r?\n|\r/g, '');
    //checkAndUpdate(input, value, setUpdateStructure); // si on entre une valeur ou si on la supprime, on met à jour la structure
    setInput(value);
  };

  return (
    <>
    <textarea 
      value={input} 
      onChange={handleChange} 
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className={className}
    />
    </>
  );
}

export default Textearea_noEnter;
