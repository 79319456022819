import { Type_Dissertation, Souspartie } from '~./app/components/Dissertation/StructureDissertation'
import { Etape } from '~./app/components/Dissertation/StructureDissertation'
import { ForbiddenEtape } from '~types'
import { types_sousparties } from '~app/consts'


export interface ValeurATester {
  valeur: string
  messageAAfficherSiAbsent: string
  composant: number
}

export interface ValuesAndNotes {
  valeursATester: ValeurATester[]
  notes: number[]
  messageEtape: string
  goTo_etape: number[]
}

export function getValuesAndNotes(dissertation:Type_Dissertation):ValuesAndNotes[]
{
  let new_valuesAndNotes: ValuesAndNotes[] = []
  new_valuesAndNotes = getValuesAndNotesIntro(dissertation)
  new_valuesAndNotes = new_valuesAndNotes.concat(getValuesAndNotesPartie(dissertation, 0))
  new_valuesAndNotes = new_valuesAndNotes.concat(getValuesAndNotesPartie(dissertation, 1))
  if (dissertation.developpement.length == 3) new_valuesAndNotes = new_valuesAndNotes.concat(getValuesAndNotesPartie(dissertation, 2))
  new_valuesAndNotes = new_valuesAndNotes.concat(getValuesAndNotesConclu(dissertation))
  return new_valuesAndNotes
}


export function getValuesAndNotesIntro(dissertation: Type_Dissertation): ValuesAndNotes[] {
  const add = "Introduction : "
  let valuesAndNotes_temp: ValuesAndNotes[] = [
    {   //accroche
      valeursATester: [],
      messageEtape: "Construction de l'accroche",
      notes: [dissertation.intro.accroche.note],
      goTo_etape: [0, 0]
    },
    {   // identification des concepts
      valeursATester: [
        {
          valeur: dissertation.intro.concepts.concept1,
          messageAAfficherSiAbsent: add + 'Identifiez le premier concept',
          composant: 0
        },
        {
          valeur: dissertation.intro.concepts.concept2,
          messageAAfficherSiAbsent: add + 'Identifiez le deuxième concept',
          composant: 1
        }
      ],
      notes: [dissertation.intro.concepts.note],
      messageEtape: "Identification des concepts",
      goTo_etape: [0, 1]
    },
    { // définition des concepts
      valeursATester: [
        {
          valeur: dissertation.intro.definitions.def_concept1.contenu,
          messageAAfficherSiAbsent: add + 'Définissez le premier concept',
          composant: 0
        },
        {
          valeur: dissertation.intro.definitions.def_concept2.contenu,
          messageAAfficherSiAbsent: add + 'Définissez le deuxième concept',
          composant: 1
        }
      ],
      notes: [dissertation.intro.definitions.def_concept1.note, dissertation.intro.definitions.def_concept2.note],
      messageEtape: "Définition des concepts",
      goTo_etape: [0, 2]
    },
    { // construction de la problématique
      valeursATester: [
        {
          valeur: dissertation.intro.problematique.problematique1.contenu,
          messageAAfficherSiAbsent: add + 'Formulez la première partie de la problématique',
          composant: 0
        },
        {
          valeur: dissertation.intro.problematique.problematique2.contenu,
          messageAAfficherSiAbsent: add + 'Formulez la deuxième partie de la problématique',
          composant: 1
        }
      ],
      notes: [dissertation.intro.problematique.problematique1.note, dissertation.intro.problematique.problematique2.note],
      messageEtape: "Construction de la problématique",
      goTo_etape: [0, 3]
    }
  ]
  if (dissertation.developpement.length == 2) {
    valuesAndNotes_temp.push(
      { // plan en deux parties
        valeursATester: [
          {
            valeur: dissertation.developpement[0].annonce.contenu,
            messageAAfficherSiAbsent: add + 'Formulez la première partie du plan',
            composant: 0
          },
          {
            valeur: dissertation.developpement[1].annonce.contenu,
            messageAAfficherSiAbsent: add + 'Formulez la deuxième partie du plan',
            composant: 1
          }
        ],
        notes: [dissertation.developpement[0].annonce.note, dissertation.developpement[1].annonce.note],
        messageEtape: "Construction du plan",
        goTo_etape: [0, 4]
      }
    )
  } else if (dissertation.developpement.length == 3) {
    // si on a un plan en trois parties
    valuesAndNotes_temp.push(
      { // plan en deux parties
        valeursATester: [
          {
            valeur: dissertation.developpement[0].annonce.contenu,
            messageAAfficherSiAbsent: add + 'Formulez la première partie du plan',
            composant: 0
          },
          {
            valeur: dissertation.developpement[1].annonce.contenu,
            messageAAfficherSiAbsent: add + 'Formulez la deuxième partie du plan',
            composant: 1
          }, //A enlever si on ne veut pas que l'absence d'annonce de thèse 3 bloque les deux premières parties 
          {
            valeur: dissertation.developpement[2].annonce.contenu,
            messageAAfficherSiAbsent: 'Formulez la troisième partie du plan',
            composant: 2
          }
        ],
        notes: [
          dissertation.developpement[0].annonce.note,
          dissertation.developpement[1].annonce.note,
          dissertation.developpement[2].annonce.note,
        ],
        messageEtape: "Construction du plan",
        goTo_etape: [0, 4]
      }
    )
  }
  //console.log("On a fini l'intro, on exporte", valuesAndNotes_temp)
  return valuesAndNotes_temp;
}

export function getValuesAndNotesPartie(dissertation: Type_Dissertation, partie: number): ValuesAndNotes[] {
  const add = "Partie " + (partie + 1) + " : "
  let valuesAndNotes_temp: ValuesAndNotes[] = [
    {   // intro de partie
      valeursATester: [
        {
          valeur: dissertation.developpement[partie].intro.contenu,
          messageAAfficherSiAbsent: add + 'Rédigez l\'introduction',
          composant: 0
        }
      ],
      notes: [dissertation.developpement[partie].intro.note],
      messageEtape: "Introduction de partie",
      goTo_etape: [1, partie, 0]
    }]

  //on rentre les sous-parties
  const nbsous_parties = dissertation.developpement[partie].sousparties.length
  for (let i = 0; i < nbsous_parties; i++) {
    let sous_partie_temp: Souspartie = dissertation.developpement[partie].sousparties[i]
    let sous_partie_export: ValuesAndNotes = {   //
      valeursATester: [ // intro de sous-partie
        {
          valeur: sous_partie_temp.intro.contenu,
          messageAAfficherSiAbsent: add + 'Rédigez l\'introduction de la sous-partie ' + (i + 1),
          composant: 0
        },
        {
          valeur: sous_partie_temp.dvlpt_souspartie.exposition_dvlpt,
          messageAAfficherSiAbsent: add + 'Développez la sous-partie ' + (i + 1),
          composant: 1
        },
        ...(types_sousparties[sous_partie_temp.dvlpt_souspartie.type_dvlpt].texte2 ? [{
          valeur: sous_partie_temp.dvlpt_souspartie.analyse_dvlpt,
          messageAAfficherSiAbsent: add + 'Développez la sous-partie ' + (i + 1),
          composant: 1
        }] : []),
        {
          valeur: sous_partie_temp.conclusion.contenu,
          messageAAfficherSiAbsent: add + 'Rédigez la conclusion de la sous-partie ' + (i + 1),
          composant: 2
        }
      ],
      notes: [sous_partie_temp.intro.note, sous_partie_temp.dvlpt_souspartie.note_dvlpt, sous_partie_temp.conclusion.note],
      messageEtape: "Sous-partie " + (i + 1),
      goTo_etape: [1, partie, 1, i]
    }
    valuesAndNotes_temp.push(sous_partie_export)
  }
  valuesAndNotes_temp.push( // il reste la conclu de partie à ajouter
    {   //
      valeursATester: [ // 
        {
          valeur: dissertation.developpement[partie].conclusion.contenu,
          messageAAfficherSiAbsent: add + 'Rédigez la conclusion',
          composant: 0
        },
      ],
      notes: [dissertation.developpement[partie].conclusion.note],
      messageEtape: "Conclusion de partie",
      goTo_etape: [1, partie, 2]
    }
  )


  return valuesAndNotes_temp;
}

export function getValuesAndNotesConclu(dissertation: Type_Dissertation): ValuesAndNotes[] {
  const add = "Conclusion : "
  let valuesAndNotes_temp: ValuesAndNotes[] = [
    {   // identification des concepts
      valeursATester: [
        {
          valeur: dissertation.conclusion.synthese.contenu,
          messageAAfficherSiAbsent: add + 'Rédigez la synthèse',
          composant: 0
        }
      ],
      notes: [dissertation.conclusion.synthese.note],
      messageEtape: "Synthèse de la dissertation",
      goTo_etape: [2, 0]
    },
    {   //accroche
      valeursATester: [],
      messageEtape: "Construction de l'ouverture",
      notes: [dissertation.conclusion.ouverture.note],
      goTo_etape: [2, 1]
    },
  ]

  return valuesAndNotes_temp
}


export function generateStructureMenu(valuesAndNotes: ValuesAndNotes[], setForbiddenEtape: (v: ForbiddenEtape) => void): Etape[] {
  let etapesTemp: Etape[] = []
  let etape_erreur_composant: number = 0

  setForbiddenEtape({ nom: '', currentProblem: '', etape: [], composant: 0 })
  let finEtapes = false
  let currentProblem_temp = ''

  // Fonction pour savoir quelle couleur attribuer aux différentes étapes
  function getColor(valueAndNote: ValuesAndNotes): Etape {
    let nom_etape = valueAndNote.messageEtape

    const nb_necessaires = valueAndNote.valeursATester.length
    let nb_remplis = 0

    /*if (nb_necessaires == 0) { // si aucune valeur n'est nécessaire
      return {nom: valueAndNote.messageEtape, nb_valeurs_renseignees:0, nb_valeurs_necessaires:0, couleur:0, goTo:valueAndNote.goTo_etape}
    }*/

    for (let i = 0; i < nb_necessaires; i++) {
      // s'il existe au moins un input nécessaire vide, on est en gris
      if (valueAndNote.valeursATester[i].valeur === '') {
        
        finEtapes = true // on indique une fin des etapes, avec une exception s'il n'y a aucune nécessité
        currentProblem_temp = valueAndNote.valeursATester[i].messageAAfficherSiAbsent
        etape_erreur_composant = valueAndNote.valeursATester[i].composant
        break;
      } else {
        nb_remplis++
      }
    }

    let etapeFinale: Etape = {
      nom: nom_etape,
      nb_valeurs_renseignees: nb_remplis,
      nb_valeurs_necessaires: nb_necessaires,
      couleur: 0,
      goTo: valueAndNote.goTo_etape
    }

    if (nb_remplis < nb_necessaires) return { ...etapeFinale, couleur: 0 }

    // si on passe cette étape, alors tous les inputs nécessaires sont renseignés, et on peut ajouter l'étape en cours à l'affichage
    const nb_notes = valueAndNote.notes.length
    //konsole.log(valueAndNote)
    for (let i = 0; i < nb_notes; i++) {
      // s'il existe au moins une note vide (résultat non évaluée), ou une note en cours d'évaluation (wait = 4), on est en gris
      if (valueAndNote.notes[i] === 0 || valueAndNote.notes[i] == 4) {
        return { ...etapeFinale, couleur: 0 }
      }
    }
    for (let i = 0; i < nb_notes; i++) {
      // si une seule évaluation est en rouge, on est en rouge
      if (valueAndNote.notes[i] === 1) {
        return { ...etapeFinale, couleur: 1 }
      }
    }
    for (let i = 0; i < nb_notes; i++) {
      // s'il y a au moins une évaluation orange, on est en orange
      if (valueAndNote.notes[i] === 2) {
        return { ...etapeFinale, couleur: 2 }
      }
    }
    return { ...etapeFinale, couleur: 3 } // sinon, il n'y a que du vert, on renvoie vert
  }

  for (let i = 0; i < valuesAndNotes.length; i++) {
    // pour chaque étape
    //if (!finEtapes) {   // si getColor n'a pas changé finEtapes pour dire qu'on s'arrête
    etapesTemp.push(getColor(valuesAndNotes[i])) // on essaye de savoir quelle couleur on aura
    if (finEtapes) {
      setForbiddenEtape({ nom: valuesAndNotes[i].messageEtape, currentProblem: currentProblem_temp, etape: valuesAndNotes[i].goTo_etape, composant: etape_erreur_composant })
      break
    }

  }

  return etapesTemp
}